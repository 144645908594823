import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import cdwImage from 'images/rasitgr/cdw-image.jpg';

const Container = tw.div`relative bg-gray-900 text-gray-100 -mx-8 px-8 `;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft ? tw`md:mr-8 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8`;

const Heading = tw(SectionHeading)`text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight `;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-400 mt-4`

const Statistics = tw.div`mt-6 lg:mt-8 xl:mt-16 flex flex-wrap`
const Statistic = tw.div`text-lg sm:text-2xl lg:text-3xl w-1/2 mt-4 lg:mt-10 text-center md:text-left`
const Value = tw.div`font-bold text-green-500`
const Key = tw.div`font-medium text-green-700`

export default ({textOnLeft = false}) => {
  const statistics = [
    {
      key: "Kartlar",
      value: "126",
    },
    {
      key: "Mani",
      value: "27",
    },
    {
      key: "Eşsiz Kartlar",
      value: "100",
    },
    {
      key: "Kazanan",
      value: "1",
    }
  ]

  return (
    <Container>
      <TwoColumn>
        <ImageColumn >
          <a href="/en/crazydemagogywars"><Image imageSrc={cdwImage} /></a>
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <a href="/en/crazydemagogywars"><Heading>İlk Kart Oyunumuz</Heading></a>
            <Description>Çılgın Algı Savaşları: Piyasadaki benzer kart oyunlarının monoton ve tekrarlayan yapısından sıkıldık ve sadece kendimiz oynamak için havalı bir oyun geliştirdik. Peki sonra ne yaptık? Seri üretime geçtik ve Çılgın Demagoji Savaşları'nı herkesle paylaştık.</Description>
            <Statistics>
              {statistics.map((statistic, index) => (
              <Statistic key={index}>
                <Value>{statistic.value}</Value>
                <Key>{statistic.key}</Key>
              </Statistic>
              ))}
            </Statistics>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  )
}

