import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "./HeroQuarantine";
import InformativeFeature from "./InformativeFeaturesQC";
import MainFeature from "./MainFeature";
import GameGuide from "./GameGuideQC";
import FeatureWithSteps from "./EasyToUseQC";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "./Testimonials";
import Hints from "./Hints";
import Download from "./DownloadQC";
import heroScreenshotImageSrc from "images/happy-illustration.svg";
import macHeroScreenshotImageSrc from "images/signup-illustration.svg";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import Gameplay from "./GameplayQC"
import banner from 'images/rasitgr/quarantine-banner.png'
import cengiz from 'images/rasitgr/QC-InGame-SS.png'
import alp from 'images/rasitgr/pap-min.png';
import berkhan from 'images/rasitgr/lurker-min.png';
import onur from 'images/rasitgr/evandnoth-min.png';
import Footer from 'components/en/Footer';
import Team from './Team';
import Team2 from "./Team-2";
import { Helmet } from "react-helmet-async";


export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-gray-300`;
  const HighlightedText = tw.span`text-red-700`;

  return (
    <>
     <Helmet>
        <title>Quarantine Chronicles: Anatolian Rock | Virtual Pet Game | RASITGR Games</title>
        <meta
          name="description"
          content="Help Cengiz survive 45 days of quarantine in Quarantine Chronicles, a unique virtual pet experience with an Anatolian Rock twist."
        />
        <meta name="keywords" content="Quarantine Chronicles, Anatolian Rock, virtual pet game, RASITGR Games" />
        <meta property="og:title" content="Quarantine Chronicles: Anatolian Rock" />
        <meta property="og:description" content="A unique virtual pet game where you help Cengiz survive quarantine." />
      </Helmet>
       <Hero roundedHeaderButton={true} />
     
      <InformativeFeature
  subheading={<Subheading>WHAT IS THIS</Subheading>}
  heading={
    <>
      Quarantine Chronicles: <HighlightedText>Anatolian</HighlightedText> Rock?
    </>
  }
  description={"A virtual pet game where you join Cengiz, who is facing a mental test during his 45-day quarantine. Throughout this period, various challenges will push your limits."}
  id='video'
  
      />
     
    
     
      <MainFeature
      heading="NOSTALGIC GAME"
     subheading={<Subheading>designed to play a</Subheading>}
     imageSrc={banner}
     imageBorder={true}
     imageDecoratorBlob={true}
     description={"We developed Quarantine Chronicles: Anatolian Rock for ourselves to play and to experience a new challenge, a new story each time. That's why we enriched every detail with academic research and refined it until we found the most accurate results. We captured everything about the Covid-19 quarantine."}
     /> 

      <Team />
     
      <Testimonial
  subheading={<Subheading>Testers</Subheading>}
  heading={
    <>
      Struggled in <HighlightedText>Quarantine Chronicles</HighlightedText>
    </>
  }
  description={"They tried Quarantine Chronicles. At first, they were confused, then they got the hang of it. After that, they made Cengiz's life a living hell."}
  testimonials={[
    {
      stars: 5,
      profileImageSrc:
        berkhan,
      heading: "Well done",
      quote:
        "I didn't expect the game to be this playable while it was being made. Turns out, I killed Cengiz the most among the testers.",
      customerName: "Berkhan 'The Lurker'",
      customerTitle: "Translator, QA"
    },
    {
      stars: 2,
      profileImageSrc:
        onur,
      heading: "My smoking addiction followed me in the game",
      quote:
        "I keep smoking in the game, turns out Pap was right, I always end up in the hospital...",
      customerName: "Onur 'EvandNoth'",
      customerTitle: "Software Developer"
    },
    {
      stars: 2,
      profileImageSrc:
        alp,
      heading: "Poor UX, but no harm in the first game",
      quote:
        "I keep smoking in the game, turns out Pap was right, I always end up in the hospital...",
      customerName: "Alp 'Pap'",
      customerTitle: "Game Director & Developer"
    }
  ]}
/>

     
      <FeatureWithSteps
       subheading={<Subheading>STEP BY STEP</Subheading>}
       heading={
         <>
           <HighlightedText>Simplified</HighlightedText> Usage
         </>
       }       
        textOnLeft={true}
        imageSrc={cengiz}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
   
      />
     
      <div id="rules" ></div>
     <Download/>

        <GameGuide  />

      <Gameplay />

      <Hints
  subheading={<Subheading>So You Can Play Easily</Subheading>}
  heading={
    <>
      Tips We've Prepared <HighlightedText>Just for You</HighlightedText>
    </>
  }
  faqs={[
    {id: 0, title: "Sleep Bar", description: ["The sleep bar holds a maximum of two days' worth of sleep. When it's half full, it's time to sleep."]},
    {id: 1, title: "Gaining Energy", description: ["When your energy drops to zero, eating and trying to sleep will be your best helpers."]},
    {id: 2, title: "Increasing Self-Awareness", description: ["Following the news or reading books will increase your awareness."]},
    {id: 3, title: "Food Storage", description: ["You can store a maximum of 1.5 days' worth of cooked food at a time."]},
    {id: 4, title: "Getting Healthier", description: ["Exercising helps your organs, and thus your health, improve."]},
    {id: 5, title: "Becoming Unhealthy", description: ["Using tobacco products negatively affects the health of your organs."]},
    {id: 6, title: "Boredom from Repetition", description: ["Doing the same activity repeatedly can bore Cengiz and negatively impact his mental health. Constantly doing things that bore Cengiz can cause some of his happiness hormones to drop."]},
    {id: 7, title: "Insomnia", description: ["Sleeping too much or irregularly can cause Cengiz to suffer from sleep disorders."]},
    {id: 8, title: "Mask Use", description: ["Going outside without a mask increases the risk of catching an illness, and your mask will only wear out when you use it outside."]},
    {id: 9, title: "Recovering from the Virus", description: ["The healthier you are when you get sick, the easier it will be to recover."]},
    {id: 10, title: "Actions", description: ["Each action takes 20 minutes, and the entire to-do list can be completed in 12 hours. Every activity has an effect when finished. Canceling an activity before it's done causes it to have no effect. Leaving the to-do list empty makes Cengiz waste time and get bored."]},
    {id: 11, title: "Cengiz Getting Bored", description: ["You can tell if Cengiz is bored by checking his negative feedback after doing a certain activity."]},
    {id: 12, title: "Playing the Guitar", description: ["You can play the guitar in Cengiz's room."]},
    {id: 13, title: "Hormone Tracking", description: ["Every action will benefit Cengiz's different hormones. To track these, you need to increase Cengiz's awareness."]},
    {id: 14, title: "Exercising Too Much", description: ["Exercising is good for health, but it will also make Cengiz hungry."]},
    {id: 15, title: "Activity Confirmation", description: ["If you're confused by activity icons, you can add them to the to-do list and check their names."]},
    {id: 16, title: "Addiction", description: ["You can track Cengiz's complaints and expressions on the Info Page. Cengiz may sometimes add his addictive behaviors to the to-do list on his own."]},
    {id: 17, title: "Understanding Cengiz", description: ["You can track Cengiz's complaints and expressions on the Info Page."]},
    {id: 18, title: "The Weight of Time", description: ["In the second half of the 45 days, you may struggle to offer Cengiz something entertaining."]},
    {id: 19, title: "Running Out of Money", description: ["If you run out of money, you can watch ads on the Info Page to earn money."]},
    {id: 20, title: "Depression", description: ["When Cengiz gets anxious, an extra notification will pop up, which is a sign of the onset of depression. Being depressed for a long time can cause Cengiz to have heart and gut problems."]},
    {id: 21, title: "Tobacco Addiction", description: ["Even if you're playing in hard mode, you can help Cengiz quit tobacco. Not using tobacco for a while will help Cengiz overcome this addiction."]},
    {id: 22, title: "Drinking Water", description: ["You don't need to make Cengiz drink water. As long as he has water in stock, he'll drink it himself."]},
    {id: 23, title: "Staying Hungry", description: ["Staying hungry for too long can cause Cengiz to have stomach pains and damage his stomach."]},
  ]}
/>


    <Footer />
     
    </>
  );
}
