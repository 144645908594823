import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/tr/Hero2024";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "./MainFeatuer";
import MainFeature2 from "pages/CDW/CDWCard.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import DownloadApp from "components/cta/DownloadApp.js";
import Footer from "components/tr/Footer";

import chefIconImageSrc from "images/chef-icon.svg";
import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";

import QuarantineImage from 'images/rasitgr/quarantine-logo.png'
import PrimzurumImage from 'images/rasitgr/primzurum-image.png'
import CDWImage from 'images/rasitgr/cdw-image.jpg';
import TOAImage from 'images/rasitgr/talesofamasia-image.png';

import { Helmet } from "react-helmet-async";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-green-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-green-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <div>
      <Helmet>
  <title>Oyunlarımız | RASITGR Games</title>
  <meta
    name="description"
    content="RASITGR Games tarafından geliştirilen ve yayımlanan benzersiz oyunları keşfedin; mobil, masaüstü ve kart oyunları."
  />
  <meta name="keywords" content="oyunlar, bağımsız oyunlar, mobil oyunlar, masaüstü oyunlar, kart oyunları, RASITGR Games" />
  <meta property="og:title" content="Oyunlarımız | RASITGR Games" />
  <meta property="og:description" content="RASITGR Games tarafından geliştirilen ve yayımlanan bağımsız oyunları keşfedin." />
</Helmet>

      <Hero />

      <MainFeature
        subheading={<Subheading>Masaüstü Rol Yapma Oyunu (2019)</Subheading>}
        heading={
          <>
            Tales Of Amasia
            <wbr /> <HighlightedText>Very Basic Edition</HighlightedText>
          </>
        }
        description={
          <Description>
            Tales Of Amasia, farklı tecrübe seviyesindeki oyunculara hitap eden bir masaüstü rol yapma oyunudur. Keşfedilmemiş Türk mitleriyle zenginleştirilmiş, el yapımı geniş bir evren sunar.
            <br />
            <br />
           En Basit Sürüm, herhangi bir öntecrübe veya bilgi gerektirmeden fantezi rol yapma oyunlarına başlangıç yapmak isteyenler için giriş seviyesinde bir zorluk sunar.
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Güncelleme Aşamasında"
        imageSrc={
         TOAImage
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
        imageAlt="Amasya'nın Hikayeleri: Basit Sürüm logosu"
        primaryButtonUrl="/tr/talesofamasia"
        innerLink="/tr/talesofamasia"
      />

      <MainFeature  
        subheading={<Subheading>Mobil Casual (2020)</Subheading>}
        heading={
          <>
            Karantina Günlükleri
            <wbr /> <HighlightedText>Anadolu Rock</HighlightedText>
          </>
        }
        description={
          <Description>
            "Karantina Günlükleri" Anadolu Rock hayranı genç bir adam olan Cengiz'in hayatını anlatıyor. Ana görev, Cengiz'in 45 günlük karantina sürecini başarıyla tamamlamasına yardımcı olmaktır. 
            <br />
            <br />
            Cengiz, bu süreçte giderek zorlaşan bir zihinsel mücadele ile karşı karşıya kalacak. "Karantina Günlükleri"nde her edinilen alışkanlık kendini çok geç olmadan gösteriyor ve oyunculara sıra dışı bir sanal bebek deneyimi sunuluyor.
          </Description>

          
        }
        buttonRounded={false}
        textOnLeft={true}
        primaryButtonText="Play Store'da Oyna"
        imageSrc={
          QuarantineImage
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
        imageAlt="Karantina Günlükleri: Anadolu Rock logosu"
        primaryButtonUrl="https://play.google.com/store/apps/details?id=com.DefaultCompany.EvdeKal"
        innerLink="/tr/quarantinechronicles"
      />
      
      <MainFeature 
        subheading={<Subheading>Mobil Casual (2021)</Subheading>}
        heading={
          <>
            Primzurum
            <wbr /> <HighlightedText>Vizyonumuzu Anlatan Bi Oyun</HighlightedText>
          </>
        }
        description={
          <Description>
            Primzurum, bir tepki olarak yaptığımız, meme formatında bi soru-cevap oyunu. 
            <br />
            <br />
            Primzurum, tanıdık bir konsepti alarak ünlü kurumsal meme'i interaktif bir mobil deneyime dönüştürüyor. Doğru cevapların çoğunlukla açık olduğu bir dünyada, Primzurum oyuncuları tek yanlış cevabı seçmeye zorlayarak farklı düşünmeye teşvik ediyor.
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Itch'den İndir"
        imageSrc={
          PrimzurumImage
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
        imageAlt="Primzurum oyun ekran görüntüsü"
        primaryButtonUrl="https://rasitgr-games.itch.io/primzurum"
        innerLink="/tr/primzurum"
      />
      
      <MainFeature 
        subheading={<Subheading>Basılı Kart Oyunu (2022)</Subheading>}
        heading={
          <>
            Çılgın Algı Savaşları
            <wbr /> <HighlightedText>Klasik</HighlightedText>
          </>
        }
        description={
          <Description>
            Çılgın Algı Savaşları, 3 ila 6 oyuncu için hızlı tempolu, politik temalı bir set tamamlama kart oyunudur. Her tur 10 ila 15 dakika arasında sürer ve her oyuncuya renkli ve dinamik bir deneyim sunar.
            <br />
            <br />
            
          </Description>
        }
        buttonRounded={false}
        textOnLeft={true}
        primaryButtonText="Shopier'de Satın Al"
        imageSrc={
         CDWImage
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
        imageAlt="Çılgın Algı Savaşları kart oyunu kutu mockup"
        innerLink="/tr/crazydemagogywars"
        primaryButtonUrl="https://www.shopier.com/ShowProductNew/products.php?id=12032393"
      />

      <DownloadApp
        text={<>Dünyanın dört bir yanındaki oyuncular mobil oyunlarımızı keyifle oynuyor.</>}
      />

      <Footer />
    </div>
  );
}
