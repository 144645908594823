import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../../components/misc/Headings.js";
import QCSS2 from 'images/rasitgr/QC-InGame-SS-2.png';
import QCSS3 from 'images/rasitgr/QC-InGame-SS-3.png';
import QCSS4 from 'images/rasitgr/QC-InGame-SS-4.png';


const Container = tw.div`relative -mx-8 px-8 bg-gray-900`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-300 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-red-500`;
const Title = tw.h4`text-3xl font-bold text-red-600`;
const Description = tw.p`mt-2 text-lg leading-loose text-gray-500`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc: QCSS2,
      subtitle: "First",
      title: "Evaluate your mental state",
      description:
        "Before planning the day, you should assess the current state. By training Cengiz, you can increase his self-awareness. A more self-aware Cengiz will provide you with more information about himself.",
      url: "https://timerse.com"
    },
  
    {
      imageSrc: QCSS3,
      subtitle: "Then",
      title: "Plan the day",
      description:
        "While planning the day, remember that each activity takes 20 minutes in real life and 1 hour in Cengiz's life. Repeating the same activities can lead to addiction for Cengiz, reducing his happiness.",
      url: "https://timerse.com"
    },
  
    {
      imageSrc: QCSS4,
      subtitle: "Finally",
      title: "Review the outcomes",
      description:
        "Every completed activity will give you a notification. You can track how the action went with these notifications and start planning the following days in your mind.",
      url: "https://timerse.com"
    }
  ];
  

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
        <HeadingTitle>Quick Game Guide</HeadingTitle>
<HeadingDescription>
In Anadolu Rock, there are five main types of activities: Time Killing, Learning, Personal Care, Online Shopping, and Face-to-Face Shopping. You are expected to manage Cengiz's 45-day quarantine by distributing these activities throughout the day. The key challenges you need to consider when distributing activities across the day are: Mental Health, Physical Health, Energy, Hunger, Sleep, Food and Water Supplies, Ready Meal Stock, and Addictions. The difficulty selection question at the beginning of the game, 'Do you smoke?' primarily negatively affects these challenges. Each activity takes 20 minutes in real life, and you can add up to 12 hours worth of activities to the list at a time in real-life terms.
</HeadingDescription>

        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc}  />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                {/* <Link href={card.url}>See Event Details</Link> */}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
