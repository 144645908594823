import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ContentWithPaddingXl, Container } from "components/misc/Layouts.js";
import { SectionHeading as Heading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";
import Alp from 'images/rasitgr/pap-min.png';
import Ergun from 'images/rasitgr/horsbtcher-min.jpg';
import Kerem from 'images/rasitgr/ponybutcher-min.jpg';

const Subheading = tw(SubheadingBase)`text-center`;
const Testimonials = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch`;
const TestimonialContainer = tw.div`mt-16 lg:w-1/3`;
const Testimonial = tw.div`px-4 text-center max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img`w-20 h-20 rounded-full`;
const Quote = tw.blockquote`mt-5 text-gray-400 font-light text-sm leading-loose`;
const CustomerName = tw.p`mt-5 text-yellow-600 font-semibold uppercase text-sm tracking-wide`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;

export default ({
  subheading = "Our Talented",
  heading = "Development Team",
  personalLink ="",
  testimonials = [
    {
      imageSrc:
        Ergun,
      quote:
        "Ergün serves as the creative force and solo developer behind Primzurum, guiding the project from concept to completion. With a passion for game development and a hands-on approach, Ergün manages all aspects of the game's development, ensuring that every feature aligns with the vision and delivers a unique experience to players.",
      customerName: "Ergünalp Kurt",
      personalLink:"https://www.linkedin.com/in/erg%C3%BCnalp-kurt-9a04a01b0/"
    },
    {
      imageSrc:
       Kerem,
      quote:
        "As the 3D artist for Primzurum, Kerem is responsible for crafting the game's distinctive visual style. From character models to environmental design, Kerem brings the world of Primzurum to life with detailed and immersive 3D artwork, enhancing the overall player experience.",
      customerName: "Kerem Kurt",
      personalLink:"https://www.linkedin.com/in/kerem-kurt-3bb5891b1/"
    },
    {
      imageSrc:
        Alp,
      quote:
        "Alp plays a multifaceted role in Primzurum as the game's designer, and the game's publishing process. Responsible for shaping the core gameplay and ensuring an intuitive UI, Alp also manages the game's release, coordinating all aspects of distribution to ensure a successful launch.",
      customerName: "Alp Kurt",
      personalLink:"https://www.linkedin.com/in/alp-kurt-161285134/"
    }
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        <Testimonials>
          {testimonials.map((testimonial, index) => (
            <TestimonialContainer key={index}>
              <Testimonial>
                <a target="_blank" href = {testimonial.personalLink}><Image src={testimonial.imageSrc} /></a>
                <a target="_blank" href = {testimonial.personalLink}><CustomerName>- {testimonial.customerName}</CustomerName></a>
                <Quote>{testimonial.quote}</Quote>
              </Testimonial>
            </TestimonialContainer>
          ))}
        </Testimonials>
      </ContentWithPaddingXl>

      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
