import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "./HeroPZ2";
import InformativeFeature from "./InformativeFeaturesPZ";
import MainFeature from "./Feature";
import GameGuide from "./GameGuidePZ";
import FeatureWithSteps from "pages/QC/EasyToUseQC";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import Hints from "pages/QC/Hints";
import Download from "pages/PZ/DownloadPZ";
import Footer from "components/en/Footer";
import heroScreenshotImageSrc from "images/happy-illustration.svg";
import macHeroScreenshotImageSrc from "images/signup-illustration.svg";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import Gameplay from "pages/QC/GameplayQC"
import PrimzurumImage from 'images/rasitgr/primzurum-image.png'
import Alp from 'images/rasitgr/pap-min.png';
import Ergun from 'images/rasitgr/horsbtcher-min.jpg';
import Kerem from 'images/rasitgr/ponybutcher-min.jpg';
import Team from './Team';
import { Helmet } from 'react-helmet-async';

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-green-500`;
  const HighlightedText = tw.span`text-green-500`;

  return (
    <div>
          <Helmet>
        <title>Primzurum | Satirical Mobile Game | RASITGR Games</title>
        <meta
          name="description"
          content="Primzurum is a satirical mobile game challenging players to pick the wrong answer in a sea of right ones. It’s a twist on decision-making in a world where wrong choices are rewarded."
        />
        <meta name="keywords" content="Primzurum, satirical game, mobile game, decision making, RASITGR Games" />
        <meta property="og:title" content="Primzurum | Satirical Mobile Game" />
        <meta property="og:description" content="Challenge yourself to pick the wrong answer in Primzurum, a satirical mobile game from RASITGR Games." />
      </Helmet>
      
       <Hero roundedHeaderButton={true} />
     
      <InformativeFeature
        subheading={<Subheading>What Is that?</Subheading>}
        heading={
          <>
            Prim<HighlightedText>zurum</HighlightedText>
          </>
        }
        description={"Primzurum, is a game with questions and answers in a meme format. We have have made it as a reaction."}
      id='video'
      />
       
       <MainFeature
  subheading={<Subheading>Developed</Subheading>}
  heading="As a Reaction "
  imageSrc={PrimzurumImage}
  imageBorder={true}
  imageDecoratorBlob={true}
  description={"This boss... oh, this boss... Inconsistent, ignorant, and unbalanced. The only thing he loves is... Profit. But come on, it can't be this bad!"}
/>


<Team id="team" />

<Testimonial
  subheading={<Subheading>Testers</Subheading>}
  heading={
    <>
      They <HighlightedText>Supported</HighlightedText> Primzurum
    </>
  }
  description={"They tried Primzurum. At first, they were confused, then they figured it out. After that, they progressed without any mistakes."}
  testimonials={[
    {
      stars: 2,
      profileImageSrc: Kerem,
      heading: "They made me create bad characters",
      quote: "They gave me a 'meme' and told me to make something from it. I made it perfectly, but then they didn’t like it and simplified it. That’s why I deducted 3 points.",
      customerName: "Kerem 'PonyButcher'",
      customerTitle: "3D Artist"
    },
    {
      stars: 5,
      profileImageSrc: Ergun,
      heading: "Glad we made it",
      quote: "It was my idea to make the game. I got Pap to design it, we finished it in a week. It turned out pretty good. It was necessary to react.",
      customerName: "Ergün 'HorsButcher'",
      customerTitle: "Game Developer"
    },
    {
      stars: 4,
      profileImageSrc: Alp,
      heading: "Not bad actually",
      quote: "At first, it seemed like an unnecessary project, but it wasn’t too exhausting. After playing, I understood its necessity better. Then, everyone reacted.",
      customerName: "Alp 'Pap'",
      customerTitle: "Game Director & Developer"
    }
  ]}
/>

     
      <div id="rules" ></div>
     <Download/>

        <GameGuide  />

   
    <Footer />
    </ div>
  );
}
