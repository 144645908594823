import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings";
import Hero from 'components/tr/Hero2024';
import Footer from 'components/tr/Footer';
import { Helmet } from "react-helmet-async";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-400 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-300`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Feragatname" }) => {
  return (
    <div>
      <Helmet>
        <title>Feragatname | RASITGR Games</title>
        <meta
          name="description"
          content="RASITGR Games oyunları 'olduğu gibi' sunulmaktadır ve oyunlarımızın hatasız çalışacağını garanti etmiyoruz."
        />
        <meta name="keywords" content="Sorumluluk Reddi, RASITGR Games" />
        <meta property="og:title" content="Sorumluluk Reddi | RASITGR Games" />
        <meta property="og:description" content="Oyunlarımız hatasız çalışmayabilir, sorumluluğumuz bulunmamaktadır." />
      </Helmet>
      <Hero/>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>

          <p>Son güncelleme: 14 Eylül 2024</p>

<p>RASITGR Games ("biz," "bizi" veya "bizim") tarafından https://rasitgr.com ("Site") üzerinde sağlanan bilgiler yalnızca genel bilgilendirme amaçlıdır. Sitedeki tüm bilgiler iyi niyetle sağlanmaktadır; ancak, Sitedeki bilgilerin doğruluğu, yeterliliği, geçerliliği, güvenilirliği, erişilebilirliği veya eksiksizliği konusunda herhangi bir açık veya zımni beyanda bulunmuyoruz.</p>

<h1>Dış Bağlantılar Sorumluluk Reddi</h1>
<p>
Site, üçüncü şahıslara ait veya onlardan kaynaklanan diğer web sitelerine veya içeriklere bağlantılar içerebilir veya bannerlar veya diğer reklamlar aracılığıyla web sitelerine ve özelliklere bağlantılar içerebilir. Bu tür dış bağlantılar, doğruluk, yeterlilik, geçerlilik, güvenilirlik, erişilebilirlik veya eksiksizlik açısından tarafımızdan araştırılmamış, izlenmemiş veya kontrol edilmemiştir.
</p>
<p>
Üçüncü taraf web sitelerinde sunulan bilgilerin doğruluğu veya güvenilirliği konusunda herhangi bir garanti vermiyoruz, onaylamıyoruz, sorumluluk üstlenmiyoruz veya teminat vermiyoruz. Site üzerinden bağlantılı herhangi bir web sitesi veya banner veya diğer reklamlar aracılığıyla bağlantılı herhangi bir özellikte sunulan bilgilerden kaynaklanan herhangi bir işlemde taraf olmayacağız ve bu tür işlemleri izleme sorumluluğumuz bulunmamaktadır.
</p>

<h1>Sorumluluk Sınırlamaları</h1>
<p>
RASITGR Games veya ekibi, Site'nin kullanımıyla ilgili olarak doğrudan, dolaylı, arızi, özel veya sonuç olarak ortaya çıkan zararlardan (içerikteki hatalar veya ihmaller, verilerin kaybolması veya bozulması dahil ancak bunlarla sınırlı olmamak üzere) sorumlu olmayacaktır. Bu sorumluluk, Site'deki kullanımınızla ya da Google Analytics gibi üçüncü taraf araçlardan kaynaklanan izleme faaliyetleriyle ilgili olabilir.
</p>
<p>
Google Analytics gibi üçüncü taraf araçlar tarafından gerçekleştirilen veri izleme veya analizlerinden sorumlu değiliz ve gizlilik ayarlarınızı anlamaktan ve yönetmekten siz sorumlusunuz.
</p>

<h1>Hatalar veya Eksiklikler İçin Sorumluluk Kabul Edilmemesi</h1>
<p>
En güncel ve doğru bilgileri sağlamaya gayret etsek de, RASITGR Games bu web sitesindeki içeriğin doğruluğu, eksiksizliği veya güvenilirliği konusunda herhangi bir garanti veya taahhütte bulunmamaktadır. Bu web sitesinde sağlanan içerik her zaman güncel olmayabilir veya sektör gelişmelerini yansıtmayabilir.
</p>

<h1>"Olduğu Gibi" ve "Mevcut Olduğu Kadar" Esasında Sunulma</h1>
<p>
Bu Sitedeki içerik "olduğu gibi" ve "mevcut olduğu kadar" esasında sağlanmaktadır. Sitenin işleyişi, bilgilerin doğruluğu veya Sitedeki içerikler veya materyaller hakkında herhangi bir açık veya zımni garanti veya teminat vermiyoruz.
</p>

<h1>Profesyonel Sorumluluk Reddi</h1>
<p>
Bu Site hukuki veya profesyonel tavsiye içermez ve içermemektedir. Herhangi bir hukuki veya profesyonel bilgi, yalnızca genel bilgilendirme ve eğitim amaçlıdır ve profesyonel tavsiyenin yerine geçmez. Bu nedenle, bu tür bilgilere dayanarak herhangi bir işlem yapmadan önce, ilgili profesyonellere danışmanızı öneririz.
</p>

<h1>Google Analytics Açıklaması</h1>
<p>
Web sitesi trafiğini ve kullanıcı davranışını izlemek ve analiz etmek için Google Analytics kullanıyoruz. Google Analytics, IP adresiniz, tarayıcı türü, işletim sistemi, yönlendiren URL'ler ve sayfalarda geçirilen süre gibi bilgileri toplar. Bu bilgiler istatistiksel amaçlar için toplanır ve web sitemizi geliştirmemize yardımcı olur.
</p>
<p>
Google Analytics tarafından toplanan veriler anonimdir ve bireysel ziyaretçileri tanımlamaz. Google'ın bu verileri nasıl kullandığı hakkında daha fazla bilgi için <a href="https://policies.google.com/privacy">Google Gizlilik Politikası</a>'na başvurabilirsiniz.
</p>
<p>
Google Analytics takibini devre dışı bırakmak isterseniz, Google Analytics Opt-out Tarayıcı Eklentisini <a href="https://tools.google.com/dlpage/gaoptout">buradan</a> yükleyebilirsiniz.
</p>

<h1>Çerezler ve İzleme Açıklaması</h1>
<p>
Kişisel izleme için çerez kullanmasak da, Google Analytics, anonim kullanım verilerini toplamak amacıyla cihazınıza çerezler yerleştirebilir. Tarayıcı ayarlarınızda çerezleri yönetebilir veya engelleyebilirsiniz, ancak bu, Site ile olan deneyiminizi etkileyebilir.
</p>
<p>
Google Analytics'in çerezleri nasıl kullandığı hakkında daha fazla bilgi için <a href="https://policies.google.com/privacy">Google Gizlilik Politikası</a>'na başvurabilirsiniz.
</p>



<h1>Bu Sorumluluk Reddi Beyanındaki Değişiklikler</h1>
<p>
Bu Sorumluluk Reddi Beyanını herhangi bir zamanda değiştirme hakkımız saklıdır. Yapılan değişiklikler, "Son güncelleme" tarihi ile bu sayfada güncellenecektir. Bu Sorumluluk Reddi Beyanındaki değişikliklerden sonra Siteyi kullanmaya devam etmeniz, değiştirilen şartları kabul ettiğiniz anlamına gelir.
</p>
<p>
Gelecekte yeni analiz araçları veya üçüncü taraf hizmetler kullanmaya başladığımızda, bu Sorumluluk Reddi Beyanını güncelleyecek ve kullanıcılara bildirimde bulunacağız. Güncellemeler için bu beyanı düzenli olarak gözden geçirmenizi öneririz.
</p>


<h1>Bize Ulaşın</h1>
<p>Bu Sorumluluk Reddi Beyanı hakkında herhangi bir sorunuz varsa, bizimle şu adresten iletişime geçebilirsiniz:</p>

<ul>
  <li>info@rasitgr.com</li>
</ul>

          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </div>
  );
};
