import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings";
import Hero from 'components/en/Hero2024';
import Footer from 'components/en/Footer';
import { Helmet } from "react-helmet-async";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-400 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-300`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Karantina Günlükleri: Anadolu Rock için Yasal Bilgilendirme" }) => {
  return (
    <div>
      <Helmet>
  <title>Karantina Günlükleri | Yasal Bilgiler | RASITGR Games</title>
  <meta
    name="description"
    content="Karantina Günlükleri: Anadolu Rock oyunu ile ilgili yasal bilgiler, gizlilik politikası, kullanım şartları ve sorumluluk reddi."
  />
  <meta name="keywords" content="Karantina Günlükleri, yasal bilgiler, gizlilik politikası, kullanım şartları, RASITGR Games" />
  <meta property="og:title" content="Karantina Günlükleri | Yasal Bilgiler" />
  <meta property="og:description" content="Karantina Günlükleri: Anadolu Rock oyunu için gizlilik politikası, kullanım şartları ve yasal sorumluluk reddi." />
</Helmet>

      <Hero/>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>


          <p>Son güncelleme: 14 Eylül 2024</p>

<p>RASITGR Games'e hoş geldiniz. Aşağıda, mobil oyunumuz Karantina Günlükleri: Anadolu Rock'un kullanımına ilişkin yasal şartlar ve koşullar yer almaktadır. Oyunumuzu oynayarak bu şartları kabul etmiş olursunuz.</p>
<br></br>
<hr></hr>
<h1>Gizlilik Politikası</h1>
<p>RASITGR Games, Karantina Günlükleri: Anadolu Rock mobil oyunu kullanıcılarından herhangi bir kişisel veri toplamaz, saklamaz veya paylaşmaz.</p>
<p>Uygulama veya web sitesi aracılığıyla izleme araçları, analizler veya reklam hizmetleri kullanmıyoruz ve herhangi bir kişisel bilgi toplanmamaktadır.</p>
<h2>Harici Bağlantılar</h2>
<p>Oyunumuz, Google Play Store gibi harici web sitelerine bağlantılar içerebilir. Bu harici web sitelerinin gizlilik uygulamalarından veya içeriklerinden sorumlu değiliz.</p>
<p>Bu Gizlilik Politikası ile ilgili herhangi bir sorunuz varsa, bizimle şu adresten iletişime geçebilirsiniz: info@rasitgr.com</p>
<br></br>
<hr></hr>

<h1>Son Kullanıcı Lisans Anlaşması (EULA)</h1>
<p>Karantina Günlükleri: Anadolu Rock'u indirerek veya kullanarak aşağıdaki şartları kabul etmiş olursunuz:</p>
<ul>
  <li>Lisans: RASITGR Games size Karantina Günlükleri: Anadolu Rock'u yalnızca kişisel ve ticari olmayan amaçlarla kullanmanız için sınırlı, münhasır olmayan, devredilemez ve iptal edilebilir bir lisans verir.</li>
  <li>Fikri Mülkiyet: Oyundaki tüm içerik RASITGR Games'e aittir ve telif hakkı yasalarıyla korunmaktadır. Oyunu değiştiremez, dağıtamaz veya türev çalışmalar oluşturamazsınız.</li>
  <li>Sınırlamalar: Oyunu tersine mühendislik yapamaz, derleyemez veya parçalayamazsınız. Oyunun yetkisiz kullanımı veya dağıtımı yasaktır.</li>
  <li>Fesih: Bu lisans, bu şartları ihlal etmeniz durumunda sona erecektir. Fesih üzerine, oyunu kullanmayı durdurmalı ve cihazlarınızdan tüm kopyaları silmelisiniz.</li>
</ul>
<p>EULA ile ilgili sorular için bizimle şu adresten iletişime geçebilirsiniz: info@rasitgr.com</p>
<br></br>
<hr></hr>

<h1>Sorumluluk Reddi</h1>
<p>Karantina Günlükleri: Anadolu Rock oyunu "olduğu gibi" sağlanmakta olup, RASITGR Games oyunun performansı veya hatasız olacağı konusunda garanti vermez.</p>
<p>Oyunu kullanarak, cihaz arızaları, veri kaybı veya diğer teknik sorunlar nedeniyle oluşabilecek zararlar veya kayıplardan RASITGR Games'in sorumlu olmadığını kabul edersiniz.</p>
<p>Herhangi bir endişeniz için şu adresten bizimle iletişime geçebilirsiniz: info@rasitgr.com</p>
<br></br>
<hr></hr>

<h1>Şartlar ve Koşullar</h1>
<p>Karantina Günlükleri: Anadolu Rock'u indirerek veya oynayarak aşağıdaki şartları kabul etmiş olursunuz:</p>
<ul>
  <li>Oyunun Kullanımı: Karantina Günlükleri: Anadolu Rock'u yalnızca kişisel ve ticari olmayan amaçlarla kullanabilirsiniz. Oyun, RASITGR Games'in izni olmadan değiştirilemez, kopyalanamaz veya yeniden dağıtılamaz.</li>
  <li>Fikri Mülkiyet: Oyun ve tüm ilgili varlıklar RASITGR Games'e aittir ve telif hakkı ve fikri mülkiyet yasalarıyla korunmaktadır.</li>
  <li>Sorumluluğun Sınırlandırılması: RASITGR Games, oyunun kullanımı sonucu meydana gelen herhangi bir hasar veya sorunlardan, cihaz arızaları veya veri kaybı da dahil olmak üzere sorumlu değildir.</li>
  <li>Yönetim Hukuku: Bu şartlar Türkiye Cumhuriyeti yasalarına tabidir.</li>
</ul>
<p>Bu Şartlarla ilgili sorular için bizimle şu adresten iletişime geçebilirsiniz: info@rasitgr.com</p>

          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </div>
  );
};
