import AnimationRevealPage from "helpers/AnimationRevealPage";
import React from "react";
import Team from "pages/OurTeam";
import Method from "pages/HowWeMake";
import Start from "./HowWeStart";
import Testers from "pages/Testers.js";
import Hero from 'components/en/Hero2024';
import Footer from "components/en/Footer";
import Features from 'components/en/AboutUsFeatures';
import { Helmet } from "react-helmet-async";

function AboutUs() {
  return (
    <div>
      <Helmet>
  <title>About Us | RASITGR Games</title>
  <meta
    name="description"
    content="Learn about RASITGR Games, an independent game publisher that creates unique, story-driven indie games with a global team."
  />
  <meta name="keywords" content="about us, RASITGR Games, indie game publisher, game development, game studio" />
  <meta property="og:title" content="About Us | RASITGR Games" />
  <meta property="og:description" content="Discover the story of RASITGR Games and how we create unique, story-driven indie games." />
</Helmet>

      <Hero />
      <Start />
      <Features />
  
      <Footer />
    </div>
  );
}

export default AboutUs;
