import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings";
import Hero from 'components/en/Hero2024';
import Footer from 'components/en/Footer';
import { Helmet } from "react-helmet-async";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-400 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-300`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Legal Information for Quarantine Chronicles: Anatolian Rock" }) => {
  return (
    <div>
      <Helmet>
  <title>Quarantine Chronicles | Legal Information | RASITGR Games</title>
  <meta
    name="description"
    content="Legal information for Quarantine Chronicles: Anatolian Rock, including privacy policy, terms, and disclaimer."
  />
  <meta name="keywords" content="Quarantine Chronicles, legal information, privacy policy, terms, RASITGR Games" />
  <meta property="og:title" content="Quarantine Chronicles | Legal Information" />
  <meta property="og:description" content="Privacy policy, terms and conditions, and legal disclaimer for Quarantine Chronicles." />
</Helmet>

      <Hero/>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>


            <p>Last updated: Sep 14, 2024</p>

            <p>Welcome to RASITGR Games. Below are the legal terms and conditions that apply to the use of our mobile game Quarantine Chronicles: Anatolian Rock. By playing our game, you agree to these terms.</p>
            <br></br>
            <hr></hr>
            <h1>Privacy Policy</h1>
            <p>RASITGR Games does not collect, store, or share any personal data from users of the mobile game Quarantine Chronicles: Anatolian Rock.</p>
            <p>We do not use tracking tools, analytics, or advertising services, and no personal information is collected through the app or website.</p>
            <h2>External Links</h2>
            <p>Our game may contain links to external websites, such as the Google Play Store. We are not responsible for the privacy practices or content of these external websites.</p>
            <p>If you have any questions regarding this Privacy Policy, you can contact us at: info@rasitgr.com</p>
            <br></br>
            <hr></hr>

            <h1>End-User License Agreement (EULA)</h1>
            <p>By downloading or using Quarantine Chronicles: Anatolian Rock, you agree to the following terms:</p>
            <ul>
              <li>License: RASITGR Games grants you a limited, non-exclusive, non-transferable, and revocable license to use Quarantine Chronicles: Anatolian Rock for personal, non-commercial purposes only.</li>
              <li>Intellectual Property: All content within the game is owned by RASITGR Games and is protected by copyright laws. You may not modify, distribute, or create derivative works from the game.</li>
              <li>Limitations: You may not reverse-engineer, decompile, or disassemble the game. Unauthorized use or distribution of the game is prohibited.</li>
              <li>Termination: This license will terminate if you breach these terms. Upon termination, you must stop using the game and delete all copies from your devices.</li>
            </ul>
           <p> For questions about the EULA, contact us at: info@rasitgr.com</p>
           <br></br>
          <hr></hr>

            <h1>Disclaimer</h1>
            <p>
            The game Quarantine Chronicles: Anatolian Rock is provided "as-is" without any warranties. RASITGR Games does not guarantee the performance of the game or that it will be free from errors or bugs.
            </p>
            <p>By using the game, you agree that RASITGR Games is not responsible for any damages or losses related to device malfunctions, data loss, or other technical issues.</p>
            <p>
            For any concerns, reach out at: info@rasitgr.com
            </p>
            <br></br>
            <hr></hr>
          
            <h1>Terms And Conditions</h1>
            <p>
            By downloading or playing Quarantine Chronicles: Anatolian Rock, you agree to the following terms:
            </p>
            <ul>
              <li>Use of Game: You may use Quarantine Chronicles: Anatolian Rock for personal, non-commercial purposes only. The game may not be modified, copied, or redistributed without permission from RASITGR Games.</li>
              <li>Intellectual Property: The game and all associated assets are owned by RASITGR Games and are protected by copyright and intellectual property laws.</li>
              <li>Limitation of Liability: RASITGR Games is not liable for any damages or issues resulting from the use of the game, including device malfunctions or data loss.</li>
              <li>Governing Law: These terms are governed by the laws of Türkiye Republic.</li>
            </ul>
            <p>For questions regarding these Terms, please contact us at: info@rasitgr.com</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </div>
  );
};
