import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import banner from 'images/rasitgr/quarantine-logo.png';

import Header, { LogoLink, NavLinks, NavLink as NavLinkBase } from "../../components/headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`justify-between`}
  ${LogoLink} {
    ${tw`mr-8 pb-0`}
  }
`;

const NavLink = tw(NavLinkBase)`
  sm:text-sm sm:mx-6 text-gray-400
`;

const Container = tw.div`relative -mx-8 -mt-8 bg-green-900`;
const TwoColumn = tw.div`flex flex-col lg:flex-row bg-green-900`;
const LeftColumn = tw.div`ml-8 mr-8 xl:pl-10 py-8 bg-green-900`;
const RightColumn = styled.div`
  background-image: url(${banner});
  ${tw`bg-green-500 bg-cover bg-center xl:ml-24 h-96 lg:h-auto lg:w-1/2 lg:flex-1`}
`;

const Content = tw.div`mt-24 lg:mt-24 lg:mb-24 flex flex-col sm:items-center lg:items-stretch`;
const Heading = tw.h1`text-3xl sm:text-5xl md:text-6xl lg:text-5xl font-black leading-none text-gray-400`;
const Paragraph = tw.p`max-w-md my-8 lg:my-5 lg:my-8 sm:text-lg lg:text-base xl:text-lg leading-loose text-gray-300`;

const Actions = styled.div`
  ${tw`mb-8 lg:mb-0`}
  .action {
    ${tw`text-center inline-block w-full sm:w-48 py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  }
  .primaryAction {
    ${tw`bg-green-500 text-gray-100 hover:bg-green-700`}
  }
  .secondaryAction {
    ${tw`mt-4 sm:mt-0 sm:ml-4 bg-red-700 text-gray-200 hover:bg-red-500 hover:text-gray-900`}
  }
`;

export default ({
  navLinks = [
    <NavLinks key={1}>
      <NavLink href="https://play.google.com/store/apps/details?id=com.DefaultCompany.EvdeKal">İndir</NavLink>
      <NavLink href="#">Trailer</NavLink>
      <NavLink href="/en/quarantinechronicles/legal">Yasal</NavLink>
      <NavLink href="/en/quarantinechronicles#team">Geliştirme Ekibi</NavLink>
    </NavLinks>
  ],
  heading = (
    <>
      Karantinada, 
      <wbr />
      <br />
      <span tw="text-red-700">Sanal bi bebek</span>
    </>
  ),
  description = "Karantina Günlükleri', Anadolu Rock fanı bir genç olan Cengiz'in hayatını işlemektedir. Temel amaç, Cengiz'in 45 günlük karantina sürecini başarıyla atlatmasını sağlamaktır. Cengiz bu yolda, gün geçtikçe ağırlaşan bir mental mücadelenin içinde olacaktır. Edinilen her alışkanlığın çok geç olmadan bir dışavurumunun olduğu Karantina Günlükleri'nde oyunculara sıradışı bir sanal bebek deneyimi sunulmaktadır.",
  primaryActionUrl = "https://play.google.com/store/apps/details?id=com.DefaultCompany.EvdeKal",
  primaryActionText = "Play Store'da Oyna",
  secondaryActionUrl = "#",
  secondaryActionText = "Trailer'ı İzle"
}) => {
  return (
    <Container>
      <TwoColumn>
        <LeftColumn>
          <StyledHeader links={navLinks} collapseBreakpointClass="sm" />
          <Content>
            <Heading>{heading}</Heading>
            <Paragraph>{description}</Paragraph>
            <Actions>
              <a href={primaryActionUrl} className="action primaryAction">
                {primaryActionText}
              </a>
              <a href={secondaryActionUrl} className="action secondaryAction">
                {secondaryActionText}
              </a>
            </Actions>
          </Content>
        </LeftColumn>
        <RightColumn></RightColumn>
      </TwoColumn>
    </Container>
  );
};
