import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import banner from 'images/rasitgr/cdw-image.jpg';

import Header, { LogoLink, NavLinks, NavLink as NavLinkBase } from "../../components/headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`justify-between`}
  ${LogoLink} {
    ${tw`mr-8 pb-0`}
  }
`;

const NavLink = tw(NavLinkBase)`
  sm:text-sm sm:mx-6 text-gray-400
`;

const Container = tw.div`relative -mx-8 -mt-8 bg-green-900`;
const TwoColumn = tw.div`flex flex-col lg:flex-row bg-green-900`;
const LeftColumn = tw.div`ml-8 mr-8 xl:pl-10 py-8 bg-green-900`;
const RightColumn = styled.div`
  background-image: url(${banner});
  ${tw`bg-green-500 bg-cover bg-center xl:ml-24 h-96 lg:h-auto lg:w-1/2 lg:flex-1`}
`;

const Content = tw.div`mt-24 lg:mt-24 lg:mb-24 flex flex-col sm:items-center lg:items-stretch`;
const Heading = tw.h1`text-3xl sm:text-5xl md:text-6xl lg:text-5xl font-black leading-none text-gray-400`;
const Paragraph = tw.p`max-w-md my-8 lg:my-5 lg:my-8 sm:text-lg lg:text-base xl:text-lg leading-loose text-gray-300`;

const Actions = styled.div`
  ${tw`mb-8 lg:mb-0`}
  .action {
    ${tw`text-center inline-block w-full sm:w-48 py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  }
  .primaryAction {
    ${tw`bg-green-500 text-gray-100 hover:bg-green-700`}
  }
  .secondaryAction {
    ${tw`mt-4 sm:mt-0 sm:ml-4 bg-red-700 text-gray-200 hover:bg-red-500 hover:text-gray-900`}
  }
`;

export default ({
  navLinks = [
    <NavLinks key={1}>
      <NavLink href="https://www.shopier.com/ShowProductNew/products.php?id=12032393">Satın al</NavLink>
      <NavLink href="https://www.youtube.com/watch?v=mqJcIxKklws&list=PLcIH5DNEjaGUH39hSJVmcW35A42odRYn_&index=2">Trailer</NavLink>
      <NavLink href="/en/crazydemagogywars/legal">Yasal</NavLink>
      <NavLink href="#team">Geliştirme Ekibi</NavLink>
    </NavLinks>
  ],
  heading = (
    <>
      Çılgın Algı Savaşları
      <wbr />
   
    </>
  ),
  description = "Çılgın Algı Savaşları: Piyasadaki benzer kart oyunlarının monoton ve tekrarlayıcı yapısından sıkıldık ve sadece kendimiz oynamak için havalı bir oyun geliştirdik. Peki sonra ne mi yaptık? Seri üretime geçtik ve Çılgın Algı Savaşları'nı herkesle paylaştık.",
  primaryActionUrl = "https://www.shopier.com/ShowProductNew/products.php?id=12032393",
  primaryActionText = "Shopier'den satın al",
  secondaryActionUrl = "https://www.youtube.com/watch?v=3Mhxfj7OMJw&list=PLcIH5DNEjaGUH39hSJVmcW35A42odRYn_",
  secondaryActionText = "Nasıl oynanır izle",
  tertiaryActionUrl ="https://drive.google.com/file/d/1rBKPPypLUv7cAnjt-VPaXN24m_XAq3hD/view?usp=drive_link",
  tertiaryActionText="Nasıl oynanır oku"
}) => {
  return (
    <Container>
      <TwoColumn>
        <LeftColumn>
          <StyledHeader links={navLinks} collapseBreakpointClass="sm" />
          <Content>
            <Heading>{heading}</Heading>
            <Paragraph>{description}</Paragraph>
            <Actions>
              <a href={primaryActionUrl} className="action primaryAction">
                {primaryActionText}
              </a>
              <a href={secondaryActionUrl} className="action secondaryAction">
                {secondaryActionText}
              </a>
              <a href={tertiaryActionUrl} className="action secondaryAction">
                {tertiaryActionText}
              </a>
            </Actions>
          </Content>
        </LeftColumn>
        <RightColumn></RightColumn>
      </TwoColumn>
    </Container>
  );
};
