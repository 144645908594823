import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import banner from 'images/rasitgr/primzurum-logo.png';

import Header, { LogoLink, NavLinks, NavLink as NavLinkBase } from "../../components/headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`justify-between`}
  ${LogoLink} {
    ${tw`mr-8 pb-0`}
  }
`;

const NavLink = tw(NavLinkBase)`
  sm:text-sm sm:mx-6 text-gray-400
`;

const Container = tw.div`relative -mx-8 -mt-8 bg-green-900`;
const TwoColumn = tw.div`flex flex-col lg:flex-row bg-green-900`;
const LeftColumn = tw.div`ml-8 mr-8 xl:pl-10 py-8 bg-green-900`;
const RightColumn = styled.div`
  background-image: url(${banner});
  ${tw`bg-green-500 bg-cover bg-center xl:ml-24 h-96 lg:h-auto lg:w-1/2 lg:flex-1`}
`;

const Content = tw.div`mt-24 lg:mt-24 lg:mb-24 flex flex-col sm:items-center lg:items-stretch`;
const Heading = tw.h1`text-3xl sm:text-5xl md:text-6xl lg:text-5xl font-black leading-none text-gray-400`;
const Paragraph = tw.p`max-w-md my-8 lg:my-5 lg:my-8 sm:text-lg lg:text-base xl:text-lg leading-loose text-gray-300`;

const Actions = styled.div`
  ${tw`mb-8 lg:mb-0`}
  .action {
    ${tw`text-center inline-block w-full sm:w-48 py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  }
  .primaryAction {
    ${tw`bg-green-500 text-gray-100 hover:bg-green-700`}
  }
  .secondaryAction {
    ${tw`mt-4 sm:mt-0 sm:ml-4 bg-primary-700 text-gray-200 hover:bg-red-500 hover:text-gray-900`}
  }
`;

export default ({
  navLinks = [
    <NavLinks key={1}>
      <NavLink href="https://rasitgr-games.itch.io/primzurum">Download</NavLink>
      <NavLink href="#">Trailer</NavLink>
      <NavLink href="/en/primzurum/legal">Legal</NavLink>
      <NavLink href="#team">Development Team</NavLink>
    </NavLinks>
  ],
  heading = (
    <>
      Primzurum, 
      <wbr />
   
    </>
  ),
  description = "Primzurum takes a twist on a familiar concept, turning the famous corporate meme into an interactive mobile experience. In a world where the right answers are often obvious, Primzurum challenges players to think differently by asking them to pick the one wrong answer among a sea of right ones. It's a satirical take on decision-making in a world that seems to reward the wrong choices.",
  primaryActionUrl = "https://rasitgr-games.itch.io/primzurum",
  primaryActionText = "Play on Itch",
  secondaryActionUrl = "#",
  secondaryActionText = "Watch Trailer"
}) => {
  return (
    <Container>
      <TwoColumn>
        <LeftColumn>
          <StyledHeader links={navLinks} collapseBreakpointClass="sm" />
          <Content>
            <Heading>{heading}</Heading>
            <Paragraph>{description}</Paragraph>
            <Actions>
              <a href={primaryActionUrl} className="action primaryAction">
                {primaryActionText}
              </a>
              <a href={secondaryActionUrl} className="action secondaryAction">
                {secondaryActionText}
              </a>
            </Actions>
          </Content>
        </LeftColumn>
        <RightColumn></RightColumn>
      </TwoColumn>
    </Container>
  );
};
