import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

const Container = tw.div`relative -mx-8 px-8 bg-gray-900`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-400`;
const Heading = tw(SectionHeading)`w-full text-gray-300`;
const Description = tw(SectionDescription)`w-full text-center text-gray-400`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-center text-2xl leading-none text-red-600`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-center leading-loose text-gray-400`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = "Amazing Features", subheading = "Features", description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    { imageSrc: ShieldIconImage, title: "Colorful", description: "We added a touch of every color for a vibrant gaming experience. While not every card is equal, we ensured a fair game." },
    { imageSrc: SupportIconImage, title: "Fluid", description: "For those expecting to say, 'Are we playing chess?' we've got bad news. Every move here is carefully thought out in advance." },
    { imageSrc: CustomizeIconImage, title: "Competitive", description: "Even if you're not winning this round, don’t worry—you can still decide who won’t win. The show must go on." },
    { imageSrc: ReliableIconImage, title: "Fun", description: "In this game, winning and losing never cross into the realm of boredom. Every round delivers a wildly unique experience." },
    { imageSrc: FastIconImage, title: "Realistic", description: "Though it’s a card game filled with absurd rhymes, the realism of Crazy Demagogy Wars will strike just as clearly as it touches your heart." },
    { imageSrc: SimpleIconImage, title: "Unconventional", description: "We guarantee you’ll have a unique experience, but the quirky vibe of Crazy Demagogy Wars will feel surprisingly familiar." }
  ];
  

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading><h2>{heading} </h2> </Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              {/* <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span> */}
              <span className="textContainer">
                <h3 className="title">{card.title || "Fully Secure"}</h3>
                <p className="description">
                  {card.description || "Karantinada 45 gün geçirecek bi gencin mental sınavına ortak olduğunuz bi sanal bebek oyunu. Bu süreçte birçok mücadele unsuru sınırlarınızı zorlayacak."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
