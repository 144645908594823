import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/en/Hero2024";
import Footer from "components/en/Footer";
import { SectionHeading } from "components/misc/Headings";
import { Helmet } from "react-helmet-async";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-400 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-300`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Privacy Policy" }) => {
  return (
    <div>
      <Helmet>
  <title>Privacy Policy | RASITGR Games</title>
  <meta
    name="description"
    content="RASITGR Games is committed to user privacy. We do not collect or share personal data."
  />
  <meta name="keywords" content="privacy policy, personal data, RASITGR Games" />
  <meta property="og:title" content="Privacy Policy | RASITGR Games" />
  <meta property="og:description" content="RASITGR Games does not collect or share personal information. Learn more about our privacy practices." />
</Helmet>

      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: September 14, 2024</p>

            <p>
            This Privacy Policy outlines how RASITGR Games (referred to as "the Company," "We," "Us," or "Our") approaches privacy matters on its website, https://rasitgr.com. As part of our commitment to privacy, we want to be clear that we do not collect, store, or share any personal data from users visiting our website.
            </p>    

            <h1>Interpretation and Definitions</h1>
            <h2>Interpretation</h2>
            <p>
            The words of which the initial letter is capitalized have meanings defined under the following conditions. 
            </p>
            <p>
            The following definitions shall have the same meaning regardless of whether they appear in singular or plural.
            </p>

            <h2>Definitions</h2>
            <p>For the purposes of this Privacy Policy:</p>
            <ul>
              <li>
                <p>
                  <strong>You</strong> means the individual accessing or using the Service, or the company, or other
                  legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                </p>
              </li>
              <li>
                <p>
                  <strong>Company</strong> refers to RASITGR Games, owned by Alp Kurt.
                </p>
              </li>
              <li>
                <strong>Website</strong> refers to RASITGR Games, accessible from https://rasitgr.com.
              </li>{" "}
              <li>
                <strong>Service</strong> refers to the Website.
              </li>
            </ul>

            <h2>Information We Collect</h2>
<p>We may collect the following information when you visit our site:</p>
<ul>
  <li>
    <p><strong>Usage Data</strong>: Usage Data is collected automatically when you interact with our website. This may include information such as your IP address, browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other diagnostic data. Usage Data is collected for statistical purposes and to improve our services.</p>
  </li>
</ul>

<h2>Google Analytics</h2>
<p>
  We use Google Analytics to monitor and analyze website traffic and behavior. Google Analytics collects information anonymously and reports website trends without identifying individual visitors. This helps us understand how users interact with our website so that we can improve the user experience.
</p>
<p>
  You can opt out of Google Analytics by installing the Google Analytics opt-out browser add-on.
</p>
<p>
  For more details on how Google uses your data, please refer to their privacy policy: <a href="https://policies.google.com/privacy">Google Privacy Policy</a>.
</p>

<h1>Cookies and Tracking</h1>
<p>
  We do not use cookies for personalized tracking. However, Google Analytics may use cookies to collect and analyze usage data. You can manage cookies in your browser settings to control or block tracking.
</p>

            <h1>Links to Other Websites</h1>
            <p>
            Our Service may contain links to third-party websites or services not operated by Us. If You click on a third-party link, You will be directed to that third-party’s site. We encourage You to review the Privacy Policy of every site You visit.
            </p>
            <p>
              We have no control over and assume no responsibility for the content, privacy policies or practices of any
              third party sites or services.
            </p>

            <h1>Changes to this Privacy Policy</h1>
            <p>
              We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new
              Privacy Policy on this page.
            </p>
            <p>
              You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy
              are effective when they are posted on this page.
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </div>
  );
};
