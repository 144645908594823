import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "./HeroPZ2";
import InformativeFeature from "./InformativeFeaturesPZ";
import MainFeature from "./Feature";
import GameGuide from "./GameGuidePZ";
import FeatureWithSteps from "pages/QC/EasyToUseQC";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import Hints from "pages/QC/Hints";
import Download from "pages/PZ/DownloadPZ";
import Footer from "components/tr/Footer";
import heroScreenshotImageSrc from "images/happy-illustration.svg";
import macHeroScreenshotImageSrc from "images/signup-illustration.svg";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import Gameplay from "pages/QC/GameplayQC"
import PrimzurumImage from 'images/rasitgr/primzurum-image.png'
import Alp from 'images/rasitgr/pap-min.png';
import Ergun from 'images/rasitgr/horsbtcher-min.jpg';
import Kerem from 'images/rasitgr/ponybutcher-min.jpg';
import Team from './Team';
import { Helmet } from "react-helmet-async";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-green-500`;
  const HighlightedText = tw.span`text-green-500`;

  return (
    <div>
      <Helmet>
        <title>Primzurum | Mizahi Mobil Oyun | RASITGR Games</title>
        <meta
          name="description"
          content="Primzurum, oyuncuların doğru cevaplar arasından yanlış olanı seçmelerini isteyen mizahi bir mobil oyun. Yanlış kararlar dünyasında farklı düşünmeyi teşvik ediyor."
        />
        <meta name="keywords" content="Primzurum, mizahi oyun, mobil oyun, RASITGR Games" />
        <meta property="og:title" content="Primzurum | Mizahi Mobil Oyun" />
        <meta property="og:description" content="Primzurum'da doğru cevaplar arasından yanlış olanı bulmaya çalışın." />
      </Helmet>
       <Hero roundedHeaderButton={true} />
     
      <InformativeFeature
        subheading={<Subheading>Nedir bu?</Subheading>}
        heading={
          <>
            Prim<HighlightedText>zurum</HighlightedText>
          </>
        }
        description={"Primzurum, sorular ve cevaplardan oluşan meme formatında bi oyun. Bazı şeylere tepki olarak geliştirdik."}
      id='video'
      />
     
    
     
      <MainFeature
        subheading={<Subheading>Tepkİ Göstermek İçİn</Subheading>}
        heading="Geliştirildi"
        imageSrc={PrimzurumImage}
        imageBorder={true}
        imageDecoratorBlob={true}
        description={"Bu patron var ya bu patron... Tutarsız, cahil, dengesiz bi adam. Sevdiği tek bi şey var o da... Prim. Ama bu kadar da olmaz ki kardeşim! "}
      /> 

    <Team id='team'/>

     <Testimonial
        subheading={<Subheading>Deneyenler</Subheading>}
        heading={
          <>
            Primzurum'u <HighlightedText>Destekledi</HighlightedText>
          </>
        }
        description={"Primzurum'u bi denediler. Önce kafaları karıştı, sonra çakozladılar. Ondan sonra hatasız ilerlediler."}
        testimonials={[
          {
            stars: 2,
            profileImageSrc:
              Kerem,
            heading: "Bana kötü kötü karakterler yaptırdılar",
            quote:
              "Bana bi 'meme' verdiler, bundan yap dediler. Mis gibi yaptım, sonra beğenmediler, basitleştirttiler. 3 Puanı ordan kırdım.",
            customerName: "Kerem 'PonyButcher'",
            customerTitle: "3D Artist"
          }, 
          {
            stars: 5,
            profileImageSrc:
             Ergun,
            heading: "İyi ki yaptık",
            quote:
              "Oyunu yapmak benim fikrimdi, Pap'a tasarlattım, 1 haftada yaptık bitti. Fena olmadı, tepki göstermek lazımdı.",
            customerName: "Ergün 'HorsButcher'",
            customerTitle: "Game Developer"
          },
         
          {
            stars: 4,
            profileImageSrc:
              Alp,
            heading: "Fena olmamı aslında",
            quote:
              "Başta gereksiz bi proje gibi geldi, ama çok da yormadı. Oynayınca daha iyi anladım gerekliliğini. Sonra zaten herkes bi tepki gösterdi.",
            customerName: "Alp 'Pap'",
            customerTitle: "Game Director & Developer"
          }
        ]}
      />
     
      <div id="rules" ></div>
     <Download/>

        <GameGuide  />

      {/* <Gameplay />

      <Hints
        subheading={<Subheading>Kolay kolay oynayın dİye</Subheading>}
        heading={
          <>
            Sizin için hazırladığımız <HighlightedText>İpuçları</HighlightedText>
          </>
        }
        faqs={[
          {id: 0, title: "Uyku Barı", description: ["Uyku barı azami iki günlük uyku tutar. Yani yarısı dolduğunda uyumanın vakti gelmiştir."] },
          { id: 1, title: "Enerji Toplamak", description: ['Enerjin sıfıra düştüğünde karnını doyurmak ve uyumaya çalışmak en büyük yardımcın olacaktır.']},
            { id: 2, title: "Öz Farkındalığı Artırmak", description: ['Haberleri takip etmek veya kitap okumak bilincini artıracaktır.']},
            { id: 3, title: "Yemek Stoğu", description: ["Aynı anda azami 1.5 günlük pişmiş yemek saklayabilirsiniz."]},
            { id: 4, title: "Sağlıklılaşmak", description: ["Spor yapmak organlarının, dolayısıyla sağlığının iyileşmesine yardımcı olur."]},
            { id: 5, title: "Sağlıksızlaşmak", description: ["Tütün ürünleri kullanmak organlarının sağlığını olumsuz etkiler."]},
            { id: 6, title: "Tekrar Ediciliğin Sıkıcılığı",  description: ["Sürekli aynı aktiviteyi yapmak Cengiz'in canını sıkar ve psikolojisini olumsuz etkileyebilir. Sürekli Cengiz'in canını sıkan şeyler yapmak Cengiz'in bazı mutluluk hormonlarının düşmesine sebep olabilir."]},
            { id: 7, title: "Insomnia", description: ["Çok fazla uyumak veya düzensiz uyumak Cengiz'in uyku hastalıkları geçirmesine sebep olabilir."]},
            { id: 8, title: "Maske Kullanımı", description: ["Maskesiz dışarı çıkmak hastalık bulaşma riskini artırır ve masken sadece dışarıda kullandığında eksilecektir."]},
            { id: 9, title: "Virüsü Atlatmak", description: ["Sana hastalık bulaştığında ne kadar sağlıklıysan hastalığı o kadar kolay atlatırsınız."]},
            { id: 10, title: "Eylemler", description: ["Her bir eylem 20 dakika sürmektedir ve Yapılacaklar listesinin tamamı 12 saatte bitmektedir. Her aktivite, aktivite bittiğinde etki eder. Bir aktiviteyi bitmeden iptal etmek, o aktivitenin hiçbir etki etmemesine sebep olur. Yapılacaklar Listesi'ni boş bırakmak Cengiz'in vakit öldürmesine ve dolayısıyla canının sıkılmasına sebep olur."]},
            { id: 11, title: "Cengiz'in Sıkılması", description: ["Cengiz'in bir şeyden sıkıldığını anlamanın yolu o işi yaptıktan sonraki olumsuz bildirimlerini kontrol etmektir.'"]},
            { id: 12, title: "Gitar Çalmak", description: ["Cengiz'in odasındaki gitarı çalabilirsiniz."]},
            { id: 13, title: "Hormon Takibi", description: ["Her eylem Cengiz'in farklı hormonlarına iyi gelecektir, bunları takip edebilmek için Cengiz'in farkındalığını artırmalısınız."]},
            { id: 14, title: "Çok Spor Yapmak", description: ["Çok spor yapmak sağlığa iyi geldiği kadar Cengiz'i acıktıracaktır da.", "Hesaplama yaparken önce Popülizm Dalgasının bonusunu Miting Gücünüze ekleyin daha sonra Havuz Medyasının Bonusunu kullanın. [ö. 2(1MG + 4MG)]"]},
            { id: 15, title: "Aktivite Teyiti", description: ["Aktivite simgelerini karıştırdığında onları yapılacaklar listesine ekleyip isimlerine bakabilirsiniz."]},
            { id: 16, title: "Bağımlılık", description: ["Cengiz'in şikayetlerini ve ifadelerini Bilgi Sayfası'ndan takip edebilirsin. Cengiz bazen bağımlı olduğu davranışları kendiliğinden Yapılacaklar Listesi'ne ekleyebilir."]},
            { id: 17, title: "Cengiz'i Anlamak", description: ["Cengiz'in şikayetlerini ve ifadelerini Bilgi Sayfası'ndan takip edebilirsin."]},
            { id: 18, title: "Vaktin Ağırlığı", description: ["45 günün ikinci yarısında Cengiz'e eğleneceği bir şey sunmakta zorlanabilirsin."]},
            { id: 19, title: "Paranın Bitmesi", description: ["Paran biterse, Bilgi Sayfası'ndan reklam izleyerek para kazanabilirsiniz."]},
            { id: 20, title: "Depresyon", description: ["Cengiz endişelendiği zaman ekstra bir bildirim çıkar, bu onun depresyon başlangıcının göstergesidir. Uzun süre depresyonda kalmak Cengiz'in kalp ve bağırsak sıkıntısı çekmesine sebep olabilir."]},
            { id: 21, title: "Tütün Bağımlılığı", description: ["Zor modda oynuyorsanız da Cengiz'i tütünden kurtarabilirsiniz. Bir süre tütün kullanmamak Cengiz'i bu bağımlılıktan kurtarır.'"]},
            { id: 22, title: "Su İçmek", description: ["Cengiz'e su içirmenize gerek yoktur, stoklarında su olduğu sürece suyunu kendisi içecektir."]},
            { id: 23, title: "Aç Kalmak", description: ["Uzun süre aç kalmak Cengiz'in karın ağrısı çekmesine ve midesinin zarar görmesine sebep olabilir."]},
        ]}
      />
 */}

   
    <Footer />
    </ div>
  );
}
