import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";

const Container = tw.div`relative -mx-8 px-8 bg-gray-900`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const TwoColumn = tw.div`flex`;
const Column = tw.div``;

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
  props.imageShadow ? tw`shadow` : tw`shadow-none`,
  tw`hidden lg:block rounded h-144 bg-center`
]);

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-left text-gray-400`;
const Heading = tw(SectionHeading)`lg:text-left`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-gray-400`;

const FAQSContainer = tw.dl`mt-12`;
const FAQ = tw.div`cursor-pointer mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center text-gray-200`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold text-gray-300`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-green-500 text-gray-100 p-1 rounded-full group-hover:bg-green-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed text-gray-400`);

export default ({
  subheading = "",
  heading = "Gameplay",
  description = "The section we think should be read before starting the game, but also the one we say shouldn’t require so much reading just to play a game.",  
  imageSrc = "https://images.unsplash.com/photo-1579427421635-a0015b804b2e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
  imageContain = false,
  imageShadow = true,
  faqs = null
}) => {
  /*
   * You can modify FAQs either by modifying the below defaultFaqs array or by passing a custom array of FAQs using
   * the faqs prop
   */
  const defaultFaqs = [
    {
      question: "Activities",
      answer:
        "There are 5 main types of activities in Anadolu Rock. These are: Killing Time, Gaining Knowledge, Personal Care, Online Shopping, and Face-to-face Shopping."
    },
    {
      question: "Activities: Killing Time Activities",
      answer:
        "There are 9 killing time activities. The main one is listening to Anadolu Rock. Listening to Anadolu Rock is what benefits Cengiz the most. Other activities include playing video games, texting, exercising, scrolling on social media, watching TV, streaming series online, reading books, and listening to music. Doing these activities consecutively can cause Cengiz to become addicted, leading to harm instead of benefit. If Cengiz becomes addicted, he may add these activities to the to-do list without your knowledge."
    },
    {
      question: "Activities: News Activities",
      answer:
        "Knowledge-gaining activities are divided into three categories. However, we want to emphasize that reading a book is also a useful 'time-killing' activity :). The knowledge-gaining activities are: internet research, social media news, and TV news. These three activities can lead to different outcomes. In this era of rampant misinformation, TV news will quickly make you happy, while social media news might upset you, and internet news is like a game of Russian roulette. Of course, this isn't everything, but we'll leave the rest for you to discover :)."
    },
    {
      question: "Activities: Personal Care Activities",
      answer:
        "We categorized personal care activities as 4 + 1. Highlighting the harms of smoking, we present these five activities: ordering food, cooking, eating, sleeping, and smoking. Here's something to note: if you answered 'no' to the question 'Do you smoke?' at the start of the game, smoking will restart your nicotine addiction. To quit smoking again, you'll need to be patient for a while. During this period, Cengiz may struggle mentally, but once he quits, he’ll regain his form. Also, note that you don't need to tell Cengiz to drink water! While Cengiz can drink water on his own, if you don't tell him to eat, he might become weak from hunger. And don't forget, if you don't want to deal with insomnia, ensure Cengiz sleeps around 8 hours in one go. Fragmented or irregular sleep can cause Cengiz to experience insomnia."
    },
    {
      question: "Activities: Shopping Activities",
      answer:
        "Shopping activities are divided into two categories: online and face-to-face. Items you can buy include food supplies, water supplies, masks, and small gifts for your mental health. Additionally, in the face-to-face shopping section, you have the option to meet up with friends. While this might relax Cengiz, we regret to inform you that your friends will likely reject you, which was my idea—me, the writer of this text. This button serves no purpose other than your friends rejecting you and wasting your energy. Even if your friends don't meet you, Cengiz will still go outside. And if you go out without a mask, we're not responsible for what happens. I'm certainly not."
    },
    {
      question: "Challenge Elements",
      answer:
        "The challenge elements in Anadolu Rock might be slightly above average in detail. You're primarily responsible for resource management and ensuring various parameters remain within healthy ranges. In the next paragraph, we'll make this explanation more enjoyable at light speed!"
    },
    {
      question: "Challenge Elements: Resources",
      answer:
        "Let's start with resource management. As you've noticed from the gauge at the top of the screen, we used bars to display several resources. These include the energy bar, hunger bar, and sleep bar. Additionally, we have some money and stocks. When you press the information button, you'll see your stocks in more detail. One thing we want to emphasize is that the sleep bar represents two days' worth of sleep. So, when it's half full, an 8-hour sleep will make you happy. Another resource is your time. You must ensure that Cengiz spends every 24 hours as efficiently as possible because as the days go by, it will become harder for Cengiz to sustain himself. And don't forget, Cengiz's food stock and cooked food stock are separate. Make sure Cengiz has enough cooked food to eat successfully. If not, you can order it quickly!"
    },
    {
      question: "Challenge Elements: Parameters",
      answer:
        "When it comes to parameters, there are many details Cengiz needs to pay attention to. These are more than just the health bars you see when you press the information button. By increasing Cengiz's knowledge and awareness, you can gain more control over these details with a 'brain' button that appears. We're not explaining them in detail for you to discover, but here's a hint to pique your curiosity. Every activity Cengiz does triggers different hormones and helps him be happy with different hormones. Similarly, every activity physically wears down different parts of his body. We took a realistic approach here, and we recommend choosing activities accordingly. And finally, Cengiz's addictions... Constantly doing the same activity will make Cengiz addicted. When he's addicted, Cengiz won't release happiness hormones and will increase his anxiety. None of us want that. Like us, Cengiz deserves to be happy!"
    },
    {
      question: "Challenge Elements: Different Endings and Difficulties",
      answer:
        "You've noticed that the question at the start of the game takes on a different meaning, haven't you? This difficulty question represents the impact of Cengiz's past habits on his quarantine experience. Answering 'no' to this question and starting to smoke in the game won't cause as much trouble as answering 'yes.' It will create a mental challenge rather than a physical one. Even after starting the game with a 'yes' answer, remember that you can quit smoking and improve your health. Some of our beta testers surprised us by continuing to make Cengiz smoke even after they sent him to the hospital, teaching us that this is a real challenge. Getting back to our topic, there are at least four different ending scenarios in the game. We won't explain them in full detail for you to discover. As we mentioned before, one of the endings involves keeping Cengiz happy for the full 45 days."
    },
    {
      question: "Challenge Elements: End of the Game",
      answer:
        "When you reach any ending in the game, a notification screen will appear. You may want to take a screenshot of this screen because once you close it, you won’t be able to access it again. On this screen, you can see how many hours Cengiz spent on each activity from the first day. If you've made Cengiz live like yourself, it may not be the best method of self-criticism, but it's a good opportunity to reflect!"
    },
    {
      question: "States",
      answer:
        "To better interpret Cengiz's feelings, he gives you some hints on the Info page and in the notification screens. We'll cover these hints in this section. Generally, we have two main categories: Mental States and Physical States. Mental states are related to the balance of hormones we previously mentioned, while physical states are more easily observable from the outside. We know this explanation feels a bit awkward. We're aware that Cengiz's mental and physical states are rooted in a common physiological structure, but we've followed common misconceptions and categorized these states into two main sections."
    },
    {
      question: "States: Physical",
      answer:
        "We've divided Cengiz's physical states into short-term and long-term. Long-term challenges usually manifest as pains and worsen if not addressed. These pains can occur in five different organs of Cengiz's body, and each has different causes and solutions. As for short-term states, you might encounter complaints like sleeplessness, fatigue, thirst, hunger, and dehydration, which develop in the short term. While the solutions to these are quicker and easier, long-term complaints stem from long-standing habits, so their recovery processes are similarly long and challenging."
    },
    {
      question: "States: Mental",
      answer:
        "Cengiz's mental states are much more complex compared to physical ones. The main reason for this is the lack of hints Cengiz gives you. Like all of us, Cengiz often doesn't notice the emotional changes he's experiencing as he lives under the influence of neurotransmitter bursts, governed by his amygdala. To help Cengiz respond better to these changes and monitor his hormone balances, you need to improve his knowledge base. As mentioned earlier, there are specific activities for this. On your path to using 100% of your brain, don't stop reading, but if you use too many different sources, you may find Cengiz in a 'Confused' state! Other mental states you might encounter include: Happy, Bored, Nervous, Anxious, Depressed, Borderline, and Insomnia. Once you start using a large part of your brain, you can track which hormone deficiencies lead to which states and plan your activities accordingly."
    },
  ];
  

  if (!faqs || faqs.length === 0) faqs = defaultFaqs;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <Content>
        <TwoColumn>
          {/* <Column tw="hidden lg:block w-5/12 flex-shrink-0">
            <Image imageContain={imageContain} imageShadow={imageShadow} imageSrc={imageSrc} />
          </Column> */}
          <Column>
            <FAQContent>
              {subheading ? <Subheading>{subheading}</Subheading> : null}
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <FAQSContainer>
                {faqs.map((faq, index) => (
                  <FAQ
                    key={index}
                    onClick={() => {
                      toggleQuestion(index);
                    }}
                    className="group"
                  >
                    <Question>
                      <QuestionText>{faq.question}</QuestionText>
                      <QuestionToggleIcon>
                        {activeQuestionIndex === index ? <MinusIcon /> : <PlusIcon />}
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                        collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                      }}
                      initial="collapsed"
                      animate={activeQuestionIndex === index ? "open" : "collapsed"}
                      transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                      {faq.answer}
                    </Answer>
                  </FAQ>
                ))}
              </FAQSContainer>
            </FAQContent>
          </Column>
        </TwoColumn>
      </Content>
    </Container>
  );
};
