import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import { css } from "styled-components/macro"; 
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import { HelmetProvider } from 'react-helmet-async';


import CDWEn from "./pages/CDW-en/CrazyDemagogyWars";
import CDWLegalEn from './pages/CDW-en/Legal';

import PrimzurumEn from 'pages/PZ-en/Primzurum.js';
import PrimzurumLegalEn from 'pages/PZ-en/Legal';

import AnatolianRockEn from "pages/QC-en/AnatolianRock";
import AnatolianRockLegalEn from 'pages/QC-en/Legal';

import HomeEn from "./Home-en.js";
import PrivacyPolicyEN from 'components/en/PrivacyPolicy';
import TermsOfServiceEN from "components/en/TermsOfService";
import DisclaimerEN from 'components/en/Disclaimer';
import AboutEN from 'components/en/AboutUs';
import GamesEN from 'components/en/Games';

import CDWTr from "./pages/CDW/CrazyDemagogyWars";
import CDWLegalTr from './pages/CDW/Legal';

import PrimzurumTr from 'pages/PZ/Primzurum';
import PrimzurumLegalTr from 'pages/PZ/Legal';

import AnatolianRockTr from "pages/QC/AnatolianRock";
import AnatolianRockLegalTr from 'pages/QC/Legal';

import HomeTr from "Home-tr";
import PrivacyPolicyTR from 'components/tr/PrivacyPolicy';
import TermsOfServiceTR from "components/tr/TermsOfService";
import DisclaimerTR from 'components/tr/Disclaimer';
import AboutTR from 'components/tr/AboutUs';
import GamesTR from 'components/tr/Games';

export default function App() {

  return ( 
    <HelmetProvider>
    <AnimationRevealPage >
      <GlobalStyles />
      
      <Router>
        <Routes>
          <Route path="/" element={<Navigate replace to="/en" />} />
          
          {/* ENGLISH PAGES */}
          <Route path="/en" element={< HomeEn/>} />
          <Route path="/en/privacypolicy" element={< PrivacyPolicyEN/>} />
          <Route path="/en/termsofservice" element={< TermsOfServiceEN/>} />
          <Route path="/en/disclaimer" element={< DisclaimerEN/>} />
          <Route path="/en/about" element={< AboutEN/>} />
          <Route path="/en/games" element={< GamesEN/>} />

          <Route path="/en/primzurum" element={< PrimzurumEn/>} />
          <Route path="/en/primzurum/legal" element={< PrimzurumLegalEn/>} />

          <Route path="/en/quarantinechronicles" element={< AnatolianRockEn/>} />
          <Route path="/en/quarantinechronicles/legal" element={< AnatolianRockLegalEn/>} />

          <Route path="/en/crazydemagogywars" element={< CDWEn/>} />
          <Route path="/en/crazydemagogywars/legal" element={< CDWLegalEn/>} />
         
          
          {/* TURKISH PAGES */}
          <Route path="/tr" element={<HomeTr />} />
          <Route path="/tr/privacypolicy" element={< PrivacyPolicyTR/>} />
          <Route path="/tr/termsofservice" element={< TermsOfServiceTR/>} />
          <Route path="/tr/disclaimer" element={< DisclaimerTR/>} />
          <Route path="/tr/games" element={<GamesTR />}/>
          <Route path="/tr/about" element={<AboutTR />} />

          <Route path="/tr/primzurum" element={<PrimzurumTr />}/>
          <Route path="/tr/primzurum/legal" element={< PrimzurumLegalTr/>} />

          
          <Route path="/tr/crazydemagogywars" element={<CDWTr/>} />       
          <Route path="/tr/crazydemagogywars/legal" element={< CDWLegalTr/>} />

          <Route path="/tr/quarantinechronicles" element={<AnatolianRockTr />}/>
          <Route path="/tr/quarantinechronicles/legal" element={< AnatolianRockLegalTr/>} />
          

          {/* RECOVERY PAGES */}
          <Route path="/cilginalgisavaslari" element={<Navigate replace to="/tr/crazydemagogywars" />} />
          <Route path="/crazydemagogywars" element={<Navigate replace to="/tr/crazydemagogywars" />} />
          <Route path= "*" element ={<HomeEn />} />
        
        </Routes>
      </Router>
     
    </AnimationRevealPage>
    </HelmetProvider>
  );
}
