import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "./HeroQuarantine";
import InformativeFeature from "./InformativeFeaturesQC";
import MainFeature from "./MainFeature";
import GameGuide from "./GameGuideQC";
import FeatureWithSteps from "./EasyToUseQC";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "./Testimonials";
import Hints from "./Hints";
import Download from "./DownloadQC";
import heroScreenshotImageSrc from "images/happy-illustration.svg";
import macHeroScreenshotImageSrc from "images/signup-illustration.svg";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import Gameplay from "./GameplayQC"
import banner from 'images/rasitgr/quarantine-banner.png'
import cengiz from 'images/rasitgr/QC-InGame-SS.png'
import alp from 'images/rasitgr/pap-min.png';
import berkhan from 'images/rasitgr/lurker-min.png';
import onur from 'images/rasitgr/evandnoth-min.png';
import Footer from 'components/tr/Footer';
import Team from './Team';
import Team2 from "./Team-2";
import { Helmet } from "react-helmet-async";


export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-gray-300`;
  const HighlightedText = tw.span`text-red-700`;

  return (
    <>
      <Helmet>
        <title>Karantina Günlükleri: Anadolu Rock | Sanal Bebek Oyunu | RASITGR Games</title>
        <meta
          name="description"
          content="Karantina Günlükleri'nde Cengiz'e 45 günlük karantina süresinde hayatta kalmasında yardım edin. Bu Anadolu Rock temalı eşsiz sanal bebek deneyimini kaçırmayın."
        />
        <meta name="keywords" content="Karantina Günlükleri, Anadolu Rock, sanal bebek oyunu, RASITGR Games" />
        <meta property="og:title" content="Karantina Günlükleri: Anadolu Rock" />
        <meta property="og:description" content="Cengiz'in karantinada hayatta kalmasına yardım edin." />
      </Helmet>
      
       <Hero roundedHeaderButton={true} />
     
      <InformativeFeature
        subheading={<Subheading>NEDİR BU</Subheading>}
        heading={
          <>
            Karantina Günlükleri: <HighlightedText>Anadolu</HighlightedText> Rock?
          </>
        }
        description={"Karantinada 45 gün geçirecek olan Cengiz'in mental sınavına ortak olduğunuz bi sanal bebek oyunu. Bu süreçte birçok mücadele unsuru sınırlarınızı zorlayacak."}
      id='video'
      />
     
    
     
      <MainFeature
        subheading={<Subheading>NOSTALJİK Bİ OYUN</Subheading>}
        imageSrc={banner}
        imageBorder={true}
        imageDecoratorBlob={true}
        description={"Karantina Günlükleri: Anadolu Rock'ı kendimiz de oynamak ve her seferinde yeni bi mücadele, yeni bi hikaye deneyimlemek için geliştirdik. Bu yüzden her detayı akademik araştırmalarla ve gerçeğe en yakın sonuçları bulana kadar zenginleştirdik. Covid19 karantinasındaki her şeyi işledik."}
      /> 

      <Team />
     
     <Testimonial
        subheading={<Subheading>Deneyenler</Subheading>}
        heading={
          <>
            Karantina Günlüklerinde <HighlightedText>Zorlandı</HighlightedText>
          </>
        }
        description={"Karantina Günlüklerini bi denediler. Önce kafaları karıştı, sonra anladılar. Ondan sonra Cengiz'in anasını ağlattılar."}
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              berkhan,
            heading: "İyi olmuş",
            quote:
              "Oyun yapılırken bu kadar oynanabilir olmasını beklemiyordum. Cengiz'i en çok öldüren benmişim, tester'lar arasında.",
            customerName: "Berkhan 'The Lurker'",
            customerTitle: "Translator, QA"
          },
          {
            stars: 2,
            profileImageSrc:
              onur,
            heading: "Sigara bağımlılığım oyunda da peşimi bırakmadı",
            quote:
              "Oyunda sürekli sigara içiyorum, Pap'ın uyardığı kadar varmış hep sonum hastane...",
            customerName: "Onur 'EvandNoth'",
            customerTitle: "Software Developer"
          },
          {
            stars: 2,
            profileImageSrc:
              alp,
            heading: "Kötü UX, ama ilk oyunun günahı olmaz",
            quote:
              "Oyunda sürekli sigara içiyorum, Pap'ın uyardığı kadar varmış hep sonum hastane...",
            customerName: "Alp 'Pap'",
            customerTitle: "Game Director & Developer"
          }
        ]}
      />
     
      <FeatureWithSteps
        subheading={<Subheading>ADIM ADIM</Subheading>}
        heading={
          <>
            <HighlightedText>Kolaylaştırılmış </HighlightedText> Kullanım
          </>
        }
        textOnLeft={true}
        imageSrc={cengiz}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
   
      />
     
      <div id="rules" ></div>
     <Download/>

        <GameGuide  />

      <Gameplay />

      <Hints
        subheading={<Subheading>Kolay kolay oynayın dİye</Subheading>}
        heading={
          <>
            Sizin için hazırladığımız <HighlightedText>İpuçları</HighlightedText>
          </>
        }
        faqs={[
          {id: 0, title: "Uyku Barı", description: ["Uyku barı azami iki günlük uyku tutar. Yani yarısı dolduğunda uyumanın vakti gelmiştir."] },
          { id: 1, title: "Enerji Toplamak", description: ['Enerjin sıfıra düştüğünde karnını doyurmak ve uyumaya çalışmak en büyük yardımcın olacaktır.']},
            { id: 2, title: "Öz Farkındalığı Artırmak", description: ['Haberleri takip etmek veya kitap okumak bilincini artıracaktır.']},
            { id: 3, title: "Yemek Stoğu", description: ["Aynı anda azami 1.5 günlük pişmiş yemek saklayabilirsiniz."]},
            { id: 4, title: "Sağlıklılaşmak", description: ["Spor yapmak organlarının, dolayısıyla sağlığının iyileşmesine yardımcı olur."]},
            { id: 5, title: "Sağlıksızlaşmak", description: ["Tütün ürünleri kullanmak organlarının sağlığını olumsuz etkiler."]},
            { id: 6, title: "Tekrar Ediciliğin Sıkıcılığı",  description: ["Sürekli aynı aktiviteyi yapmak Cengiz'in canını sıkar ve psikolojisini olumsuz etkileyebilir. Sürekli Cengiz'in canını sıkan şeyler yapmak Cengiz'in bazı mutluluk hormonlarının düşmesine sebep olabilir."]},
            { id: 7, title: "Insomnia", description: ["Çok fazla uyumak veya düzensiz uyumak Cengiz'in uyku hastalıkları geçirmesine sebep olabilir."]},
            { id: 8, title: "Maske Kullanımı", description: ["Maskesiz dışarı çıkmak hastalık bulaşma riskini artırır ve masken sadece dışarıda kullandığında eksilecektir."]},
            { id: 9, title: "Virüsü Atlatmak", description: ["Sana hastalık bulaştığında ne kadar sağlıklıysan hastalığı o kadar kolay atlatırsınız."]},
            { id: 10, title: "Eylemler", description: ["Her bir eylem 20 dakika sürmektedir ve Yapılacaklar listesinin tamamı 12 saatte bitmektedir. Her aktivite, aktivite bittiğinde etki eder. Bir aktiviteyi bitmeden iptal etmek, o aktivitenin hiçbir etki etmemesine sebep olur. Yapılacaklar Listesi'ni boş bırakmak Cengiz'in vakit öldürmesine ve dolayısıyla canının sıkılmasına sebep olur."]},
            { id: 11, title: "Cengiz'in Sıkılması", description: ["Cengiz'in bir şeyden sıkıldığını anlamanın yolu o işi yaptıktan sonraki olumsuz bildirimlerini kontrol etmektir.'"]},
            { id: 12, title: "Gitar Çalmak", description: ["Cengiz'in odasındaki gitarı çalabilirsiniz."]},
            { id: 13, title: "Hormon Takibi", description: ["Her eylem Cengiz'in farklı hormonlarına iyi gelecektir, bunları takip edebilmek için Cengiz'in farkındalığını artırmalısınız."]},
            { id: 14, title: "Çok Spor Yapmak", description: ["Çok spor yapmak sağlığa iyi geldiği kadar Cengiz'i acıktıracaktır da."]},
            { id: 15, title: "Aktivite Teyiti", description: ["Aktivite simgelerini karıştırdığında onları yapılacaklar listesine ekleyip isimlerine bakabilirsiniz."]},
            { id: 16, title: "Bağımlılık", description: ["Cengiz'in şikayetlerini ve ifadelerini Bilgi Sayfası'ndan takip edebilirsin. Cengiz bazen bağımlı olduğu davranışları kendiliğinden Yapılacaklar Listesi'ne ekleyebilir."]},
            { id: 17, title: "Cengiz'i Anlamak", description: ["Cengiz'in şikayetlerini ve ifadelerini Bilgi Sayfası'ndan takip edebilirsin."]},
            { id: 18, title: "Vaktin Ağırlığı", description: ["45 günün ikinci yarısında Cengiz'e eğleneceği bir şey sunmakta zorlanabilirsin."]},
            { id: 19, title: "Paranın Bitmesi", description: ["Paran biterse, Bilgi Sayfası'ndan reklam izleyerek para kazanabilirsiniz."]},
            { id: 20, title: "Depresyon", description: ["Cengiz endişelendiği zaman ekstra bir bildirim çıkar, bu onun depresyon başlangıcının göstergesidir. Uzun süre depresyonda kalmak Cengiz'in kalp ve bağırsak sıkıntısı çekmesine sebep olabilir."]},
            { id: 21, title: "Tütün Bağımlılığı", description: ["Zor modda oynuyorsanız da Cengiz'i tütünden kurtarabilirsiniz. Bir süre tütün kullanmamak Cengiz'i bu bağımlılıktan kurtarır.'"]},
            { id: 22, title: "Su İçmek", description: ["Cengiz'e su içirmenize gerek yoktur, stoklarında su olduğu sürece suyunu kendisi içecektir."]},
            { id: 23, title: "Aç Kalmak", description: ["Uzun süre aç kalmak Cengiz'in karın ağrısı çekmesine ve midesinin zarar görmesine sebep olabilir."]},
        ]}
      />

    <Footer />
     
    </>
  );
}
