import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ContentWithPaddingXl, Container } from "components/misc/Layouts.js";
import { SectionHeading as Heading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";
import Alp from 'images/rasitgr/pap-min.png';
import Ergun from 'images/rasitgr/horsbtcher-min.jpg';
import Kerem from 'images/rasitgr/ponybutcher-min.jpg';
import Onur from 'images/rasitgr/evandnoth-min.png';
import Berkhan from 'images/rasitgr/lurker-min.png';
import Chili from 'images/rasitgr/chili-min.jpg';

const Subheading = tw(SubheadingBase)`text-center`;
const Testimonials = tw.div`flex flex-wrap items-center justify-center`;
const TestimonialContainer = tw.div`mt-16 w-full sm:w-1/2 lg:w-1/3 px-4`;
const Testimonial = tw.div`px-4 text-center max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img`w-20 h-20 rounded-full`;
const Quote = tw.blockquote`mt-5 text-gray-400 font-light text-sm leading-loose`;
const CustomerName = tw.p`mt-5 text-red-600 font-semibold uppercase text-sm tracking-wide`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;

export default ({
  subheading = "Yetenekli",
  heading = "Geliştirme Ekibimiz",
  personalLink ="",
  testimonials = [
    {
      imageSrc:
        Onur,
      quote:
        "Onur, Karantina Günlükleri'nin kalitesini sağlamada kritik bir rol oynar. Titiz test ve kalite güvencesi sayesinde olası sorunları tespit ederek oyuncular için sorunsuz ve keyifli bir oyun deneyimi sunulmasını sağlar.",
      customerName: "Onur Doğan",
      personalLink:"https://www.linkedin.com/in/erg%C3%BCnalp-kurt-9a04a01b0/"
    },
    {
      imageSrc:
        Berkhan,
      quote:
        "Berkhan, kalite güvencesi ve oyun testlerinin yanı sıra, Karantina Günlükleri'nin küresel bir kitleye hitap etmesini sağlarken, oyunun benzersiz kültürel unsurlarını koruyarak yabancılaştırma sürecine odaklanır.",
      customerName: "Berkhan Nalcı",
      personalLink:"https://www.linkedin.com/in/erg%C3%BCnalp-kurt-9a04a01b0/"
    },
    {
      imageSrc:
        Chili,
      quote:
        "Göktuğ, Karantina Günlükleri'nin dünyasında ana karakter olan Cengiz'i tasarlamaktan sorumludur. 3D karakter tasarımı ve oyun tasarımı konusundaki uzmanlığı sayesinde Cengiz, derinlik ve kişilik kazanarak oyunun genel deneyimini zenginleştirir.",
      customerName: "Göktuğ Tepecik",
      personalLink:"https://www.linkedin.com/in/erg%C3%BCnalp-kurt-9a04a01b0/"
    },
    {
      imageSrc:
        Ergun,
      quote:
        "Ergünalp, oyun sistemlerinin ve oynanış mekaniklerinin geliştirilmesini yöneterek oyunun sorunsuz çalışmasını ve ilgi çekici deneyimler sunmasını sağlar. Ayrıca, Karantina Günlükleri'nin genel yapısını şekillendirerek oyun tasarımına katkıda bulunur.",
      customerName: "Ergünalp Kurt",
      personalLink:"https://www.linkedin.com/in/erg%C3%BCnalp-kurt-9a04a01b0/"
    },
    {
      imageSrc:
       Kerem,
      quote:
        "Kerem’in 3D animasyon ve nesne tasarımı konusundaki ustalığı, Karantina Günlükleri için etkileyici bir dünya yaratır. Nesneler, ortamlar ve animasyonlar üzerinde yaptığı çalışmalar, oyunun atmosferini zenginleştirir ve oyuncuları büyüleyen dinamik bir görsel katman sunar.",
      customerName: "Kerem Kurt",
      personalLink:"https://www.linkedin.com/in/kerem-kurt-3bb5891b1/"
    },
    {
      imageSrc:
        Alp,
      quote:
        "Alp, Karantina Günlükleri'nin genel vizyonunu yönetir, oyun sistemleri programlamasından 2D sanat eserlerine ve kullanıcı arayüzü tasarımına kadar her şeyi denetler. Ayrıca, oyunun yayınlanma sürecini yöneterek, oyunun oyunculara ulaşmasını ve yaratıcı vaatlerini yerine getirmesini sağlar.",
      customerName: "Alp Kurt",
      personalLink:"https://www.linkedin.com/in/alp-kurt-161285134/"
    }
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        <Testimonials>
          {testimonials.map((testimonial, index) => (
            <TestimonialContainer key={index}>
              <Testimonial>
                <a target="_blank" href = {testimonial.personalLink}><Image src={testimonial.imageSrc} /></a>
                <a target="_blank" href = {testimonial.personalLink}><CustomerName>- {testimonial.customerName}</CustomerName></a>
                <Quote>{testimonial.quote}</Quote>
              </Testimonial>
            </TestimonialContainer>
          ))}
        </Testimonials>
      </ContentWithPaddingXl>

      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
