import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings";
import Hero from 'components/en/Hero2024';
import Footer from 'components/en/Footer';
import { Helmet } from "react-helmet-async";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-400 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-300`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Terms And Conditions" }) => {
  return (
    <div>
      <Helmet>
  <title>Terms and Conditions | RASITGR Games</title>
  <meta
    name="description"
    content="Terms and conditions for using RASITGR Games' products and services. By using our services, you agree to these terms."
  />
  <meta name="keywords" content="terms and conditions, legal, RASITGR Games" />
  <meta property="og:title" content="Terms and Conditions | RASITGR Games" />
  <meta property="og:description" content="Learn about the terms and conditions for using RASITGR Games' products and services." />
</Helmet>

      <Hero/>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>

            <p>Last updated: Sep 14, 2024</p>

            <p>Please read these terms and conditions carefully before using Our Service.</p>

            <h1>Interpretation and Definitions</h1>
            <h2>Interpretation</h2>
            <p>
            The words with capitalized initial letters have meanings defined under the following conditions. 
            </p>
            <p>
            The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
            </p>

            <h2>Definitions</h2>
            <p>For the purposes of these Terms and Conditions:</p>
            <ul>
              <li>
                <strong>Affiliate</strong> means an entity that controls, is controlled by, or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for election of directors or other managing authority.
              </li>
              <li>
                <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to RASITGR Games, owned by Alp Kurt.
              </li>
              <li>
                <strong>Country</strong> refers to Turkey (or Germany, based on user’s current location).
              </li>
              <li>
                <strong>Service</strong> refers to the Website.
              </li>
              <li>
                <strong>Terms and Conditions</strong> (also referred to as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.
              </li>
              <li>
                <strong>Third-party Social Media Service</strong> means any services or content (including data,
                information, products or services) provided by a third-party that may be displayed, included or made
                available by the Service.
              </li>
              <li>
                <strong>Website</strong> refers to RASITGR Games, accessible from https://rasitgr.com.
              </li>
              <li>
                <strong>You</strong> means the individual accessing or using the Service.
              </li>
              <li>
  <strong>Usage Data</strong> means data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
</li>

            </ul>

            <h1>Acknowledgement</h1>
            <p>
            These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms set out the rights and obligations of all users regarding the use of the Service.
            </p>
            <p>
            Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service.
            </p>
            <p>
            By accessing or using the Service, You agree to be bound by these Terms. If You disagree with any part of the Terms, You may not access the Service.
            </p>
            <p>
            You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.
            </p>
            <h1>Data Collection and Usage</h1>
<p>
  Our Service uses Google Analytics to collect anonymous usage data. This includes information such as your IP address, browser type, the pages you visit, and the time spent on those pages. The purpose of collecting this data is to monitor website traffic and user behavior to improve our services.
</p>
<p>
  You may opt-out of Google Analytics tracking by installing the <a href="https://tools.google.com/dlpage/gaoptout">Google Analytics opt-out browser add-on</a>.
</p>


            <h1>Links to Other Websites</h1>
            <p>
            Our Service may contain links to third-party websites or services that are not owned or controlled by the Company.
            </p>
            <p>
            The Company assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that the Company is not liable for any damage or loss caused or alleged to be caused by the use of any such content, goods, or services available on third-party websites.
            </p>
            <p>
            We strongly advise You to read the terms and conditions and privacy policies of any third-party websites or services You visit.
            </p>

            <h1>Termination</h1>
            <p>
            We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including if You breach these Terms.
            </p>
            <p>Upon termination, Your right to use the Service will cease immediately.</p>

            <h1>Limitation of Liability</h1>
            <p>
            To the maximum extent permitted by applicable law, in no event shall the Company be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, loss of profits, loss of data, or business interruption) arising from the use of the Service.
            </p>
            <p>
              Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or
              consequential damages, which means that some of the above limitations may not apply. In these states, each
              party's liability will be limited to the greatest extent permitted by law.
            </p>
            <p>
  The Company is not liable for any damages resulting from third-party services such as Google Analytics, including but not limited to loss of data, breach of privacy, or inaccuracies in analytics data.
</p>

<h1>Cookies and Tracking</h1>
<p>
  While we do not use cookies for personalized tracking, Google Analytics may use cookies to collect usage data. You can manage your cookie settings in your browser to control or block tracking.
</p>


            <h1>"AS IS" and "AS AVAILABLE" Disclaimer</h1>
            <p>
            The Service is provided to You "AS IS" and "AS AVAILABLE" without warranties of any kind, either express or implied. The Company makes no warranty that the Service will meet Your requirements, be uninterrupted or error-free, or that any errors can or will be corrected.
            </p>

            <h1>Governing Law</h1>
            <p>
            The laws of Turkey (or Germany based on user location) will govern these Terms and Your use of the Service.
            </p>

            <h1>Disputes Resolution</h1>
            <p>
            If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting Us at info@rasitgr.com.
            </p>

            <h1>Severability and Waiver</h1>
            <h2>Severability</h2>
            <p>
              If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and
              interpreted to accomplish the objectives of such provision to the greatest extent possible under
              applicable law and the remaining provisions will continue in full force and effect.
            </p>

            <h1>Translation Interpretation</h1>
            <p>
              These Terms and Conditions may have been translated if We have made them available to You on our Service.
            </p>
            <p>You agree that the original English text shall prevail in the case of a dispute.</p>

            <h1>Changes to These Terms and Conditions</h1>
            <p>
            We reserve the right to modify these Terms at Our discretion. If a revision is material, We will provide at least 30 days' notice prior to the new terms taking effect.
            </p>
            <p>
            By continuing to access or use Our Service after revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, please stop using the Service.
            </p>

            <h1>Contact Us</h1>
            <p>If you have any questions about these Terms and Conditions, You can contact us by email at:</p>

            <ul>
              <li>info@rasitgr.com</li>
            
            </ul>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </div>
  );
};
