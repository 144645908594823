import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../../components/misc/Headings.js";
import Visual1 from 'images/rasitgr/cdw-cards-1.png';
import Visual2 from 'images/rasitgr/cdw-cards-2.png';
import Visual3 from 'images/rasitgr/cdw-cards-3.png';

const Container = tw.div`relative -mx-8 px-8 bg-gray-900`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-300 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}"); `,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-red-600`;
const Title = tw.h4`text-3xl font-bold text-gray-300`;
const Description = tw.p`mt-2 text-lg leading-loose text-gray-400`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc: Visual1,
      imageAlt: "test",
      subtitle: "First",
      title: "Execute Your Policies",
      description:
        "Initially, focusing on enriching your propaganda stockpile can be a safe start. The twist of fate aligns with the timing of political cards. The best player is the one who plays the political card that dilates the pupils of their opponents.",
      url: "https://timerse.com"
    },
  
    {
      imageSrc: Visual3,
      imageAlt: "",
      subtitle: "Next",
      title: "Fire Off a Few Rallies",
      description:
        "In the middle of the game, when community cards start to increase, the inevitable is about to happen. Your rally cards may cause routes to be recalculated or force all plans to be reconsidered.",
      url: "https://timerse.com"
    },
  
    {
      imageSrc: Visual2,
      imageAlt: "",
      subtitle: "Finally",
      title: "Complete Your Sets",
      description:
        "In the later stages of the game, every move can be decisive, while some moves might aim to block the final play. In such an environment, quickly completing your sets and finishing the game can be a sign of true cleverness.",
      url: "https://timerse.com"
    }
  ];
  

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
        <HeadingTitle>Quick Game Guide</HeadingTitle>
<HeadingDescription>
  We've designed a unique and simple game with 100 different cards and a few standards. While each card offers a different experience, you'll quickly get accustomed to the standards of Crazy Demagogy Wars.
</HeadingDescription>

        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                {/* <Link href={card.url}>See Event Details</Link> */}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
