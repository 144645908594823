import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings";
import Hero from 'components/en/Hero2024';
import Footer from 'components/en/Footer';
import { Helmet } from "react-helmet-async";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-400 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-300`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Çılgın Algı Savaşları için Yasal Bilgilendirme" }) => {
  return (
    <div>
      <Helmet>
  <title>Çılgın Algı Savaşları | Yasal Bilgiler | RASITGR Games</title>
  <meta
    name="description"
    content="Çılgın Algı Savaşları ile ilgili yasal bilgiler, gizlilik politikası, kullanım şartları ve sorumluluk reddi."
  />
  <meta name="keywords" content="Çılgın Algı Savaşları, yasal bilgiler, gizlilik politikası, kullanım şartları, RASITGR Games" />
  <meta property="og:title" content="Çılgın Algı Savaşları | Yasal Bilgiler" />
  <meta property="og:description" content="Çılgın Algı Savaşları için gizlilik politikası, kullanım şartları ve yasal sorumluluk reddi." />
</Helmet>

      <Hero/>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>


          <p>Son güncelleme: 14 Eylül 2024</p>

<p>RASITGR Games'e hoş geldiniz. Aşağıda, kart oyunumuz Çılgın Algı Savaşları (Crazy Demagogy Wars) kullanımına ilişkin yasal şartlar ve koşullar yer almaktadır. Oyunumuzu oynayarak bu şartları kabul etmiş olursunuz.</p>
<br></br>
<hr></hr>
<h1>Gizlilik Politikası</h1>
<p>RASITGR Games, gizliliğinize saygı duyar ve bizimle paylaşılan kişisel verileri korumaya kararlıdır. Ancak, müşterilerimizden herhangi bir kişisel veri toplamıyor veya saklamıyoruz.</p>
<h2>Kişisel Veri Toplama</h2>
<p>Satış süreci, Shopier ve Trendyol.com gibi üçüncü taraf platformlar üzerinden yönetildiği için herhangi bir kişisel bilgi toplamaz, saklamaz veya işlemez. Verdiğiniz kişisel bilgiler bu platformlar tarafından kendi gizlilik politikalarına uygun şekilde yönetilmektedir.</p>
<h2>Harici Bağlantılar</h2>
<p>Web sitemiz, Shopier.com ve Trendyol.com gibi harici web sitelerine bağlantılar içerebilir. Bu harici web sitelerinin içeriklerinden veya gizlilik uygulamalarından sorumlu değiliz. Daha fazla bilgi için ilgili platformların gizlilik politikalarına bakmanızı öneririz.</p>
<p>Bu Gizlilik Politikası ile ilgili herhangi bir sorunuz varsa, bizimle şu adresten iletişime geçebilirsiniz: info@rasitgr.com veya info@lupittus.com</p>
<br></br>
<hr></hr>

<h1>Şartlar ve Koşullar</h1>
<p>Çılgın Algı Savaşları'nı satın alarak, LUPI tarafından geliştirilen ve RASITGR Games adı altında satılan bu şartlar ve koşulları kabul etmiş olursunuz:</p>
<ul>
  <li>Satıcı: Oyun, Alp Kurt'a ait olan LUPI tarafından geliştirilmiş ve RASITGR Games adı altında satılmaktadır.</li>
  <li>Satış Platformları: Ürünlerimiz, Shopier ve Trendyol.com gibi üçüncü taraf platformlar üzerinden satılmaktadır. Ödeme süreci doğrudan tarafımızca yönetilmemektedir.</li>
</ul>
<h2>Fikri Mülkiyet</h2>
<p>Çılgın Algı Savaşları oyunu Türkiye'de tescilli bir markadır. Oyunla ilgili tüm varlıklar, logo ve tasarım da dahil olmak üzere RASITGR Games ve LUPI'nin fikri mülkiyetidir.</p>
<h2>Gönderim ve Teslimat</h2>
<ul>
  <li>Gönderim Bölgeleri: Sadece Türkiye sınırları içinde gönderim yapılmaktadır.</li>
  <li>Teslimat Süreleri: Teslimat süreleri bir haftadan kısa olarak tahmin edilmekte, ancak teslimat ortağına bağlı olarak değişiklik gösterebilmektedir. Tahmini teslimat süreleri, ilgili alışveriş sayfalarında belirtilmiştir.</li>
  <li>Teslimat Ortağı: Teslimat ortaklarımız değişiklik gösterebilir ve müşterilere satın alma sırasında gönderim yöntemi bildirilecektir.</li>
</ul>
<h2>İade ve Geri Ödeme</h2>
<p>İade ve geri ödeme politikamız Türk yasalarına tabidir. Müşteriler, ürünün kusurlu olması durumunda geri ödeme alma hakkına sahiptir. İade kargo maliyetleri Türk yasalarına göre belirlenir. Ürünü satın aldığınız platformda belirtilen iade süreçlerine başvurmanızı öneririz.</p>
<h2>Sorumluluğun Sınırlandırılması</h2>
<p>Türk yasalarının gerektirdiği durumlar dışında herhangi bir sorumluluk sınırlaması bulunmamaktadır.</p>
<p>Bu Şartlar ve Koşullar ile ilgili herhangi bir sorunuz varsa, bizimle şu adresten iletişime geçebilirsiniz: info@rasitgr.com veya info@lupittus.com</p>
<br></br>
<hr></hr>

<h1>İade ve Geri Ödeme Politikası</h1>
<p>İade ve geri ödeme politikamız, Türk yasalarına uygun olup müşteri memnuniyetini sağlamayı hedeflemektedir.</p>
<h2>İade İçin Uygunluk</h2>
<ul>
  <li>Müşteriler, ürünün kusurlu olması durumunda geri ödeme talep edebilirler.</li>
  <li>İade ve geri ödeme işlemleri, ürünü satın aldığınız platformun (Shopier veya Trendyol.com) belirttiği süreçlere uygun olarak gerçekleştirilmelidir.</li>
</ul>
<h2>İade Kargo Ücreti</h2>
<ul>
  <li>İade kargo maliyetinden sorumlu taraf, Türk yasalarına göre belirlenir.</li>
  <li>Eğer ürünün kusurlu olduğunu düşünüyor ve iade etmek istiyorsanız, Shopier veya Trendyol.com platformunda belirtilen iade sürecine başvurmalısınız.</li>
</ul>
<p>Satın alma işleminizle ilgili herhangi bir sorunuz veya sorununuz varsa, bizimle şu adresten iletişime geçebilirsiniz: info@rasitgr.com veya info@lupittus.com</p>
<br></br>
<hr></hr>

<h1>Gönderim Politikası</h1>
<p>Tüm müşterilerimize Türkiye içinde hızlı ve etkili gönderim hizmeti sunmayı amaçlıyoruz.</p>
<h2>Gönderim Bölgeleri</h2>
<p>Şu anda yalnızca Türkiye içindeki adreslere gönderim yapıyoruz.</p>
<h2>Teslimat Süreleri</h2>
<ul>
  <li>Tahmini teslimat süreleri bir haftadan kısa olup, teslimat ortağına bağlı olarak değişiklik gösterebilir.</li>
  <li>Oyun satın alındığında, tahmini teslimat süresi platformda (Shopier veya Trendyol.com) belirtilen ürün sayfasında yer alacaktır.</li>
</ul>
<h2>Teslimat Ortakları</h2>
<ul>
  <li>Teslimat ortaklarımız, bölgeye ve kullanılabilirliğe bağlı olarak değişiklik gösterebilir.</li>
  <li>Müşterilere, satın alma sırasında teslimat yöntemi hakkında bilgi verilecektir.</li>
</ul>
<p>Gönderimle ilgili herhangi bir sorunuz varsa, bizimle şu adresten iletişime geçebilirsiniz: info@rasitgr.com veya info@lupittus.com</p>

          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </div>
  );
};
