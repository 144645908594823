import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/en/Hero2024";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "./MainFeatuer";
import MainFeature2 from "pages/CDW/CDWCard.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import DownloadApp from "components/cta/DownloadApp.js";
import Footer from "components/en/Footer";

import chefIconImageSrc from "images/chef-icon.svg";
import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";

import QuarantineImage from 'images/rasitgr/quarantine-logo.png'
import PrimzurumImage from 'images/rasitgr/primzurum-image.png'
import CDWImage from 'images/rasitgr/cdw-image.jpg';
import TOAImage from 'images/rasitgr/talesofamasia-image.png';

import { Helmet } from "react-helmet-async";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-green-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-green-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <div>
      <Helmet>
  <title>Our Games | RASITGR Games</title>
  <meta
    name="description"
    content="Discover the unique games developed and published by RASITGR Games, including mobile, desktop, and card games."
  />
  <meta name="keywords" content="games, indie games, mobile games, desktop games, card games, RASITGR Games" />
  <meta property="og:title" content="Our Games | RASITGR Games" />
  <meta property="og:description" content="Explore the range of indie games developed and published by RASITGR Games." />
</Helmet>

      <Hero />

      <MainFeature
        subheading={<Subheading>Tabletop Role Playing Game (2019)</Subheading>}
        heading={
          <>
            Tales Of Amasia
            <wbr /> <HighlightedText>Very Basic Edition For Dummies.</HighlightedText>
          </>
        }
        description={
          <Description>
            Tales Of Amasia is a tabletop role-playing game targeting various levels of experienced players. It offers a hand crafted vast universe enriched with the unexplored Turkish myths.
            <br />
            <br />
           The Very Basic Edition For Dummies offers an entry level complexity for those who seek a way to start exploring fantasy role playing without need of a prior experience or knowledge.
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="In Rework"
        imageSrc={
         TOAImage
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
        imageAlt="Tales of Amasia: For Dummies logo"
        primaryButtonUrl="/en/talesofamasia"
        innerLink="/en/talesofamasia"
      />

      <MainFeature  
        subheading={<Subheading>Mobile Casual (2020)</Subheading>}
        heading={
          <>
            Quarantine Chronicles
            <wbr /> <HighlightedText>Anatolian Rock</HighlightedText>
          </>
        }
        description={
          <Description>
            "The Quarantine Chronicles" follows the life of Cengiz, a young Anatolian Rock fan. The main goal is to help Cengiz successfully get through a 45-day quarantine period. 
            <br />
            <br />
            Along the way, Cengiz will face an increasingly difficult mental struggle. In "The Quarantine Chronicles", where every habit acquired manifests itself before it's too late, players are offered an extraordinary virtual pet experience.
          </Description>

          
        }
        buttonRounded={false}
        textOnLeft={true}
        primaryButtonText="Play On Play Store"
        imageSrc={
          QuarantineImage
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
        imageAlt="Quarantine Chronicles: Anatolian Rock logo"
        primaryButtonUrl="https://play.google.com/store/apps/details?id=com.DefaultCompany.EvdeKal"
        innerLink="/en/quarantinechronicles"
      />
      
      <MainFeature 
        subheading={<Subheading>Mobile Casual (2021)</Subheading>}
        heading={
          <>
            Primzurum
            <wbr /> <HighlightedText>A game to state our vision</HighlightedText>
          </>
        }
        description={
          <Description>
            Primzurum, is a game with questions and answers in a meme format. We have have made it as a reaction!
            <br />
            <br />
            Primzurum takes a twist on a familiar concept, turning the famous corporate meme into an interactive mobile experience. In a world where the right answers are often obvious, Primzurum challenges players to think differently by asking them to pick the one wrong answer among a sea of right ones.
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Download from Itch"
        imageSrc={
          PrimzurumImage
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
        imageAlt="Primzurum gameplay screenshot"
        primaryButtonUrl="https://rasitgr-games.itch.io/primzurum"
        innerLink="/en/primzurum"
      />
      
      <MainFeature 
        subheading={<Subheading>Printed Card Game (2022)</Subheading>}
        heading={
          <>
            Crazy Demagogy Wars
            <wbr /> <HighlightedText>Classic</HighlightedText>
          </>
        }
        description={
          <Description>
            Çılgın Algı Savaşları is a fast-paced, politically-themed set collection card game for 3 to 6 players. Each round lasts between 10 to 15 minutes, offering a colorful and dynamic experience for all.
            <br />
            <br />
            
          </Description>
        }
        buttonRounded={false}
        textOnLeft={true}
        primaryButtonText="Get It On Shopier"
        imageSrc={
         CDWImage
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
        imageAlt="Crazy Demagogy Wars card game box mockup"
        innerLink="/en/crazydemagogywars"
        primaryButtonUrl="https://www.shopier.com/ShowProductNew/products.php?id=12032393"
      />

      {/* <Testimonial
        subheading=""
        heading={<>Customers <HighlightedText>Love Us.</HighlightedText></>}
      /> */}

      <DownloadApp
        text={<>Players all over the world enjoy playing our mobile games.</>}
      />

      <Footer />
    </div>
  );
}
