import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import cdwImage from 'images/rasitgr/cengiz2-min.png';

const Container = tw.div`relative bg-gray-900 text-gray-100 -mx-8 px-8 `;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft ? tw`md:mr-8 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8`;

const Heading = tw(SectionHeading)`text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-400 mt-4`

const Statistics = tw.div`mt-6 lg:mt-8 xl:mt-16 flex flex-wrap`
const Statistic = tw.div`text-lg sm:text-2xl lg:text-3xl w-1/2 mt-4 lg:mt-10 text-center md:text-left`
const Value = tw.div`font-bold text-green-500`
const Key = tw.div`font-medium text-green-700`

export default ({textOnLeft = false}) => {
  const statistics = [
    {
      key: "Günler",
      value: "45",
    },
    {
      key: "Cengiz",
      value: "1",
    },
    {
      key: "Farklı Son",
      value: "4",
    },
    {
      key: "Eşsiz Oynanış",
      value: "1",
    }
  ]

  return (
    <Container>
      <TwoColumn>
        <ImageColumn >
          <a href="/tr/karantinagunlukleri"><Image imageSrc={cdwImage} /></a>
        </ImageColumn>
        <TextColumn textOnLeft={true}>
          <TextContent>
            <a href="/tr/karantinagunlukleri"><Heading>Karantinada, Sanal Bir Bebek</Heading></a>
            <Description>Karantina Günlükleri, Anadolu Rock hayranı bir genç olan Cengiz'in hayatını takip eder. Ana görev, Cengiz'in 45 günlük karantina sürecini başarıyla atlatmasını sağlamaktır. Bu süreçte, Cengiz giderek artan bir zihinsel mücadeleyle karşı karşıya kalacaktır. Her alışkanlığın çok geç olmadan kendini gösterdiği Karantina Günlükleri'nde oyunculara benzersiz bir sanal bebek deneyimi sunulmaktadır.</Description>
            <Statistics>
              {statistics.map((statistic, index) => (
              <Statistic key={index}>
                <Value>{statistic.value}</Value>
                <Key>{statistic.key}</Key>
              </Statistic>
              ))}
            </Statistics>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};

