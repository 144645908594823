import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ContentWithPaddingXl, Container } from "components/misc/Layouts.js";
import { SectionHeading as Heading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";
import Alp from 'images/rasitgr/pap-min.png';
import Ergun from 'images/rasitgr/horsbtcher-min.jpg';
import Kerem from 'images/rasitgr/ponybutcher-min.jpg';
import Onur from 'images/rasitgr/evandnoth-min.png';
import Berkhan from 'images/rasitgr/lurker-min.png';
import Chili from 'images/rasitgr/chili-min.jpg';

const Subheading = tw(SubheadingBase)`text-center`;
const Testimonials = tw.div`flex flex-wrap items-center justify-center`;
const TestimonialContainer = tw.div`mt-16 w-full sm:w-1/2 lg:w-1/3 px-4`;
const Testimonial = tw.div`px-4 text-center max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img`w-20 h-20 rounded-full`;
const Quote = tw.blockquote`mt-5 text-gray-400 font-light text-sm leading-loose`;
const CustomerName = tw.p`mt-5 text-red-600 font-semibold uppercase text-sm tracking-wide`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;

export default ({
  subheading = "Our Talented",
  heading = "Development Team",
  personalLink ="",
  testimonials = [
    {
      imageSrc:
        Onur,
      quote:
        "Onur plays a critical role in ensuring the quality of Quarantine Chronicles through rigorous testing and quality assurance. His keen attention to detail helps identify potential issues, ensuring a smooth and enjoyable gameplay experience for players.",
      customerName: "Onur Doğan",
      personalLink:"https://www.linkedin.com/in/erg%C3%BCnalp-kurt-9a04a01b0/"
    },
    {
      imageSrc:
        Berkhan,
      quote:
        "In addition to handling quality assurance and game testing, Berkhan focuses on the process of foreignization, making sure that Quarantine Chronicles appeals to a global audience while maintaining its unique cultural elements.",
      customerName: "Berkhan Nalcı",
      personalLink:"https://www.linkedin.com/in/erg%C3%BCnalp-kurt-9a04a01b0/"
    },
    {
      imageSrc:
        Chili,
      quote:
        "Göktuğ is responsible for designing the character Cengiz who is the protagonist of the world of Quarantine Chronicles. His expertise in 3D character design and game design ensures that Cengiz is brought to life with depth and personality, enhancing the overall game experience.",
      customerName: "Göktuğ Tepecik",
      personalLink:"https://www.linkedin.com/in/erg%C3%BCnalp-kurt-9a04a01b0/"
    },
    {
      imageSrc:
        Ergun,
      quote:
        "Ergünalp leads the development of game systems and gameplay mechanics, ensuring that the game functions smoothly and provides engaging experiences. He also contributes to game design, shaping the overall structure of Quarantine Chronicles.",
      customerName: "Ergünalp Kurt",
      personalLink:"https://www.linkedin.com/in/erg%C3%BCnalp-kurt-9a04a01b0/"
    },
    {
      imageSrc:
       Kerem,
      quote:
        "Kerem’s mastery of 3D animation and props design creates immersive world for Quarantine Chronicles. His work on objects, environments, and animations adds a dynamic visual layer that captivates players and enriches the game’s atmosphere.",
      customerName: "Kerem Kurt",
      personalLink:"https://www.linkedin.com/in/kerem-kurt-3bb5891b1/"
    },
    {
      imageSrc:
        Alp,
      quote:
        "Alp directs the overall vision of Quarantine Chronicles, managing everything from game systems programming to 2D artwork and UI design. He also oversees the publishing process, ensuring that the game reaches players and delivers on its creative promise.",
      customerName: "Alp Kurt",
      personalLink:"https://www.linkedin.com/in/alp-kurt-161285134/"
    }
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        <Testimonials>
          {testimonials.map((testimonial, index) => (
            <TestimonialContainer key={index}>
              <Testimonial>
                <a target="_blank" href = {testimonial.personalLink}><Image src={testimonial.imageSrc} /></a>
                <a target="_blank" href = {testimonial.personalLink}><CustomerName>- {testimonial.customerName}</CustomerName></a>
                <Quote>{testimonial.quote}</Quote>
              </Testimonial>
            </TestimonialContainer>
          ))}
        </Testimonials>
      </ContentWithPaddingXl>

      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
