import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings";
import Hero from 'components/en/Hero2024';
import Footer from 'components/en/Footer';
import { Helmet } from "react-helmet-async";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-400 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-300`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Disclaimer" }) => {
  return (
    <div>
      <Helmet>
  <title>Disclaimer | RASITGR Games</title>
  <meta
    name="description"
    content="RASITGR Games' disclaimer, outlining our liability and limitations in relation to the use of our products and website."
  />
  <meta name="keywords" content="disclaimer, legal, RASITGR Games, liability" />
  <meta property="og:title" content="Disclaimer | RASITGR Games" />
  <meta property="og:description" content="Legal disclaimer for RASITGR Games, outlining limitations of liability and other legal terms." />
</Helmet>

      <Hero/>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>

            <p>Last updated: Sep 14, 2024</p>

            <p>The information provided by RASITGR Games ("we," "us," or "our") on https://rasitgr.com (the "Site") is for general informational purposes only. All information on the Site is provided in good faith; however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site.</p>

            <h1>External Links Disclaimer</h1>
            <p>
            The Site may contain links to other websites or content belonging to or originating from third parties, or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us.
            </p>
            <p>
            We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the Site or any website or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.
            </p>

            <h1>Limitations of Liability</h1>
<p>
In no event shall RASITGR Games or its team be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Site, including but not limited to any errors or omissions in the content, loss or corruption of data, or any other loss or damage arising from your use of the Site or from third-party tools such as Google Analytics.
</p>
<p>
We are not responsible for any data tracking or analytics conducted by third-party tools like Google Analytics, and you are responsible for understanding and managing your privacy settings accordingly.
</p>

            <h1>No Responsibility for Errors or Omissions</h1>
            <p>
            While we strive to provide up-to-date and accurate information, RASITGR Games makes no warranties or guarantees regarding the accuracy, completeness, or reliability of any content on this website. The content provided on this website may not always be up to date or reflect current industry developments.
            </p>

            <h1>"As-Is" and "As Available" Basis</h1>
            <p>
            The content on this Site is provided on an "as-is" and "as available" basis. We do not make any warranties or representations of any kind, either express or implied, about the operation of the Site, the accuracy of the information, or any content or materials included on the Site.
            </p>

            <h1>Professional Disclaimer</h1>
            <p>
            This Site cannot and does not contain legal or professional advice. Any legal or professional information is provided for general informational and educational purposes only and is not a substitute for professional advice. Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals.
            </p>

            <h1>Google Analytics Disclaimer</h1>
<p>
We use Google Analytics to track and analyze website traffic and user behavior. Google Analytics collects information such as your IP address, browser type, operating system, referring URLs, and time spent on pages. This information is collected for statistical purposes and to improve our website.
</p>
<p>
The data collected by Google Analytics is anonymous and does not identify individual visitors. For more information about how Google uses this data, please refer to the <a href="https://policies.google.com/privacy">Google Privacy Policy</a>.
</p>
<p>
You can opt out of Google Analytics tracking by installing the Google Analytics Opt-out Browser Add-on, available <a href="https://tools.google.com/dlpage/gaoptout">here</a>.
</p>

<h1>Cookies and Tracking Disclaimer</h1>
<p>
While we do not use cookies for personalized tracking, Google Analytics may place cookies on your device to collect anonymous usage data. You can manage or block cookies in your browser settings, but this may impact your experience with the Site.
</p>
<p>
For more information on how Google Analytics uses cookies, please refer to the <a href="https://policies.google.com/privacy">Google Privacy Policy</a>.
</p>


         

            <h1>Changes To This Disclaimer</h1>
            <p>
            We reserve the right to modify this Disclaimer at any time. Any changes will be updated on this page with the "Last updated" date. Your continued use of the Site following any changes to this Disclaimer constitutes your acceptance of the modified terms.
            </p>
            <p>
If we introduce new analytics tools or other third-party services in the future, we will update this Disclaimer and notify users. We recommend reviewing this Disclaimer periodically for any updates.
</p>


            <h1>Contact Us</h1>
            <p>If you have any questions about this Disclaimer, you can contact us at:</p>

            <ul>
              <li>info@rasitgr.com</li>
            
            </ul>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </div>
  );
};
