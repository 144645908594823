import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Slogan from 'components/tr/SloganFeature';
import FeaturedGame from 'components/tr/Featured-Games';
import FeaturedGame2 from 'components/tr/Featured-Games-2';
import ExploreGames from 'components/tr/ExploreGames';
import VisionStatement from "components/tr/VisionStatement";
import Hero from 'components/tr/Hero2024';
import Footer from "components/tr/Footer";


export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-green-500`;
  const HighlightedText = tw.span`text-green-500`;

  return (
    <div >
      <Hero />
      <Slogan />
      <FeaturedGame />
      <FeaturedGame2 />
      <ExploreGames />
      <VisionStatement />
      <Footer />

    </ div>
  );
};
