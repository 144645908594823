import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import HeroSecondary from "./HeroSecondary.js";
import Hero from './Hero';
import InformativeFeature from "pages/InformativeFeatureCDW";
import MainFeature from "./Feature.js";
import MainFeature2 from "./Features-2";
import FeatureWithSteps from "./FeatureWithSteps";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "./Testimonial.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/tr/Footer.js";
import heroScreenshotImageSrc from "images/happy-illustration.svg";
import macHeroScreenshotImageSrc from "images/signup-illustration.svg";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import Rules from "./Rules"
import Ergun from 'images/rasitgr/horsbtcher-min.jpg';
import Memet from 'images/rasitgr/ridgeHog-min.jpg';
import Models from 'images/rasitgr/cdw-models.png'
import Mockup from 'images/rasitgr/cdw-mockup.jpg';
import Team from './Team';
import Testers from './Testers'
import { Helmet } from "react-helmet-async";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-green-500`;
  const HighlightedText = tw.span`text-green-500`;

  return (
    
    <>
      <Helmet>
        <title>Çılgın Algı Savaşları | Politik Kart Oyunu | RASITGR Games</title>
        <meta
          name="description"
          content="Çılgın Algı Savaşları, 3 ila 6 kişiyle oynanabilen, politik temalı set tamamlama kart oyunudur. Hızlı tempolu ve renkli bir oyun deneyimi sunar."
        />
        <meta name="keywords" content="Çılgın Algı Savaşları, kart oyunu, politik oyun, RASITGR Games" />
        <meta property="og:title" content="Çılgın Algı Savaşları | Politik Kart Oyunu" />
        <meta property="og:description" content="Renkli ve politik temalı kart oyunu." />
      </Helmet>
    <Hero />
       <HeroSecondary roundedHeaderButton={true} />
     
      <InformativeFeature
        subheading={<Subheading>NEDİR BU</Subheading>}
        heading={
          <>
            Çılgın Algı Savaşları?
          </>
        }
        description={"Çılgın Algı Savaşları; 3 ile 6 kişi oynanabilen, bi turu 10 ile 15 dk arasında süren, politik temalı bi set tamamlama kart oyunudur."}
      id='video'
      />
     
    
      <FeatureWithSteps
        subheading={<Subheading>ADIM ADIM</Subheading>}
        heading={
          <>
            <HighlightedText>Kolaylaştırılmış </HighlightedText> Kullanım
          </>
        }
        textOnLeft={false}
        imageSrc={Mockup}
        imageAlt="Çılgın Algı Savaşları Cards and Box Mockup"
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
   
      />
      <MainFeature
        subheading={<Subheading>HAVALI Bİ OYUN</Subheading>}
        imageSrc={Models}
        imageAlt="Çılgın Algı Savaşları Characters Illustration"
        imageBorder={true}
        imageDecoratorBlob={true}
        description={"Çılgın Algı Savaşları, piyasadaki emsal kart oyunlarının tekdüze ve tekrar edici yapısından sıkılıp kendimiz oynamak için havalı bi oyun geliştirdik. Sonra ne mi yaptık? Seri üretime geçip, Çılgın Algı Savaşlarını herkesle paylaştık."}
      />

      <Team id="team" />
      <Testers id="testers" />

      <Testimonial
        subheading={<Subheading>Deneyenler</Subheading>} imageAlt="Çılgın Algı Savaşları Box Mockup"
        heading={
          <>
            Çılgın Algı Savaşları'na <HighlightedText>Bayıldı</HighlightedText>
          </>
        }
        description={"Çılgın Algı Savaşları'nı bi denediler. Önce kafaları karıştı, ikinci oyunda herkes bayıldı. Üçüncü oyunda herkesin içi dışındaydı!"}
        testimonials={[
          {
            stars: 1,
            profileImageSrc:
              Memet,
            heading: "Efsane mücadele",
            quote:
              "Yorumum başta görünsün diye 1 yıldız verdim. Oyun iyi güzel, arkadaşlıkları zedeleyebilecek türden bi mücadelesi var. Oyunu en hızlı bitiren kişi benmişim, o da benim farkım.",
            customerName: "Mehmet Eren 'RidgeHog'",
            customerTitle: "En hızlı kazanan ödüllü Tester"
          },
          {
            stars: 4,
            profileImageSrc:
              Ergun,
            heading: "Güzel tasarım, manilerden 1 puan kırdım",
            quote:
              "Oyunu hep ben kazanıyorum... Başlarda özel hissediyordum, sonra normalleşti. Oyunun dengesi güzel ama ben de dengeleri bozarım şimdi...",
            customerName: "Ergün 'HorsButcher'",
            customerTitle: "Unity Developer, RASITGR Games"
          }
        ]}
      />
      <div id="rules" ></div>
     <GetStarted/>

        <MainFeature2  id="quick-guide"/>

      <Rules id="rules" />

      <FAQ id="faq"
        subheading={<Subheading>SSS</Subheading>}
        heading={
          <>
            Sıkça Sorulan <HighlightedText>Sorular</HighlightedText>
          </>
        }
        faqs={[
          {id: 0, title: "Sıra bana geldi ne yapmalıyım?", description: ["Sıra size geldiğinde başlangıç olarak ortadaki desteden 2 kart çekmelisiniz.", "Devamında, elinizdeki kartları 2 gruba ayırabilirsiniz. Topluluk ve Yandaş kartlarını bir grup, Politik ve Propaganda kartlarını ayrı bir grup olarak sınıflandırabilirsiniz.", "Her tur, bu iki gruptan ikişer kart olmak üzere toplamda 4 kart oynama hakkınız vardır. *Elinizde bir gruptan çok fazla kart birikmesi gibi durumlarda sadece 1 gruptan oynamak koşuluyla istediğiniz gruptan 3 kart oynayabilirsiniz. *Dilerseniz hiç kart oynamadan pas da geçebilirsiniz."] },
          { id: 1, title: "Topluluk kartlarımı nasıl set yapabilirim?", description: ["Her topluluk kartının kendine öz rengi ve sayısı vardır. Kimi topluluklar 2 kartla tamamlanırken kimi topluluklar 4 kartla tamamlanır.", "Bir topluluk setini tamamlamak için bahsi geçen topluluğun gerektirdiği kadar kartı önünüze yerleştirmiş olmanız gerekmektedir. Elinizdeki kartlar tam olsa dahi set olarak sayılmaz, sadece yerdeki kartlar geçerlidir.", "Bir topluluk setini tamamlarken Yandaş kartlarından faydalanabilirsiniz. Bahsi geçen topluluk setine uygun renkte bir Yandaş kartı bulmanız gerekmektedir veya her renge uygun ‘Omurgasız’ yandaş kartını da kullanabilirsiniz.","Set yaparken aklınızda bulunması gereken 2 önemli nokta vardır; *Tamamlanmış setler sizin tarafınızdan bozulamaz. *Sadece Yandaş kartlarından oluşan setler geçerli olmaz."]},
            { id: 2, title: "Oy gücümü nasıl hesaplarım?", description: ["Tamamladığınız setlerinizin değeri Oy Gücünüzü belirler. Örnek olarak, 2 parçadan oluşan lacivert seti ele aldığımızda, bahsi geçen setin Propaganda Gücü 4 olduğu için bu seti tamamladığınızda elde ettiğiniz Oy Gücü de 4’tür. *Bu set iki parçadan oluştuğu için (2x4), [(Set Sayısı) x (Propaganda Gücü)] gibi bir hesaplamayla 8 Oy Gücü elde etmezsiniz. Setin sayısından bağımsız olarak sadece Propaganda Gücü kadar Oy Gücü elde edersiniz. Yani örnek olarak, Lacivert seti tamamlamanız durumunda Oy Gücünüz 4 olacaktır."]},
            { id: 3, title: "İlkeler Şaşması kartını nasıl kullanırım?", description: ["İlkeler şaşması kartı, bir oyuncunun önüne yerleştirdiği topluluk kartlarından biriyle kendi önünüzdeki topluluk kartlarından birini takas etmenizi sağlar.", "Yandaş kartları bu takasta kullanılamaz."]},
            { id: 4, title: "Nasıl miting yaparım?", description: ["Miting yapmak için öncelikle sahanızda Topluluk kartı olması gerekmektedir. Sahanızdaki topluluk kartına uygun renkte bir Miting kartı oynamanız durumunda, Topluluk kartınızın Miting Gücü kadar PG’yi rakiplerinizden istersiniz.", "Topluluk kartlarınızın sayısı arttığında Miting Güçleri de artacaktır.", "Miting yaparken diğer politik kartlarla kombinasyon yapıp, çılgın bir politika yürütebilir büyük algılar kasabilirsiniz."]},
            { id: 5, title: "Yandaş kartını nasıl kullanırım?", description: ["Yandaş kartları kendi renklerindeki topluluk kartlarının tamamlayıcı kartlarıdır.", "Sahaya tek başına yerleştirebilir veya uygun renkteki bir Topluluk kartının altına ekleyebilirsiniz.", "Yandaş kartını kullanırken dikkat etmeniz gereken şeylerden biri de bu kartın yerini değiştirmek istediğinizde 1 hamle harcamanız gerektiğidir."]},
            { id: 6, title: "Karalama kartını nasıl kullanırım?",  description: ["İki tür karalama kartı bulunmaktadır. Bunlar; Topluluk kartları üzerine oynananlar ve Propaganda Birikimi üzerine oynanan olmak üzere iki ayrılır.", "Topluluk kartı üzerine oynanan Karalama kartları sadece set üzerine oynanabilir ve oynandığı setin Oy Gücünü azaltır. Ne kadar azaltacağı kartın açıklamasında belirtilmiştir.", "Propaganda Birikimi üzerine oynanan Karalama kartı oynandığı kartların tamamını aklanana kadar etkisiz kılar.", "Birinin oyunu bitirmesi durumunda elden oynayıp rakibinizin galibiyetini engellemeyi deneyebilirsiniz."]},
            { id: 7, title: "Bir set üzerine iki adet Karalama kartı kullanılabilir mi?", description: ["Evet, bir set üzerine birden fazla Karalama kartı kullanılabilir."]},
            { id: 8, title: "Bir setin Oy Gücü karalama kartı ile eksiye düşebilir mi?", description: ["Hayır, bir setin oy gücü eksiye düşemez."]},
            { id: 9, title: "Ak Süt Dökülmesi kartını nasıl kullanabilirim?", description: ["Ak Süt Dökülmesi kartını sıra size geldiğinde diğer Politik kartlar gibi oynayabilirsiniz.", "Dilediğiniz bir Karalama kartını havaya uçurabilirsiniz, bir rakibiniz üzerinden oynanmış Karalama kartları dahil."]},
            { id: 10, title: "Ayran Dökülmesi kartını nasıl kullanabilirim?", description: ["Ayran Dökülmesi kartını bir rakibinizin Propaganda Birikiminden bir kartı havaya uçurmak için diğer Politik kartlar gibi kullanabilirsiniz.", "Rakibinizin Propaganda Birikim destesini elinize alıp dilediğiniz kartı seçebilirsiniz.", "Bu desteyi diğer oyunculara göstermek zorunda değilsiniz."]},
            { id: 11, title: "Ak Süt Dökülmesi kartımla iki Karalama kartını birden havaya uçurabilir miyim?", description: ["Hayır, her Ak Süt Dökülmesi kartı 1 adet Karalama kartını havaya uçurabilir."]},
            { id: 12, title: "Popülizm Dalgası kartını nasıl kulanabilirim?", description: ["Popülizm Dalgası kartını sadece Miting yaparken tek seferlik kullanabilirsiniz.", "Kartı kullandıktan sonra ıskartaya çıkarmalısınız."]},
            { id: 13, title: "Havuz Medyası kartını nasıl kullanabilirim?", description: ["Havuz Medyası kartını sadece Miting yaparken tek seferlik kullanabilirsiniz.", "Kartı kullandıktan sonra ıskartaya çıkarmalısınız."]},
            { id: 14, title: "Popülizm Dalgası ve Havuz Medyası kartlarını aynı Mitingde kullanabilir miyim?", description: ["Evet bu iki kartı herhangi bir mitinginizle kombine kullanabilirsiniz.", "Hesaplama yaparken önce Popülizm Dalgasının bonusunu Miting Gücünüze ekleyin daha sonra Havuz Medyasının Bonusunu kullanın. [ö. 2(1MG + 4MG)]"]},
            { id: 15, title: "Yalanlama kartını nasıl oynayabilirim?", description: ["Yalanlama kartı diğer kartların aksine sıra sizde değilken dahi elden oynanabilir.", "Yalanlama kartı bir politik karta karşı veya bir politik kart kombinasyonuna karşı kullanılabilir."]},
            { id: 16, title: "Yalanlama kartı ile karalama kartını engelleyebilir miyim?", description: ["Hayır, yalanlama kartı karalama kartlarına karşı etkisiz."]},
            { id: 17, title: "Yalanlama kartı karşılıklı olarak oynanabilir mi?", description: ["Evet. Bir politik kartın size yaptırımlarını reddetmek istediğinizde Yalanlama kartını kullanabilirsiniz ancak rakibinizin de sizin Yalanlamanızı Yalanlamak gibi bir kozu bulunabilir. Bu durumda en son Yalanlama kartı kullanan oyuncu düelloyu kazanmış sayılacaktır."]},
            { id: 18, title: "Ayrılan Güçler Üçlemi kartını nasıl oynamalıyım?", description: ["Ayrılan Güçler Üçlemi kartını ıskartaya çıkarıp, desteden iki kart çekip, hamle sayınızı sıfırlayarak yeniden oynayın."]},
            { id: 19, title: "Konjonktür Tutulması kartını nasıl oynamalıyım?", description: ["Konjonktür Tutulması kartını, bi oyuncunun tüm propaganda birikimini ıskartaya çıkarmak için kullanabilirsin."]},
            { id: 20, title: "Kukla Ustalığı kartını nasıl oynamalıyım?", description: ["Kukla Ustalığı kartını rakip oyuncularının tamamlanmış setlerinin üzerine yerleştirerek o setin oy gücünden ikiniz de faydalanabilirsiniz.", "Setin bozulması durumunda kukla ustalığı kartı düşer ve ıskartaya çıkarılır."]},
            { id: 21, title: "Burjuvazik Sempatisi kartını nasıl oynamalıyım?", description: ["Elindeki kartları beğenmediğinde bu kartı oynayarak desteni reset’leyebilirsiniz. Burjuvazik Sempatisi kartını ve elindeki kartları ıskartaya çıkartıp 5 yeni kart çekerek oynayın."]},
            { id: 22, title: "Liderlik Vasfı ve Anketör Yanlaması kartlarını nasıl oynamalıyım?", description: ["Liderlik Vasfı ve Anketör Yanlaması kartları sahanızda Topluluk kartları gibi muamele görmektedir.", "Tek başlarına bir set gibi davranan bu kartlar, bulundukları sahanın sahibi oyuncuya +1 Oy Gücü veya -1 Oy Gücü sağlamaktadır.", "Anketör Yanlaması kartını bir rakibin sahasına yerleştirebilirsiniz."]},
            { id: 23, title: "Nepotistik Bükülme, İlkeler Şaşması, Köken Kayması kartlarıyla rakibimin bir setini bozabilir miyim?", description: ["Hayır bu kartları tamamlanan setler üzerinde kullanamazsınız."]},
            { id: 24, title: "Apolitik Dalga kartını nasıl kullanırım?", description: ["Apolitik dalga kartı, her oyuncunun önünden birer topluluk kartı almanızı sağlar. Ancak, tamamlanmış setleri bozamaz ve yandaş kartı alamazsınız.", "Alacağınız kartları siz seçersiniz."]},
            { id: 25, title: "Totaliter Kokuşması kartını nasıl kullanırım?", description: ["Totaliter kokuşması kartı, her oyuncunun önünden ikişer topluluk kartı almanızı sağlar. Ancak, tamamlanmış setleri bozamaz ve yandaş kartı alamazsınız.", "Alacağınız kartları rakip oyuncular seçer."]},
            { id: 26, title: "Güç Zehirlenmesiyle zehirlendim, bu tur oynamasam da desteden kart çekebilir miyim?", description: ["Hayır, bu tur boyunca yalanlama kartı dahi kullanamazsınız.", "Zehiriniz, sıra sizi zehirleyen oyuncuya yeniden gelene kadar bitmez."]},
            { id: 27, title: "Kemik Pazarlığı kartını nasıl kullanabilirim?", description: ["Kemik Pazarlığı kartı, tamamlanmış setlerin takası için kullanılır."]},
            { id: 28, title: "Kemik Çıkması, Kemik Pazarlığı ve Oyların Mühürsüzlüğü kartlarını tamamlanmamış setler üzerine oynayabilir miyim?", description: ["Hayır, bu kartları sadece tamamlanmış setler üzerine oynayabilirsiniz."]},
            { id: 29, title: "Oynadığım kartları geri alabilir miyim?", description: ["Hayır oynanan kartları geri elinize alamazsınız."]},
            { id: 30, title: "Ödememi Topluluk veya Yandaş kartıyla yapabilir miyim?", description: ["Evet ödemenizi bu şekilde yapabilirsiniz ancak ödeme yapmak için bir seti bozamazsınız. Sadece başka alternatifiniz kalmadığı durumlarda, sahada başka kartınız kalmadığı durumlarda bu şekilde ödeme yapabilirsiniz."]},
            { id: 31, title: "Bir rakibim benden PG istedi ancak sahamda hiç kartım yok ne yapmalıyım?", description: ["Herhangi bir rakibinizin sizden PG istemesi durumunda sahanızda hiç kartınız yoksa ödeme yapmakla yükümlü değilsiniz. Rakibinizin stratejik hatasının veya dezavantajının sorumlusu siz değilsiniz."]},
            { id: 32, title: "Bir yandaş kartımın yerini değiştirmek istiyorum, ne yapmalıyım?", description: ["Yandaş kartlarının yerini değiştirmek için bir hamle harcamanız gerekmektedir.", "Bir sete ait yandaş kartının yerini değiştirmek için seti bozmadan yerine uygun başka bir kart yerleştirmeniz gerekmektedir."]},
            { id: 33, title: "Yandaş kartlarından destek alarak bir set yapmıştım ancak bu Yandaş kartı yerine elimdeki bir topluluk kartını yerleştirip Yandaş kartımı başka bir set için kullanmak istiyorum, ne yapmalıyım?", description: ["Bahsi geçen durumda eğer setiniz tamamlanmışsa, Yandaş kartınızı boşa çıkarmak için sete uygun farklı bir kartı yerleştirmeniz gerekmektedir. Bu şekilde, Yandaş kartınız boşa çıkar ancak elinize dönmez.", "Yandaş kartınızı başka bir sete yerleştirmek istemeniz durumunda 1 hamlenizi bu işlem için harcamış sayılırsınız."]},
            { id: 34, title: "Sadece Yandaş kartlarından oluşan bir set yapabilir miyim?", description: ["Hayır, bir seti tamamlamak için en az 1 tane Topluluk kartına ihtiyaç var."]},
            { id: 35, title: "Karalama kartıyla karalanmış bir set bozuldu, Karalama kartına ne olacak?", description: ["Bu durumlarda karalama kartı ıskartaya çıkarılır."]},
            { id: 36, title: "Yandaş uçurma kartıyla rakibimin tamamlanmış bir setini bozabilir miyim?", description: ["Yandaş uçurma kartları tamamlanmış setleri bozmak için kullanılamaz sadece bağımsız ve tamamlanmamış Yandaş kartları için kullanılabilir."]},
            { id: 37, title: "Aktivist Selamlaması kartını nasıl kullanmalıyım?", description: ["Aktivist selamlaması kartı, şu an yapılan bi mitingi iptal etmek için kullanılır. Rakibiniz mitin yaparken, elinizde oynayabilirsiniz.", "Aktivist selamlaması kullanırken, mitinge dahil edilen Havuz Medyası ve Popülizm Dalgası gibi tüm kartları da iptal edip ıskartaya çıkmasına sebep olursunuz."]},
            { id: 38, title: "Çıkar Çakışması kartını nasıl kullanmalıyım?", description: ["Çıkar çakışması kartı, rakiplerinin önündeki herhangi bi yandaş kartını ortadan kaldırmak için kullanılır."]},
            { id: 39, title: "Çapulcu Çakozlatması kartını nasıl kullanabilirim?", description: ["Çapulcu Çakozlatması kartı, her oyuncudan önlerindeki topluluk ve yandaş kartı sayısının toplamı kadar PG almanızı sağlar."]},
            { id: 40, title: "Güç Zehirlenmesi kartını nasıl kullanabilirim?", description: ["Güç zehirlenmesi kartını bir rakip oyuncunun önüne yerleştirdiğinizde, sıra tekrar size gelene kadar oynamasını engellersiniz.", "Güç Zehirlenmesi kartı aynı anda size de atılırsa, siz tekrar oynayana kadar zehirlediğiniz oyuncu da oynayamaz."]},
            { id: 41, title: "Proleter Empatisi kartını ne işe yarar?", description: ["Proleter Empatisi kartı, bi rakibin elindeki tüm kartları ıskartaya çıkarmanızı sağlar."]},
            { id: 42, title: "Keser Dönmesi kartı ne işe yarar?", description: ["Keser Dönmesi kartı, rakiplerinizin önündeki herhangi bir yandaş kartını almanızı sağlar. Tamamlanmış setleri bozamazsınız."]},
            { id: 43, title: "Artist Çıkarması kartı ne işe yarar?", description: ["Artist Çıkarması kartını bi rakip topluluk setinin üzerine yerleştirdiğinizde, bahsi geçen topluluk kartıyla miting yapılmasını engellersiniz."]},
            { id: 44, title: "Siyasi Delaleti kartını ne işe yarar?", description: ["Siyasi Delaleti kartı, her oyuncunun elindeki kartları yanındaki oyuncuya vermesini sağlar. Elinde kart olmayan oyuncular kart vermekle yükümlü değildir."]} ]}
      />

          <Footer />
   
    
    </>
  );
}
