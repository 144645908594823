import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings";
import Hero from 'components/tr/Hero2024';
import Footer from 'components/tr/Footer';
import { Helmet } from "react-helmet-async";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-400 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-300`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Kullanım Koşulları" }) => {
  return (
    <div>
       <Helmet>
        <title>Kullanım Koşullar | RASITGR Games</title>
        <meta
          name="description"
          content="RASITGR Games kullanımı için geçerli olan kullanım şartları. Oyunlarımızı oynarken bu şartları kabul etmiş sayılırsınız."
        />
        <meta name="keywords" content="Kullanım şartları, RASITGR Games" />
        <meta property="og:title" content="Kullanım Şartları | RASITGR Games" />
        <meta property="og:description" content="Oyunlarımızı oynarken bu kullanım şartlarını kabul edersiniz." />
      </Helmet>
      <Hero/>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>

          <p>Son güncelleme: 14 Eylül 2024</p>

<p>Lütfen Hizmetimizi kullanmadan önce bu şartları dikkatlice okuyun.</p>

<h1>Yorum ve Tanımlar</h1>
<h2>Yorum</h2>
<p>
Baş harfi büyük olan kelimeler, aşağıdaki koşullar altında tanımlanan anlamlara sahiptir.
</p>
<p>
Aşağıdaki tanımlar, tekil ya da çoğul olarak kullanılsalar da aynı anlama gelir.
</p>

<h2>Tanımlar</h2>
<p>Bu Şartlar ve Koşullar kapsamında:</p>
<ul>
  <li>
    <strong>Bağlı Kuruluş</strong>, bir tarafın kontrol ettiği, kontrol ettiği veya bir tarafla ortak kontrol altında
    bulunduğu bir kuruluş anlamına gelir; burada "kontrol", hisselerin, sermaye payının veya diğer menkul kıymetlerin en
    az %50'sine sahip olmayı ifade eder.
  </li>
  <li>
    <strong>Şirket</strong> (bu Anlaşmada "Şirket", "Biz" veya "Bizim" olarak anılacaktır), Alp Kurt'a ait RASITGR
    Games'i ifade eder.
  </li>
  <li>
    <strong>Ülke</strong>, Türkiye (veya kullanıcının mevcut konumuna göre Almanya) anlamına gelir.
  </li>
  <li>
    <strong>Hizmet</strong>, Web Sitesini ifade eder.
  </li>
  <li>
    <strong>Şartlar ve Koşullar</strong> (aynı zamanda "Şartlar" olarak da anılır), Hizmetin kullanımıyla ilgili olarak
    sizinle Şirket arasında yapılan tüm anlaşmayı oluşturan bu Şartlar ve Koşullar anlamına gelir.
  </li>
  <li>
    <strong>Üçüncü Taraf Sosyal Medya Hizmeti</strong>, Hizmet tarafından görüntülenen, dahil edilen veya sunulan
    üçüncü taraflara ait veri, bilgi, ürün veya hizmetler anlamına gelir.
  </li>
  <li>
    <strong>Web Sitesi</strong>, https://rasitgr.com adresinden erişilebilen RASITGR Games'i ifade eder.
  </li>
  <li>
    <strong>Sen</strong>, Hizmete erişen veya Hizmeti kullanan bireyi ifade eder.
  </li>
  <li>
  <strong>Kullanım Verileri</strong>, Hizmetin kullanımı veya Hizmet altyapısından (örneğin, bir sayfada geçirilen süre) otomatik olarak toplanan verileri ifade eder.
</li>

</ul>

<h1>Kabul</h1>
<p>
Bu Şartlar, Hizmetin kullanımı ile ilgili olarak sizinle Şirket arasındaki anlaşmayı düzenler. Bu Şartlar, Hizmetin
kullanımıyla ilgili tüm kullanıcıların hak ve yükümlülüklerini belirler.
</p>
<p>
Hizmete erişiminiz ve Hizmeti kullanmanız, bu Şartları kabul etmenize ve bunlara uymanıza bağlıdır. Bu Şartlar, Hizmete
erişen veya Hizmeti kullanan tüm ziyaretçiler, kullanıcılar ve diğerlerine uygulanır.
</p>
<p>
Hizmete erişerek veya Hizmeti kullanarak bu Şartlara bağlı kalmayı kabul ediyorsunuz. Bu Şartların herhangi bir kısmını
kabul etmiyorsanız, Hizmete erişmeyin.
</p>
<p>
18 yaşın üzerinde olduğunuzu beyan ediyorsunuz. Şirket, 18 yaşından küçüklerin Hizmeti kullanmasına izin vermez.
</p>

<h1>Veri Toplama ve Kullanımı</h1>
<p>
  Hizmetimiz, anonim kullanım verilerini toplamak için Google Analytics kullanır. Bu veriler IP adresiniz, tarayıcı türü, ziyaret ettiğiniz sayfalar ve bu sayfalarda geçirilen süre gibi bilgileri içerebilir. Bu verilerin toplanmasının amacı, web sitesi trafiğini ve kullanıcı davranışlarını izleyerek hizmetlerimizi iyileştirmektir.
</p>
<p>
  Google Analytics takibinden çıkmak için <a href="https://tools.google.com/dlpage/gaoptout">Google Analytics çıkış tarayıcı eklentisini</a> yükleyebilirsiniz.
</p>


<h1>Diğer Web Sitelerine Bağlantılar</h1>
<p>
Hizmetimiz, Şirket tarafından işletilmeyen üçüncü taraf web sitelerine veya hizmetlerine bağlantılar içerebilir.
</p>
<p>
Şirket, üçüncü taraf web sitelerinin veya hizmetlerin içerikleri, gizlilik politikaları veya uygulamaları üzerinde
herhangi bir sorumluluk üstlenmez. Bu tür içerik, mal veya hizmetlerin kullanımından kaynaklanan herhangi bir zarar veya
kayıp konusunda Şirketin sorumlu olmadığını kabul ediyorsunuz.
</p>
<p>
Ziyaret ettiğiniz üçüncü taraf web sitelerinin veya hizmetlerinin şartlarını ve gizlilik politikalarını okumanızı
şiddetle tavsiye ederiz.
</p>

<h1>Fesih</h1>
<p>
Herhangi bir nedenle, bu Şartları ihlal etmeniz dahil olmak üzere, size önceden haber vermeksizin erişiminizi derhal
sonlandırabilir veya askıya alabiliriz.
</p>
<p>Fesih anında Hizmeti kullanma hakkınız derhal sona erecektir.</p>

<h1>Sorumluluğun Sınırlandırılması</h1>
<p>
Geçerli yasaların izin verdiği ölçüde, Şirket, Hizmetin kullanımından kaynaklanan özel, tesadüfi, dolaylı veya sonucu
olarak meydana gelen zararlar (kar kaybı, veri kaybı veya işin kesilmesi dahil ancak bunlarla sınırlı olmamak üzere)
konusunda sorumlu olmayacaktır.
</p>
<p>
Bazı eyaletler, zımni garantilerin veya tesadüfi ya da sonuç olarak meydana gelen zararların sorumluluğunun sınırlandırılmasına izin vermez, bu da yukarıdaki sınırlamaların bazılarının geçerli olmayabileceği anlamına gelir. Bu eyaletlerde, tarafların sorumluluğu yasaların izin verdiği en geniş ölçüde sınırlı olacaktır.
</p>
<p>
  Şirket, Google Analytics gibi üçüncü taraf hizmetlerden kaynaklanan herhangi bir zarardan sorumlu değildir. Bu, veri kaybı, gizlilik ihlali veya analiz verilerindeki yanlışlıklar gibi durumları içerir ancak bunlarla sınırlı değildir.
</p>

<h1>Çerezler ve Takip</h1>
<p>
  Kişiselleştirilmiş takip için çerezler kullanmasak da, Google Analytics kullanım verilerini toplamak için çerezler kullanabilir. Takibi kontrol etmek veya engellemek için tarayıcı ayarlarınızdan çerezleri yönetebilirsiniz.
</p>


<h1>"OLDUĞU GİBİ" ve "MEVCUT OLDUĞU KADAR" Feragatnamesi</h1>
<p>
Hizmet size "OLDUĞU GİBİ" ve "MEVCUT OLDUĞU KADAR" sunulmaktadır ve herhangi bir türde açık veya zımni garanti olmadan sağlanmaktadır. Şirket, Hizmetin gereksinimlerinizi karşılayacağı, kesintisiz veya hatasız olacağı veya hataların düzeltilebileceği konusunda hiçbir garanti vermez.
</p>

<h1>Geçerli Hukuk</h1>
<p>
Bu Şartlar ve Hizmeti kullanımınız, Türkiye (veya kullanıcının konumuna göre Almanya) yasalarına tabi olacaktır.
</p>

<h1>Anlaşmazlıkların Çözümü</h1>
<p>
Hizmetle ilgili herhangi bir endişe veya anlaşmazlığınız varsa, önce bizimle info@rasitgr.com adresinden iletişime geçerek bu anlaşmazlığı gayri resmi olarak çözmeyi kabul ediyorsunuz.
</p>

<h1>Bölünebilirlik ve Feragat</h1>
<h2>Bölünebilirlik</h2>
<p>
Bu Şartların herhangi bir hükmü uygulanamaz veya geçersiz olarak kabul edilirse, bu hüküm, ilgili hükmün amacına en fazla uyacak şekilde değiştirilir ve yorumlanır ve kalan hükümler tam olarak yürürlükte kalır.
</p>

<h1>Çeviri Yorumlaması</h1>
<p>
Bu Şartlar ve Koşullar, Hizmetimizde size sunulmuşsa çevrilmiş olabilir.
</p>
<p>Bir anlaşmazlık durumunda orijinal İngilizce metnin geçerli olacağını kabul ediyorsunuz.</p>

<h1>Bu Şartlar ve Koşullardaki Değişiklikler</h1>
<p>
Bu Şartları kendi takdirimize bağlı olarak değiştirme hakkımız saklıdır. Eğer bir değişiklik önemliyse, yeni şartlar yürürlüğe girmeden en az 30 gün önce bildirimde bulunacağız.
</p>
<p>
Değişikliklerin yürürlüğe girmesinden sonra Hizmetimize erişmeye veya kullanmaya devam ederek, revize edilen şartlarla bağlı kalmayı kabul ediyorsunuz. Yeni şartları kabul etmiyorsanız, Hizmeti kullanmayı bırakın.
</p>

<h1>Bize Ulaşın</h1>
<p>Bu Şartlar ve Koşullar hakkında herhangi bir sorunuz varsa, bize şu e-posta adresinden ulaşabilirsiniz:</p>

<ul>
  <li>info@rasitgr.com</li>
</ul>

          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </div>
  );
};
