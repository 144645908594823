import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-green-900 text-gray-100 `;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-gray-300`;

const FaqsContainer = tw.div`mt-10 sm:mt-16 w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto`;
const FaqsColumn = tw.div`w-full lg:max-w-lg lg:mr-12 last:mr-0`;
const Faq = tw.div`select-none cursor-pointer border-b-2 border-green-300 hover:border-green-500 transition-colors duration-300 py-6`;
const Question = tw.div`flex justify-between items-center`;
const QuestionText = tw.div`text-sm sm:text-lg font-semibold tracking-wide`;
const QuestionToggleIcon = styled(motion.span)`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Answer = tw(motion.div)`hidden text-sm font-normal mt-4 text-gray-300`;

export default ({
  subheading = "Misyonumuz",
  heading = "Tutkuyu Oyunlara Taşımak",
  description = "Oyunların sadece bir eğlence aracı olmadığını düşünüyoruz; aynı zamanda hikaye anlatmanın, yaratıcılığı ifade etmenin ve topluluklar inşa etmenin bir yolu. RASITGR Games'teki amacımız, günümüz oyun dünyasında öne çıkan özgün indie oyunlarını desteklemek ve geliştirmektir.",
  faqs = [
    {
      question: "Vizyonumuz",
      answer:
        "RASITGR Games olarak, özgün ve özel oyunlar yaratmaya kendimizi adadık. Seri üretim modelini takip etmek yerine, her unsuru özenle kendi bünyemizde üretiyor, her projeyi eşsiz ve sürükleyici bir deneyim haline getiriyoruz."
    },
    {
      question: "Hedefimiz",
      answer:
        "En büyük hedefimiz, oyun geliştirerek kendimizi sürdürebilmek. Her projede yenilik ve öngörülemezlikten ilham alıyoruz, oyuncuyu merkeze alan yeni bir hikaye sunuyoruz. Fikirlerimiz tükendiğinde bile, bu meydan okumayı bir oyuna dönüştüreceğiz. Oyun geliştirmeye olan tutkumuz sınırsız."
    },
    {
      question: "Bizi Farklı Kılan Nedir?",
      answer:
        "Oyunları önce kendimiz oynamak için tasarlıyoruz. Odak noktamız kârı maksimize etmek değil, oyun oynama zevkine dayalıdır. Oynamak için yapılan oyunların, daha samimi ve keyifli olduğuna inanıyoruz."
    },
    {
      question: "Benzersiz Yaklaşımımız",
      answer:
        "Konseptten lansmana kadar, oyunlarımızın her aşaması dahili olarak üretilir ve yönetilir. Hizmetleri dış kaynaklardan almıyoruz veya dış finansmana güvenmiyoruz. Vizyonumuza ve değerlerimize sadık kalarak öğreniyor ve gelişiyoruz."
    },
    {
      question: "Tek Endişemiz",
      answer:
        "Amacımız eğlendirmek, etkilemek ve bazen düşündürmek, tekrarlayan fikirlerden uzak durarak yeni projeler yaratmak. Her projede yeniliği önceliklendiriyoruz, formüle dayalı yaklaşımlar yerine yeni konseptler ve temaları keşfetmeyi tercih ediyoruz."
    },
    {
      question: "Sürdürülebilirlik",
      answer:
        "Oyun geliştirme sürecine bütünsel bir yaklaşımla yaklaşıyoruz; hayal ediyor, planlıyor, tasarlıyor, test ediyor ve her ayrıntıyı eleştiriyoruz. Fiyatlandırmamız, herkesin erişebilmesini sağlayacak şekilde bütçe dostu olacak şekilde tasarlandı ve gelecekteki projelerin maliyetlerini karşılamayı amaçlıyor. Modern geliştirme yöntemleri ile sürdürülebilir uygulamaları harmanlayarak oyunlarımızın hem yenilikçi hem de kalıcı olmasını sağlıyoruz.",
    }
  ]
}) => {
  const faqCol1 = [];
  const faqCol2 = [];
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  faqs.map((faq, index) => {
    const renderedFaq = (
      <Faq key={index} onClick={() => toggleQuestion(index)}>
        <Question>
          <QuestionText>{faq.question}</QuestionText>
          <QuestionToggleIcon
            variants={{
              collapsed: { rotate: 0 },
              open: { rotate: -180 }
            }}
            initial="collapsed"
            animate={activeQuestionIndex === index ? "open" : "collapsed"}
            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ChevronDownIcon />
          </QuestionToggleIcon>
        </Question>
        <Answer
          variants={{
            open: { opacity: 1, height: "auto", marginTop: "16px", display: "block" },
            collapsed: { opacity: 0, height: 0, marginTop: "0px", display: "none" }
          }}
          initial="collapsed"
          animate={activeQuestionIndex === index ? "open" : "collapsed"}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {faq.answer}
        </Answer>
      </Faq>
    );

    if (index % 2 === 0) faqCol1.push(renderedFaq);
    else faqCol2.push(renderedFaq);

    return null;
  });
  return (
    <PrimaryBackgroundContainer>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
        </HeadingContainer>
        <FaqsContainer>
          <FaqsColumn>{faqCol1}</FaqsColumn>
          <FaqsColumn>{faqCol2}</FaqsColumn>
        </FaqsContainer>
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
};
