import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";

const Container = tw.div`relative -mx-8 px-8 bg-gray-900`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const TwoColumn = tw.div`flex`;
const Column = tw.div``;

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
  props.imageShadow ? tw`shadow` : tw`shadow-none`,
  tw`hidden lg:block rounded h-144 bg-center`
]);

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-left text-gray-400`;
const Heading = tw(SectionHeading)`lg:text-left`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-gray-400`;

const FAQSContainer = tw.dl`mt-12`;
const FAQ = tw.div`cursor-pointer mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center text-gray-200`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold text-gray-300`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-green-500 text-gray-100 p-1 rounded-full group-hover:bg-green-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed text-gray-400`);

export default ({
  subheading = "",
  heading = "Oynanış",
  description = "Oyuna başlamadan önce bi okunması gerektiğini düşündüğümüz ve bi oyun oynamak için bu kadar şey okunmamalı dediğimiz bölüm.",
  imageSrc = "https://images.unsplash.com/photo-1579427421635-a0015b804b2e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
  imageContain = false,
  imageShadow = true,
  faqs = null
}) => {
  /*
   * You can modify FAQs either by modifying the below defaultFaqs array or by passing a custom array of FAQs using
   * the faqs prop
   */
  const defaultFaqs = [
    {
      question: "Aktiviteler",
      answer:
        "Anadolu Rock'ta 5 temel aktivite türü vardır. Bunlar; Zaman Öldürme, Bilgilenme, Kişisel Bakım, Online Alışveriş ve Yüz yüze alışveriştir."
    },
    {
      question: "Aktiviteler: Zaman öldürme aktiviteleri",
      answer:
        "9 adet zaman öldürme aktivitesi vardır. Bunların başında Anadolu Rock dinleme gelmektedir. Cengiz'e en iyi gelen şey Anadolu Rock dinlemektir. Diğer aktiviteler ise; video oyunu oynamak, mesajlaşmak, spor yapmak, sosyal medyada aşağı kaymak, televizyon izlemek, internetten dizi izlemek, kitap okumak ve müzik dinlemek. Bu aktiviteleri peş peşe yapmak Cengiz'in bağımlı olmasına ve bu aktivitelerden fayda almak yerine kendine zarar vermesine sebep olabilir. Cengiz'in bağımlı olması halinde Cengiz, sizden habersiz olarak yapılacaklar listesine bağımlı olduğu aktiviteleri ekleyebilir."
    },
    {
      question: "Aktiviteler: Haber aktiviteleri",
      answer:
        "Bilgilenme aktiviteleri temelde üçe bölünmektedir. Ancak, kitap okumanın da faydalı bir 'zaman öldürme' olduğunun altını çiziyoruz :). Bilgilenme aktiviteleri şöyledir; internet araştırması, sosyal medya haberleri ve televizyon haberleri. Bu 3 bilgilenme aktivitesi, birbirinden farklı sonuçlara sebep olabilir. Bilgi kirliliğinin 'pik' yaptığı bu dönemde televizyon haberleri sizi hızla mutlu ederken, sosyal medya haberleri canınızı sıkabilir, internet haberleri ise tam bir rus ruleti... Tabii ki sadece bu kadar değil ancak kalan detayları keşfetmeniz için size bırakıyoruz :).        "
    },
    {
      question:"Aktiviteler: Kişisel bakım aktiviteleri",
      answer:"Kişisel bakım aktivitelerini 4 + 1 olarak kodladık. Sigara içmenin zararlarının altını çizerek bu 5 aktiviteyi anlatıyoruz. Yemek sipariş etmek, yemek pişirmek, yemek yemek, uyumak ve sigara içmek. Burada bir detaya dikkatinizi çekmek istiyoruz. Oyunun başında sorulan 'sigara içiyor musun' sorusuna hayır cevabını verdiyseniz, sigara içmek sigara bağımlılığınızı yeniden başlatacaktır. Sigarayı yeniden bırakmak için bir süre sabretmeniz gerekecektir. Bu süreçte Cengiz, mental olarak zorlanabilir ancak sigarayı bıraktığında yeniden eski formuna ulaşacaktır. Bunun dışında sizin de fark ettiğiniz gibi Cengiz'e su iç demenize gerek yok! Cengiz suyunu kendisi içebilirken, siz yemek ye demezseniz açlıktan zayıf düşebilir. Ve unutmadan, insomnia'dan şikayetçi olmak istemiyorsanız Cengiz'in tek seferde günlük 8 saat civarında uyuduğundan emin olmaya bakın. Bölünmüş veya düzensiz uykular Cengiz'in insomnia yaşamasına sebep olabilir."
    },
    {
      question: "Aktiviteler: Satın alma aktiviteleri",
      answer:
        "Satın alma aktiviteleri online ve yüz yüze olmak üzere ikiye ayrılmaktadır. Satın alabileceğiniz şeyler; yemek stoğu, su stoğu, maske, mental sağlığınız için kendinize küçük hediyelerdir. Ayrıca yüz yüze alışveriş sekmesinde arkadaşlarınızla buluşma seçeneğiniz de vardır. Cengiz'i rahatlatacak olsa da arkadaşlarınızın bilinçli davranarak sizi reddedeceğini üzülerek belirtiyoruz. Bu bizzat benim fikrimdi. Ben, bu metnin yazarının. Bu buton, arkadaşlarınızın sizi reddetmesinden ve enerji harcamanızdan başka hiçbir işe yaramamaktadır. Tabii arkadaşlarınız sizinle buluşmamış olsa da Cengiz dışarı çıkmış olacak. Eğer maske takmadan çıktıysanız, olabileceklerden biz sorumlu değiliz, ben hiç değilim."
    },
    {
      question:"Mücadele Unsurları",
      answer:"Anadolu Rock'ta mücadele unsurlarının detayları ortalamanın biraz üstünde olabilir. Temel olarak bir kaynak yönetiminden ve birçok parametrenin sağlıklı değerler arasında kalmasından sorumlusunuz. Bir sonraki paragrafta, bu açıklamayı ışık hızında daha keyifli bir hale getiriyoruz!."
    },
    {
      question:"Mücadele Unsurları: Kaynaklar",
      answer:"Kaynak yönetimi ile başlayalım. Öncelikle ekranın üst kısmındaki göstergede fark ettiğiniz gibi birkaç kaynağı, bar kullanarak gösterdik. Enerji barı, açlık barı ve uyku barı. Bunlara ek olarak, bir miktar paramız ve stoklarımız da var. Bilgi butonuna bastığımızda stoklarımızı daha detaylı göreceğiz. Bu barlara hakkında altını çizmek istediğimiz bir şey var. Uyku barı, iki günlük uykunuzu temsil etmektedir. Yani, yarısı dolduğunda 8 saatlik bir uyku sizi mutlu edecektir. Bir diğer kaynağımız da zamanımız. Cengiz'in her 24 saati en verimli şekilde geçirdiğinden emin olmalısınız. Çünkü günler geçtikçe Cengiz'in kendini idame etmesi daha da zorlaşacaktır. Ve unutmadan, Cengiz'in yemek stoğu ve pişmiş yemek stoğu birbirinden ayrılmıştır. Cengiz'in başarılı bir şekilde yemek yiyebilmesi için yeteri kadar pişmik yemeği olduğundan emin olun. Eğer yoksa, hızla sipariş edebilirsiniz!"
    },
    {
      question: "Mücadele Unsurları: Parametreler",
      answer:
        "Parametrelere geldiğimizde, Cengiz'in dikkat etmesi gereken çokça detay var. Bunlar, bilgi butonuna bastığınızda karşınıza çıkan sağlık barlarından daha fazlası. Cengiz'in bilgi birikimini ve farkındalığını arttırdığınızda karşınıza çıkacak bir 'beyin' butonuyla bu detaylara daha fazla hakim olabilirsiniz. Sizin keşfetmeniz için bunları açıklamıyoruz ancak tutkunuzu açığa çıkarmak için de biraz bahsetmek gerek. Cengiz'in yaptığı her aktivite, farklı bir hormonu tetiklemekte ve farklı hormonlarla mutlu olmasını sağlamaktadır. Aynı şekilde, yaptığı her aktivite fiziksel olarak vücudunun farklı yerlerini yıpratmaktadır. Burada gerçekçi bir tutum sergilediğimizin altını çizerek, aktivite seçimlerinizi buna göre belirlemenizi öneririz. Ve son olarak, Cengiz'in bağımlılıkları... Sürekli aynı aktiviteyi yapmak Cengiz'i bağımlı yapacaktır. Bağımlılık halinde, Cengiz mutluluk hormonu salgılayamayıp endişesini artıracaktır. Bunu hiçbirimiz istemeyiz. Cengiz'i de biz insanlar gibi kodladık. Mutlu olmak onun da hakkı!"
    },
    {
      question: "Mücadele Unsurları: Farklı Sonlar ve Zorluklar",
      answer:"Oyunun başındaki sorunun farklı bir anlam kazandığını siz de fark ettiniz değil mi? Bu zorluk sorusu, Cengiz'in geçmişten getirdiği alışkanlıklarının onun bu karantina sürecine olan etkisini temsil etmektedir. Yani bu soruya hayır cevabını verip, oyunda sigara içmeye başlamanız, evet cevabını vermeniz kadar büyük sıkıntılar getirmeyecektir. Fizikselden ziyade mental bir zorluk yaratacaktır. Oyuna evet cevabıyla başladıktan sonra da sigarayı bırakıp sağlığınızı düzeltebileceğinizi unutmayın. Bazı beta tester'larımızın Cengiz'i hastaneye düşürdüğünde bile sigara içirmeye devam etmesi bizi oldukça şaşırtmakla beraber bunun büyük bir mücadele olduğunu da öğretti. Konumuza dönecek olursak, oyunun en az dört farklı bitiş senaryosu var. Bunları kendiniz keşfetmeniz için bütün hatlarıyla anlatmayacağız. Daha önce de bahsettiğimiz gibi sonlardan biri, 45 günlük bir sürede Cengiz'i mutlu bir şekilde tutabilmek."
    },
    {
      question: "Mücadele Unsurları: Oyun Sonu",
      answer:"Nihayet oyunun herhangi bir sonuna ulaştığınızda, karşınıza bir bilgilendirme ekranı çıkacaktır. Bu ekranın ekran görüntüsünü almak isteyebilirsiniz, çünkü kapattığınızda bir daha ulaşamayacaksınız. Bu ekranda Cengiz'in ilk günden beri hangi aktiviteye kaç saat ayırdığını görebilirsiniz. Eğer Cengiz'i kendiniz gibi yaşattıysanız, en iyi özeleştiri metodu olmasa bile özeleştirinizi yapmak için iyi bir fırsatla karşı karşıyasınız!"
    },
    {
      question: "Durumlar",
      answer: "Cengiz'in hislerini daha iyi yorumlayabilmeniz için Cengiz size Bilgi sayfasında ve bildirim ekranlarında bazı ipuçları verir. Bu bölümde bu ipuçlarından bahsedeceğiz. Genel olarak iki başlığımız var. Mental durumlar ve fiziksel durumlar. Mental durumlar daha önce de bahsettiğimiz hormonların birbirlerine oranı ile ilgiliyken fiziksel durumlar dışardan daha kolay gözlemlenebilen etkenlere bağlıdır. Bu açıklamanın biraz eğreti olduğunun farkındayız. Cengiz'in mental ve fiziksel başlığı altındaki durumlarının ortak bir fizyolojik yapıya dayandığının bilincindeyiz ancak burada doğru bilinen yanlışları takip edip bu durumları iki temel başlık altında kullandık.",
    },
    {
      question:"Durumlar: Fiziksel",
      answer:"Cengiz'in yaşadığı fiziksel durumları kısa vadeli ve uzun vadeli olmak üzere ikiye ayırdık. Uzun vadeli zorluklar genelde ağrılarla ortaya çıkar ve gerekli önlemlerin alınmaması durumunda daha da şiddetlenir. Bu ağrılar Cengiz'in beş farklı organında görülebilir ve hepsinin sebepleri ve çözümleri farklıdır. Kısa vadeli durumlara gelecek olursak, uykusuzluk, yorgunluk, sussuzluk, açlık ve dehidrasyon gibi kısa vadede gelişen şikayetlerle karşılaşabilirsiniz. Bunların çözümü çok daha kısa ve kolayken uzun vadeli şikayetlerin sebepleri bir süredir sıragelen alışkanlıklar olduğundan iyileşme süreçleri de aynı şekilde uzun ve zor geçecektir.      "
    },
    {
      question:"Durumlar: Mental",
      answer:"Cengiz'in yaşadığı mental durumlar, fizikel olanlara göre oldukça karmaşıktır. Bunun temel sebebi Cengiz'in size verdiği ipuçlarının eksik oluşudur. Çünkü hepimiz gibi Cengiz de nörotransmiterlerindeki patlamaların etkisinde amigdalasının himayesinde bir hayat sürmekteyken genelde yaşadığı duygu değişimlerinin farkına varmaz. Cengiz'in bu değişimlere daha iyi tepki vermesini sağlamak ve hormon dengelerini gözlemleyebilmek için bilgi dağarcığını geliştirmelisiniz. Daha önce de bahsettiğimiz gibi bunu yapabilmeniz için belirli aktiviteler var. Beyninizin %100'ünü kullanmaya kadar giden bu yolda okumaktan vazgeçmeyin ancak okurken çok farklı kaynaklar kullanırsanız, Cengiz'i 'Kafası Karışık' durumunda bulabilirsiniz! Diğer durumlardan bahsedecek olursak, karşınıza şunlar çıkabilir; Mutlu, Sıkılmış, Gergin, Endişeli, Depresif, Borderline ve Insomnia. Beyninizin büyük bir kısmını kullanmaya başldığınızda hangi hormonlarınızın düşüklüğünde hangi durumun yaşandığını takip ederek aktivitelerinizi bunlara göre dağıtmayı düşünebilirsiniz."
    },
  ];

  if (!faqs || faqs.length === 0) faqs = defaultFaqs;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <Content>
        <TwoColumn>
          {/* <Column tw="hidden lg:block w-5/12 flex-shrink-0">
            <Image imageContain={imageContain} imageShadow={imageShadow} imageSrc={imageSrc} />
          </Column> */}
          <Column>
            <FAQContent>
              {subheading ? <Subheading>{subheading}</Subheading> : null}
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <FAQSContainer>
                {faqs.map((faq, index) => (
                  <FAQ
                    key={index}
                    onClick={() => {
                      toggleQuestion(index);
                    }}
                    className="group"
                  >
                    <Question>
                      <QuestionText>{faq.question}</QuestionText>
                      <QuestionToggleIcon>
                        {activeQuestionIndex === index ? <MinusIcon /> : <PlusIcon />}
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                        collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                      }}
                      initial="collapsed"
                      animate={activeQuestionIndex === index ? "open" : "collapsed"}
                      transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                      {faq.answer}
                    </Answer>
                  </FAQ>
                ))}
              </FAQSContainer>
            </FAQContent>
          </Column>
        </TwoColumn>
      </Content>
    </Container>
  );
};
