import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/tr/Hero2024";
import Footer from "components/tr/Footer";
import { SectionHeading } from "components/misc/Headings";
import { Helmet } from "react-helmet-async";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-400 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-300`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Gizlilik Politikası" }) => {
  return (
    <div>
       <Helmet>
        <title>Gizlilik Politikası | RASITGR Games</title>
        <meta
          name="description"
          content="RASITGR Games olarak kullanıcıların kişisel verilerini toplamıyoruz ve paylaşmıyoruz. Gizliliğinize önem veriyoruz."
        />
        <meta name="keywords" content="Gizlilik politikası, kişisel veriler, RASITGR Games" />
        <meta property="og:title" content="Gizlilik Politikası | RASITGR Games" />
        <meta property="og:description" content="Kişisel verilerinizi toplamıyor ve paylaşmıyoruz." />
      </Helmet>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Son güncelleme: 14 Eylül 2024</p>

            <p>
            Bu Gizlilik Politikası, RASITGR Games’in ("Şirket", "Biz" veya "Bizim" olarak anılacaktır) https://rasitgr.com adresinde gizlilikle ilgili konulara nasıl yaklaştığını açıklar. Gizliliğe olan bağlılığımızın bir parçası olarak, sitemizi ziyaret eden kullanıcıların kişisel verilerini toplamadığımızı, saklamadığımızı veya paylaşmadığımızı belirtmek isteriz.
            </p>    

            <h1>Yorum ve Tanımlar</h1>
            <h2>Yorum</h2>
            <p>
            Baş harfi büyük olan kelimeler, aşağıdaki koşullar altında tanımlanan anlamlara sahiptir. 
            </p>
            <p>
            Aşağıdaki tanımlar, tekil veya çoğul olarak kullanılsalar da aynı anlama sahiptir.
            </p>

            <h2>Tanımlar</h2>
            <p>Bu Gizlilik Politikası kapsamında:</p>
            <ul>
              <li>
                <p>
                  <strong>Sen</strong>, Hizmete erişen veya Hizmeti kullanan birey ya da bu bireyin adına Hizmete erişen veya onu kullanan şirket veya diğer tüzel kişi anlamına gelir.
                </p>
              </li>
              <li>
                <p>
                  <strong>Şirket</strong>, Alp Kurt'a ait RASITGR Games'i ifade eder.
                </p>
              </li>
              <li>
                <strong>Web Sitesi</strong>, https://rasitgr.com adresinden erişilebilen RASITGR Games'i ifade eder.
              </li>{" "}
              <li>
                <strong>Hizmet</strong>, Web Sitesini ifade eder.
              </li>
            </ul>

            <h2>Topladığımız Bilgiler</h2>
<p>Sitemizi ziyaret ettiğinizde aşağıdaki bilgileri toplayabiliriz:</p>
<ul>
  <li>
    <p><strong>Kullanım Verileri</strong>: Kullanım Verileri, web sitemizle etkileşimde bulunduğunuzda otomatik olarak toplanır. Bu veriler IP adresiniz, tarayıcı türünüz, tarayıcı sürümünüz, ziyaret ettiğiniz Hizmet sayfaları, ziyaretinizin tarihi ve saati, bu sayfalarda geçirdiğiniz süre ve diğer tanılama verilerini içerebilir. Kullanım Verileri, istatistiksel amaçlarla ve hizmetlerimizi iyileştirmek için toplanır.</p>
  </li>
</ul>

<h2>Google Analytics</h2>
<p>
  Web sitesi trafiğini ve kullanıcı davranışını izlemek ve analiz etmek için Google Analytics kullanıyoruz. Google Analytics, bilgileri anonim olarak toplar ve bireysel ziyaretçileri tanımlamadan web sitesi eğilimlerini raporlar. Bu, kullanıcıların web sitemizle nasıl etkileşime girdiğini anlamamıza ve kullanıcı deneyimini iyileştirmemize yardımcı olur.
</p>
<p>
  Google Analytics'ten, Google Analytics devre dışı bırakma tarayıcı eklentisini yükleyerek çıkabilirsiniz.
</p>
<p>
  Google’ın verilerinizi nasıl kullandığı hakkında daha fazla bilgi için lütfen gizlilik politikalarına bakın: <a href="https://policies.google.com/privacy">Google Gizlilik Politikası</a>.
</p>

<h1>Çerezler ve Takip</h1>
<p>
  Kişiselleştirilmiş izleme için çerez kullanmıyoruz. Ancak, Google Analytics, kullanım verilerini toplamak ve analiz etmek için çerezleri kullanabilir. Çerezleri yönetmek ve takibi kontrol etmek veya engellemek için tarayıcı ayarlarınızı kullanabilirsiniz.
</p>

            <h1>Diğer Web Sitelerine Bağlantılar</h1>
            <p>
            Hizmetimiz, Bizim tarafımızdan işletilmeyen üçüncü taraf web sitelerine veya hizmetlerine bağlantılar içerebilir. Üçüncü taraf bağlantısına tıkladığınızda, sizi o üçüncü tarafın sitesine yönlendirecektir. Ziyaret ettiğiniz her sitenin Gizlilik Politikasını incelemenizi tavsiye ederiz.
            </p>
            <p>
              Üçüncü taraf sitelerin veya hizmetlerin içerikleri, gizlilik politikaları veya uygulamaları üzerinde hiçbir kontrolümüz yoktur ve sorumluluk kabul etmiyoruz.
            </p>

            <h1>Gizlilik Politikasındaki Değişiklikler</h1>
            <p>
              Gizlilik Politikamızı zaman zaman güncelleyebiliriz. Yeni Gizlilik Politikasını bu sayfada yayınlayarak herhangi bir değişiklikten sizi haberdar edeceğiz.
            </p>
            <p>
              Bu Gizlilik Politikasını periyodik olarak gözden geçirmeniz önerilir. Bu Gizlilik Politikasındaki değişiklikler, bu sayfada yayınlandığında geçerli olacaktır.
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </div>
  );
};
