import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import Modal from "react-modal";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDy4HFA7G2ctCOqM_kZ_ufk20c2EwsB_OE",

    authDomain: "rasitgr-58485.firebaseapp.com",
  
    projectId: "rasitgr-58485",
  
    storageBucket: "rasitgr-58485.appspot.com",
  
    messagingSenderId: "1025403859562",
  
    appId: "1:1025403859562:web:98a2d941f47f54ef9ad013",
  
    measurementId: "G-K7LS3TWGX4"
  
  };
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics (if you're using it)
const analytics = getAnalytics(app);

Modal.setAppElement("#root");

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App  />);