import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import HeroSecondary from "./HeroSecondary.js";
import Hero from './Hero';
import InformativeFeature from "./InformativeFeature";
import MainFeature from "./Feature.js";
import MainFeature2 from "./Features-2";
import FeatureWithSteps from "./FeatureWithSteps";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "./Testimonial.js";
import FAQ from "./SingleCol.js";
import GetStarted from "./GetStarted";
import Footer from "components/en/Footer.js";
import heroScreenshotImageSrc from "images/happy-illustration.svg";
import macHeroScreenshotImageSrc from "images/signup-illustration.svg";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import Rules from "./Rules"
import Ergun from 'images/rasitgr/horsbtcher-min.jpg';
import Memet from 'images/rasitgr/ridgeHog-min.jpg';
import Models from 'images/rasitgr/cdw-models.png'
import Mockup from 'images/rasitgr/cdw-mockup.jpg';
import Team from './Team';
import Testers from './Testers'
import { Helmet } from "react-helmet-async";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-green-500`;
  const HighlightedText = tw.span`text-green-500`;

  return (
    
    <>
    <Helmet>
  <title>Crazy Demagogy Wars | Political Card Game | RASITGR Games</title>
  <meta
    name="description"
    content="Crazy Demagogy Wars is a fast-paced, politically-themed set collection card game for 3 to 6 players. Experience an exciting and colorful game."
  />
  <meta name="keywords" content="Crazy Demagogy Wars, political card game, card game, RASITGR Games" />
  <meta property="og:title" content="Crazy Demagogy Wars | Political Card Game" />
  <meta property="og:description" content="Enjoy a fast-paced and colorful political card game." />
</Helmet>

    <Hero />
       <HeroSecondary roundedHeaderButton={true} />
     
       <InformativeFeature
  subheading={<Subheading>What Is that</Subheading>}
  heading={
    <>
      Crazy Demagogy Wars?
    </>
  }
  description={
    "Crazy Demagogy Wars is a politically themed set collection card game for 3 to 6 players, with each round lasting between 10 to 15 minutes."
  }
  id='video'
/>

     
    
     <FeatureWithSteps
  subheading={<Subheading>STEP BY STEP</Subheading>}
  heading={
    <>
      <HighlightedText>Simplified</HighlightedText> Usage
    </>
  }
  textOnLeft={false}
  imageSrc={Mockup}
  imageAlt="Crazy Demagogy Wars Cards and Box Mockup"
  imageDecoratorBlob={true}
  decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
/>

     <MainFeature
  subheading={<Subheading>DesIgned to</Subheading>}
  imageSrc={Models}
  imageAlt="Crazy Demagogy Wars Characters Illustration"
  imageBorder={true}
  imageDecoratorBlob={true}
  description={
    "Tired of the monotonous and repetitive nature of similar card games on the market, we developed a cool game just for ourselves to play. And what did we do next? We went into mass production and shared Crazy Demagogy Wars with everyone."
  }
/>


      <Team id="team" />
      <Testers id="testers" />

      <Testimonial
  subheading={<Subheading>Testers</Subheading>}
  imageAlt="Çılgın Algı Savaşları Box Mockup"
  heading={
    <>
      They <HighlightedText>Loved</HighlightedText> Crazy Demagogy Wars
    </>
  }
  description={
    "They tried Crazy Demagogy Wars. At first, they were confused, but by the second game, they loved it. By the third game, everyone was fully immersed!"
  }
  testimonials={[
    {
      stars: 1,
      profileImageSrc: Memet,
      heading: "Epic battle",
      quote:
        "I gave it 1 star so my review would appear first. The game is great, but it has the kind of competitive struggle that can strain friendships. Turns out I’m the fastest to finish the game, that’s just my style.",
      customerName: "Mehmet Eren 'RidgeHog'",
      customerTitle: "Awarded Tester for The Fastest Win"
    },
    {
      stars: 4,
      profileImageSrc: Ergun,
      heading: "Great design, deducted 1 point for the rhymes",
      quote:
        "I always win the game... At first, it made me feel special, but then it became normal. The game's balance is great, but I might just disrupt it soon...",
      customerName: "Ergün 'HorsButcher'",
      customerTitle: "Game Developer"
    }
  ]}
/>

      <div id="rules" ></div>
     <GetStarted/>

        <MainFeature2  id="quick-guide"/>

      <Rules id="rules" />

      <FAQ id="faq"
  subheading={<Subheading>FAQ</Subheading>}
  heading={
    <>
      Frequently Asked <HighlightedText>Questions</HighlightedText>
    </>
  }
  faqs={[
    {id: 0, title: "It's my turn, what should I do?", description: ["When it's your turn, start by drawing 2 cards from the deck in the middle.", "Then, you can divide the cards in your hand into 2 groups. One group for Community and Partisan cards, and another group for Political and Propaganda cards.", "Each turn, you have the right to play 4 cards, 2 from each group. *If you have accumulated too many cards from one group, you can play up to 3 cards from one group only. *If you wish, you can pass without playing any cards."]},
    {id: 1, title: "How can I form a Community set?", description: ["Each Community card has its own color and number. Some communities can be completed with 2 cards, while others require 4.", "To complete a Community set, you must place the required number of cards for that community in front of you. Even if you have all the necessary cards in your hand, they don't count as a set until they are placed.", "You can use Partisan cards to complete a Community set. You need to find an Partisan card of the matching color or use the 'Spineless' partisan card that can match any color.","There are two important points to keep in mind when completing sets; *Completed sets cannot be broken by you. *Sets made entirely of Partisan cards are not valid."]},
    {id: 2, title: "How do I calculate my Voting Power?", description: ["The value of the sets you complete determines your Voting Power. For example, if you complete a 2-piece blue set, the Propaganda Power of that set is 4, so your Voting Power will also be 4. *Since this set consists of 2 pieces, you do not gain 8 Voting Power [(Set Number) x (Propaganda Power)]. The Voting Power you get is only equal to the Propaganda Power of the set, regardless of the number of cards. In this example, completing the Blue set gives you 4 Voting Power."]},
    {id: 3, title: "How do I use the Principle Deviation card?", description: ["The Principle Deviation card allows you to swap one of the Community cards in front of another player with one of your own Community cards.", "You cannot use Partisan cards in this swap."]},
    {id: 4, title: "How do I hold a Rally?", description: ["To hold a Rally, you must first have a Community card in your field. If you play a Rally card that matches the color of your Community card, you can demand PG from your opponents equal to the Rally Power of your Community card.", "As the number of Community cards increases, their Rally Power also increases.", "When holding a Rally, you can combine other political cards and execute a wild policy to create big influence."]},
    {id: 5, title: "How do I use an Partisan card?", description: ["Partisan cards complement Community cards of the same color.", "You can place them in the field alone or attach them under a matching Community card.", "One thing to keep in mind when using an Partisan card is that you will need to spend one move to change its position."]},
    {id: 6, title: "How do I use a Defamation card?", description: ["There are two types of Defamation cards. Those played on Community cards and those played on Propaganda Accumulation.", "Defamation cards played on a Community set reduce the Voting Power of that set. How much it reduces is stated on the card.", "Defamation cards played on Propaganda Accumulation neutralize all the cards they are played on until they are cleared.", "You can also use a Defamation card to block an opponent's victory by playing it just before they win."]},
    {id: 7, title: "Can two Defamation cards be played on the same set?", description: ["Yes, multiple Defamation cards can be played on the same set."]},
    {id: 8, title: "Can a set's Voting Power drop below zero with a Defamation card?", description: ["No, a set's Voting Power cannot fall below zero."]},
    {id: 9, title: "How do I use the Pure Milk Spilling card?", description: ["You can play the Pure Milk Spilling card on your turn like other Political cards.", "You can destroy any Defamation card, including those played on your opponents."]},
    {id: 10, title: "How do I use the Ayran Spilling card?", description: ["The Ayran Spilling card can be used to destroy a card from your opponent's Propaganda Accumulation just like other Political cards.", "You can pick any card from your opponent's Propaganda Accumulation deck.", "You do not have to show this deck to the other players."]},
    {id: 11, title: "Can I destroy two Defamation cards with one Pure Milk Spilling card?", description: ["No, each Pure Milk Spilling card can only destroy 1 Defamation card."]},
    {id: 12, title: "How do I use the Populism Wave card?", description: ["The Populism Wave card can only be used during a Rally as a one-time use.", "After using the card, discard it."]},
    {id: 13, title: "How do I use the Pool Media card?", description: ["The Pool Media card can only be used during a Rally as a one-time use.", "After using the card, discard it."]},
    {id: 14, title: "Can I use both the Populism Wave and Pool Media cards in the same Rally?", description: ["Yes, you can combine these two cards in any of your Rallies.", "When calculating, first add the bonus from the Populism Wave to your Rally Power, and then use the Pool Media bonus. [e.g., 2(1RP + 4RP)]"]},
    {id: 15, title: "How do I play the Refutation card?", description: ["The Refutation card, unlike other cards, can be played from your hand even when it’s not your turn.", "The Refutation card can be used against a political card or a combination of political cards."]},
    {id: 16, title: "Can I block a Defamation card with a Refutation card?", description: ["No, the Refutation card is ineffective against Defamation cards."]},
    {id: 17, title: "Can Refutation cards be played against each other?", description: ["Yes. You can use a Refutation card when you want to reject the sanctions of a political card. However, your opponent can also Refute your Refutation. The player who plays the last Refutation card wins the duel."]},
    {id: 18, title: "How do I play the Separation of Powers Trilogy card?", description: ["Discard the Separation of Powers Trilogy card, draw two cards from the deck, reset your move count, and play again."]},
    {id: 19, title: "How do I play the Conjuncture Eclipse card?", description: ["You can use the Conjuncture Eclipse card to discard all of a player’s Propaganda Accumulation."]},
    {id: 20, title: "How do I play the Puppet Mastery card?", description: ["You can place the Puppet Mastery card on top of your opponent's completed sets to share their Voting Power.", "If the set is broken, the Puppet Mastery card falls and is discarded."]},
    {id: 21, title: "How do I play the Bourgeois Sympathy card?", description: ["If you don't like the cards in your hand, you can use this card to reset your deck. Discard the Bourgeois Sympathy card along with your hand and draw 5 new cards."]},
    {id: 22, title: "How do I play the Leadership Trait and Surveyor Leaning cards?", description: ["The Leadership Trait and Surveyor Leaning cards act like Community cards in your field.", "These cards act as sets on their own and provide the owning player +1 or -1 Voting Power.", "You can place the Surveyor Leaning card in an opponent’s field."]},
    {id: 23, title: "Can I break an opponent's set with the Nepotism Twist, Principle Deviation, or Origin Shift cards?", description: ["No, you cannot use these cards on completed sets."]},
    {id: 24, title: "How do I use the Apolitical Wave card?", description: ["The Apolitical Wave card allows you to take one Community card from each player’s field. However, you cannot break completed sets or take Partisan cards.", "You can choose the cards you want to take."]},
    {id: 25, title: "How do I use the Totalitarian Corruption card?", description: ["The Totalitarian Corruption card allows you to take two Community cards from each player’s field. However, you cannot break completed sets or take Partisan cards.", "Your opponents will choose which cards to give you."]},
    {id: 26, title: "I am poisoned by Power Poisoning. Can I draw a card from the deck even if I don't play this turn?", description: ["No, you cannot even play a Refutation card during this turn.", "Your poison won’t wear off until the player who poisoned you plays again."]},
    {id: 27, title: "How do I use the Bone Bargaining card?", description: ["The Bone Bargaining card is used to swap completed sets."]},
    {id: 28, title: "Can I play the Bone Outgrowth, Bone Bargaining, or Unstamped Votes cards on incomplete sets?", description: ["No, you can only play these cards on completed sets."]},
    {id: 29, title: "Can I take back cards I’ve already played?", description: ["No, you cannot take back cards that have been played."]},
    {id: 30, title: "Can I make a payment with a Community or Partisan card?", description: ["Yes, you can make a payment this way, but you cannot break a set to make a payment. You can only do so if you have no other options and no other cards in the field."]},
    {id: 31, title: "An opponent demanded PG from me, but I have no cards in my field. What should I do?", description: ["If an opponent demands PG from you and you have no cards in your field, you are not obligated to make a payment. You are not responsible for your opponent’s strategic mistake or disadvantage."]},
    {id: 32, title: "I want to move one of my Partisan cards. What should I do?", description: ["You must spend one move to change the position of an Partisan card.", "To move an Partisan card belonging to a set, you must replace it with another card that fits the set without breaking the set."]},
    {id: 33, title: "I completed a set using Partisan cards, but now I want to replace the Partisan card with a Community card from my hand and use the Partisan card in another set. What should I do?", description: ["In this case, if your set is completed, you must place a different card that fits the set to free the Partisan card. This way, the Partisan card is freed but does not return to your hand.", "If you want to place the Partisan card in another set, you will have to spend one of your moves for this action."]},
    {id: 34, title: "Can I complete a set with only Partisan cards?", description: ["No, you need at least 1 Community card to complete a set."]},
    {id: 35, title: "A set that was defamed has been broken. What happens to the Defamation card?", description: ["In these cases, the Defamation card is discarded."]},
    {id: 36, title: "Can I use a Flying Partisan card to break my opponent's completed set?", description: ["Flying Partisan cards cannot be used to break completed sets; they can only be used on independent and incomplete Partisan cards."]},
    {id: 37, title: "How do I use the Activist Salutation card?", description: ["The Activist Salutation card is used to cancel an ongoing Rally. You can play it from your hand while your opponent is holding a Rally.", "When using Activist Salutation, all cards involved in the Rally, such as Pool Media and Populism Wave, are also canceled and discarded."]},
    {id: 38, title: "How do I use the Conflict of Interest card?", description: ["The Conflict of Interest card is used to remove any Partisan card from your opponents’ fields."]},
    {id: 39, title: "How do I use the Plunderer’s Trick card?", description: ["The Plunderer’s Trick card allows you to take PG equal to the total number of Community and Partisan cards in front of each player."]},
    {id: 40, title: "How do I use the Power Poisoning card?", description: ["When you place the Power Poisoning card in front of another player, you prevent them from playing until your turn comes again.", "If a Power Poisoning card is played on you at the same time, the player you poisoned will also be unable to play until you play again."]},
    {id: 41, title: "What does the Proletariat Empathy card do?", description: ["The Proletariat Empathy card allows you to discard all the cards in an opponent’s hand."]},
    {id: 42, title: "What does the Hammer’s Return card do?", description: ["The Hammer’s Return card allows you to take any Partisan card from your opponents' fields. You cannot break completed sets."]},
    {id: 43, title: "What does the Artist’s Output card do?", description: ["When you place the Artist’s Output card on top of an opponent’s Community set, it prevents that set from being used in a Rally."]},
    {id: 44, title: "What does the Political Intermediary card do?", description: ["The Political Intermediary card forces every player to pass their hand to the player next to them. Players with no cards are not required to pass anything."]}
  ]}
/>


          <Footer />
   
    
    </>
  );
}
