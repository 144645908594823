import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings";
import Hero from 'components/en/Hero2024';
import Footer from 'components/en/Footer';
import { Helmet } from "react-helmet-async";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-400 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-300`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Legal Information for Çılgın Algı Savaşları" }) => {
  return (
    <div>
      <Helmet>
  <title>Crazy Demagogy Wars | Legal Information | RASITGR Games</title>
  <meta
    name="description"
    content="Legal information about Crazy Demagogy Wars, including privacy policy, terms and conditions, and disclaimer."
  />
  <meta name="keywords" content="Crazy Demagogy Wars, legal information, privacy policy, terms, RASITGR Games" />
  <meta property="og:title" content="Crazy Demagogy Wars | Legal Information" />
  <meta property="og:description" content="Privacy policy, terms and conditions, and legal disclaimer for Crazy Demagogy Wars." />
</Helmet>

      <Hero/>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>


            <p>Last updated: Sep 14, 2024</p>

            <p>Welcome to RASITGR Games. Below are the legal terms and conditions that apply to the use of our card game Crazy Demagogy Wars (Çılgın Algı Savaşları). By playing our game, you agree to these terms.</p>
            <br></br>
            <hr></hr>
            <h1>Privacy Policy</h1>
            <p>RASITGR Games respects your privacy and is committed to protecting any personal data that may be shared with us. However, we do not collect or store any personal data from our customers.</p>
            <h2>Personal Data Collection</h2>
            <p>We do not collect, store, or process any personal information, as the sales process is managed through third-party platforms such as Shopier and Trendyol.com. Any personal information you provide is managed directly by these platforms in accordance with their own privacy policies.</p>
            <h2>External Links</h2>
            <p>Our website may contain links to other websites such as Shopier.com and Trendyol.com. We are not responsible for the content or privacy practices of these external websites. Please refer to their respective privacy policies for more information.</p>
            <p>If you have any questions regarding this Privacy Policy, please contact us at: info@rasitgr.com or info@lupittus.com</p>
            
            <br></br>
            <hr></hr>

            <h1>Terms And Conditions</h1>
            <p>By purchasing Çılgın Algı Savaşları, developed by LUPI and sold under RASITGR Games, you agree to the following terms and conditions:</p>
            <ul>
              <li>Seller: The game is sold under RASITGR Games and developed by LUPI, both owned by Alp Kurt.</li>
              <li>Sale Platforms: Our products are available through third-party platforms such as Shopier and Trendyol.com. We do not directly manage the payment process.</li>
            </ul>
            <h2>Intellectual Property</h2>
            <p>The game Çılgın Algı Savaşları is a trademark registered in Turkey. All game-related assets, including the logo and design, are the intellectual property of RASITGR Games and LUPI.</p>
           <h2>Shipping And Delivery</h2>
           <ul>
            <li>Shipping Regions: We only ship within Turkey.</li>
            <li>Delivery Times: Delivery times are estimated to be shorter than a week but may vary depending on the delivery partner. Estimated times are stated on the respective shop pages.</li>
           <li>Delivery Partner: Our delivery partners may vary, and customers will be notified of the shipping method at the time of purchase.</li>
           </ul>
           <h2>Returns And Refunds</h2>
           <p>Our Return and Refund Policy follows Turkish law. Customers are eligible for a refund if the product is defective. Return shipping costs are determined by Turkish law. Please refer to the platform you purchased the product from for specific return processes.</p>
           <h2>Limitations Of Liability</h2>
           <p>There are no limitations on liability beyond those required by Turkish law.</p>
           <p> If you have any questions regarding these Terms and Conditions, please contact us at: info@rasitgr.com or info@lupittus.com</p>
           <br></br>
          <hr></hr>

            <h1>Return & Refund Policy</h1>
            <p>
            Our Return and Refund Policy complies with Turkish law, and we strive to ensure customer satisfaction.
            </p>
            <h2>Eligibility For Returns</h2>
            <ul>
              <li>Customers may request a refund if the product is defective.</li>
              <li>Returns and refunds must follow the process outlined by the platform through which the purchase was made (Shopier or Trendyol.com).</li>
            </ul>
            <h2>Return Shipping</h2>
            <ul>
              <li>The party responsible for covering return shipping costs is determined by Turkish law.</li>
              <li>If you believe your product is defective and eligible for a return, please refer to the return process outlined on the Shopier or Trendyol.com platform.</li>
            </ul>
             <p>
             For any questions or issues regarding your purchase, you can contact us at: info@rasitgr.com or info@lupittus.com
            </p>
            <br></br>
            <hr></hr>
          
            <h1>Shipping Policy</h1>
            <p>
            We strive to provide quick and efficient shipping services for all customers within Turkey.
            </p>
            <h2>Shipping Regions</h2>
            <p>We currently ship only within Turkey.</p>
            <h2>Delivery Times</h2>
            <ul>
              <li>Estimated delivery times are shorter than one week but may vary depending on the delivery partner.</li>
              <li>The estimated delivery time will be stated on the specific product page of the platform (Shopier or Trendyol.com) where the game is purchased.</li>
            </ul>
            <h2>Delivery Partners</h2>
            <ul>
              <li>Our delivery partners may vary, depending on the region and availability.</li>
              <li>Customers will be notified of the specific delivery method upon purchase.</li>
            </ul>
            <p>If you have any questions about shipping, please contact us at: info@rasitgr.com or info@lupittus.com</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </div>
  );
};
