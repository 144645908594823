import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-green-900 text-gray-100 `;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-gray-300`;

const FaqsContainer = tw.div`mt-10 sm:mt-16 w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto`;
const FaqsColumn = tw.div`w-full lg:max-w-lg lg:mr-12 last:mr-0`;
const Faq = tw.div`select-none cursor-pointer border-b-2 border-green-300 hover:border-green-500 transition-colors duration-300 py-6`;
const Question = tw.div`flex justify-between items-center`;
const QuestionText = tw.div`text-sm sm:text-lg font-semibold tracking-wide`;
const QuestionToggleIcon = styled(motion.span)`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Answer = tw(motion.div)`hidden text-sm font-normal mt-4 text-gray-300`;

export default ({
  subheading = "Our Mission is",
  heading = "Bringing Passion to Play",
  description = "We believe games are more than just entertainment; they’re a way to tell stories, express creativity, and build community. Our goal at RASITGR Games is to support and develop original indie titles that stand out in today’s gaming landscape.",
  faqs = [
    {
      question: "Our Vision",
      answer:
        "At RASITGR Games, we are dedicated to creating original, bespoke games. Rather than following a model of mass production, we meticulously craft each element in-house, ensuring every project provides a truly unique and immersive experience."
    },
    {
      question: "Our Goal",
      answer:
        "Our ultimate aspiration is to sustain ourselves by developing games. We thrive on unpredictability and innovation, with each project introducing a new narrative where the player takes center stage. Even when ideas seem scarce, we’ll turn that very challenge into a game. Our passion for game development knows no bounds."
    },
    {
      question: "What Sets Us Apart",
      answer:
        "We design games with the intention of playing them ourselves first. Our focus isn't on maximizing profit, but rather on the pure joy of gameplay. We believe that games built out of a genuine love for play are inherently more authentic and enjoyable."
    },
    {
      question: "Our Unique Approach",
      answer:
        "From concept to launch, every aspect of our games is produced and managed internally. We neither outsource services nor rely on external funding, learning and evolving as a team to remain true to our vision and values."
    },
    {
      question: "Our Only Concern",
      answer:
        "Our mission is to entertain, engage, and at times, provoke thoughtful reflection, all while steering clear of repetitive ideas. With each project, we prioritize innovation, exploring new concepts and themes rather than adhering to formulaic approaches."
    },
    {
      question: "Sustainability",
      answer:
        "We approach game development holistically—dreaming, planning, designing, playtesting, and critiquing every detail. Our pricing is designed to be budget-friendly, ensuring accessibility while covering the essential costs for future projects. By blending modern development techniques with sustainable practices, we ensure that our games are both innovative and enduring.",
    }
  ]
}) => {
  const faqCol1 = [];
  const faqCol2 = [];
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  faqs.map((faq, index) => {
    const renderedFaq = (
      <Faq key={index} onClick={() => toggleQuestion(index)}>
        <Question>
          <QuestionText>{faq.question}</QuestionText>
          <QuestionToggleIcon
            variants={{
              collapsed: { rotate: 0 },
              open: { rotate: -180 }
            }}
            initial="collapsed"
            animate={activeQuestionIndex === index ? "open" : "collapsed"}
            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ChevronDownIcon />
          </QuestionToggleIcon>
        </Question>
        <Answer
          variants={{
            open: { opacity: 1, height: "auto", marginTop: "16px", display: "block" },
            collapsed: { opacity: 0, height: 0, marginTop: "0px", display: "none" }
          }}
          initial="collapsed"
          animate={activeQuestionIndex === index ? "open" : "collapsed"}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {faq.answer}
        </Answer>
      </Faq>
    );

    if (index % 2 === 0) faqCol1.push(renderedFaq);
    else faqCol2.push(renderedFaq);

    return null;
  });
  return (
    <PrimaryBackgroundContainer>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
        </HeadingContainer>
        <FaqsContainer>
          <FaqsColumn>{faqCol1}</FaqsColumn>
          <FaqsColumn>{faqCol2}</FaqsColumn>
        </FaqsContainer>
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
};
