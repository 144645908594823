import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import rasitgrStory from 'images/rasitgr/rasitgr-story.png';
import rasitgrMission from 'images/rasitgr/rasitgr-mission.png';
import rasitgrProcess from 'images/rasitgr/rasitgr-process.png';

const Container = tw.div`relative bg-gray-900 -mx-8 px-8`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-200 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-red-700`;
const Description = tw.p`mt-2 text-lg leading-loose text-gray-400`;
const Link = tw.a`inline-block mt-4 text-lg text-green-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-green-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-green-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-green-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-green-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-green-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc: rasitgrStory,
      subtitle: "Ücretli",
      title: "Bizim Hikayemiz",
      description:
        "2019 yılında, oynamak istediğimiz türden oyunlar yaratma hayalini paylaşan tutkulu bir oyuncu grubu olarak başladık. Amasya, Türkiye merkezli olarak, bu hayali gerçekleştirmek için ilk adımları atmamıza yardımcı olan bir fon sağladık. Küçük, özverili bir oyuncu ekibi olarak başladığımız yolculuk, zamanla uluslararası bir geliştirici, sanatçı ve tasarımcı grubuna dönüştü. Lupi Studios'ta oyunlarımızı yaratıcılık ve yenilik ön planda tutarak geliştiriyoruz ve projelerimizi tamamladıktan sonra, RASITGR Games, bu çalışmaları dünya genelindeki oyunculara ulaştırıyor.",
      url: "https://timerse.com",
    },
  
    {
      imageSrc: rasitgrMission,
      subtitle: "Ücretsiz",
      title: "Misyonumuz",
      description:
        "Adandık: Küresel İşbirliği: Ekibimiz, dünyanın farklı yerlerinden yetenekli bireylerden oluşur ve uzaktan çalışarak benzersiz oyunlar geliştirir. Oyun Tasarımında Yenilik: Oyun geliştirme normlarına meydan okuma amacımız var ve her zaman yeni ve heyecan verici bir şeyler sunmaya çalışıyoruz. Kalite, Niceliğin Önünde: Seri üretim yerine, özgün ve yüksek kaliteli oyunlar yaratmaya odaklanıyoruz.",
      url: "https://timerse.com",
    },
  
    {
      imageSrc: rasitgrProcess,
      subtitle: "Özel",
      title: "Sürecimiz",
      description:
        "Oyunlarımız, Lupi Studios'ta uluslararası bir ekip tarafından uzaktan geliştiriliyor, bu da bize geniş bir perspektif, beceri ve yaratıcı fikirler havuzuna erişim sağlıyor. Her proje, fiziksel mesafeye rağmen ekip üyelerinin yakın işbirliğiyle geliştiriliyor. Bir proje hazır olduğunda, RASITGR Games onu yayınlıyor ve yaratıcı çalışmalarımızı küresel bir kitleye ulaştırıyor.",
      url: "https://timerse.com",
    },
  ];
  

  return (
    <Container>
      <SingleColumn>
     

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                {/* <Subtitle>{card.subtitle}</Subtitle> */}
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                {/* <Link href={card.url}>See Event Details</Link> */}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
