import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";

const Container = tw.div`relative -mx-8 px-8 bg-gray-900`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const TwoColumn = tw.div`flex`;
const Column = tw.div``;

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
  props.imageShadow ? tw`shadow` : tw`shadow-none`,
  tw`hidden lg:block rounded h-144 bg-center`
]);

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-left`;
const Heading = tw(SectionHeading)`lg:text-left text-gray-400`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-gray-300`;

const FAQSContainer = tw.dl`mt-12`;
const FAQ = tw.div`cursor-pointer mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center text-gray-300`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold text-gray-400`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-green-500 text-gray-100 p-1 rounded-full group-hover:bg-green-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed text-gray-400`);

export default ({
  subheading = "",
  heading = "Rules",
  description = "During countless test games, we realized we needed to establish some rules to keep us from fighting each other.",
  imageSrc = "https://images.unsplash.com/photo-1579427421635-a0015b804b2e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
  imageContain = false,
  imageShadow = true,
  faqs = null
}) => {
  /*
   * You can modify FAQs either by modifying the below defaultFaqs array or by passing a custom array of FAQs using
   * the faqs prop
   */
  const defaultFaqs = [
    {
      question: "Sets cannot be completed with only Supporter cards",
      answer:
        "To complete a set, there must be at least one Community card. Sets made entirely of Supporter cards are not valid."
    },
    {
      question: "Completed sets cannot be broken",
      answer:
        "Breaking a set should be the last resort for payment. If a player has any other cards with PG (Propaganda Power), even they cannot break their own completed set. Political cards targeting Supporter cards cannot be used against completed sets, meaning completed sets cannot be broken with these cards either. You can only replace Supporter cards with the appropriate set cards, freeing the Supporter card."
    },
    {
      question: "Political cards can be converted into Propaganda Power",
      answer:
        "All Political cards, identified by the green stripe on the inside, can be converted into Propaganda Power. Once converted, they cannot be turned back into political cards during the round."
    },
    {
      question: "No change is given",
      answer:
        "Keep in mind that no change is given when making a payment with PG. For example, even if you owe 1PG, you are required to pay with your lowest PG card. You cannot ask for change."
    },
    {
      question: "Payments can be made with Community cards",
      answer:
        "Players can use their Community cards for payment if they have no other cards or if they choose to do so. In this case, the Propaganda Power of the Community cards is used instead."
    },
    {
      question: "A player with no cards doesn't make a payment",
      answer:
        "If players do not have any cards with PG in front of them, they are not required to make a payment. Hand payments are not allowed."
    },
    {
      question: "0PG (Zero Propaganda Power) pays any debt",
      answer:
        "Cards with a PG value of '0' can pay any debt. When a payment of '0' units is requested, any card can be used for payment. If 0PG is requested, it pays any debt."
    },
    {
      question: "You can hold a maximum of 7 cards",
      answer:
        "Each player can hold a maximum of 7 cards. If a player has 7 cards, they cannot draw new cards until their hand size falls below 7."
    },
    {
      question: "When the deck is exhausted, cards are reshuffled to form a new deck",
      answer:
        "If the entire deck is played and the game is not over, discarded and incomplete set cards are gathered, shuffled, and a new deck is formed to continue the game. During this process, players may choose to keep or discard their cards. Players can also add their cards to the new deck and make a fresh start. 1 point is added to the required Victory Power in the new round. Any incomplete Community and Supporter cards in play are gathered and added to the central deck. If the game still doesn't end after this reshuffling, no additional point is added to the Victory Power. A Libel card is added to the accumulated Propaganda, rendering it ineffective until cleared. If the game extends and the cards are redistributed, the affected Propaganda Power will carry over if it hasn't been cleared with a Clean Slate card."
    },
    {
      question: "A maximum of 4 cards can be played each round",
      answer:
        "Players can play up to 4 cards each turn. These should be divided into 2 Political and 2 Community cards. However, players wishing to play only one type of card may play up to 3 cards of the same type (e.g., 3 Community cards or 3 Political cards)."
    },
    {
      question: "Libel cards are tied to their location",
      answer:
        "Libel cards are tied to the set or Propaganda stockpile where they are played. If the set is broken, the Libel card is discarded."
    },
    {
      question: "Rallies cannot be held with only Supporter cards",
      answer:
        "Rally cards cannot be used with card groups made up entirely of Supporter cards. They can only be used with groups containing at least one Community card."
    },
    {
      question: "Libel cards can be played as a bluff",
      answer:
        "Libel cards can be used as a bluff by spending 1 action to place them face-down in front of players. The player can choose to play the Libel card to slander an opponent at any time."
    }
  ];
  

  if (!faqs || faqs.length === 0) faqs = defaultFaqs;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <Content>
        <TwoColumn>
          {/* <Column tw="hidden lg:block w-5/12 flex-shrink-0">
            <Image imageContain={imageContain} imageShadow={imageShadow} imageSrc={imageSrc} />
          </Column> */}
          <Column>
            <FAQContent>
              {subheading ? <Subheading>{subheading}</Subheading> : null}
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <FAQSContainer>
                {faqs.map((faq, index) => (
                  <FAQ
                    key={index}
                    onClick={() => {
                      toggleQuestion(index);
                    }}
                    className="group"
                  >
                    <Question>
                      <QuestionText>{faq.question}</QuestionText>
                      <QuestionToggleIcon>
                        {activeQuestionIndex === index ? <MinusIcon /> : <PlusIcon />}
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                        collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                      }}
                      initial="collapsed"
                      animate={activeQuestionIndex === index ? "open" : "collapsed"}
                      transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                      {faq.answer}
                    </Answer>
                  </FAQ>
                ))}
              </FAQSContainer>
            </FAQContent>
          </Column>
        </TwoColumn>
      </Content>
    </Container>
  );
};
