import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import rasitgrStory from 'images/rasitgr/rasitgr-story.png';
import rasitgrMission from 'images/rasitgr/rasitgr-mission.png';
import rasitgrProcess from 'images/rasitgr/rasitgr-process.png';

const Container = tw.div`relative bg-gray-900 -mx-8 px-8`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-200 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-red-700`;
const Description = tw.p`mt-2 text-lg leading-loose text-gray-400`;
const Link = tw.a`inline-block mt-4 text-lg text-green-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-green-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-green-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-green-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-green-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-green-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc:
       rasitgrStory,
      subtitle: "Paid",
      title: "Our Story",
      description:
        "In 2019, we began as a group of passionate gamers with a shared dream of creating the kinds of games we wanted to play. Based in Amasya, Turkey, we secured funding that helped us take the first steps toward realizing that dream. What started as a small team of dedicated gamers has since evolved into an international group of developers, artists, and designers working remotely to craft unforgettable gaming experiences. At Lupi Studios, we develop our games with creativity and innovation at the forefront, and once a project is complete, RASITGR Games publishes our work to ensure it reaches players around the globe.",
      url: "https://timerse.com"
    },

    {
      imageSrc:
        rasitgrMission,
      subtitle: "Free",
      title: "Our Mission",
      description:
        "We are dedicated to: Global Collaboration: Our team is made up of talented individuals from different parts of the world, working together remotely to develop unique games. Innovation in Game Design: We aim to challenge the norms of game development, always striving to offer something new and exciting. Quality Over Quantity: We focus on crafting original, high-quality games rather than pursuing mass production.",
      url: "https://timerse.com"
    },

    {
      imageSrc:rasitgrProcess,
      subtitle: "Exclusive",
      title: "Our Process",
      description:
        "Our games are developed remotely by an international team at Lupi Studios, allowing us to tap into a wide range of perspectives, skills, and creative ideas. Each project is developed collaboratively, with team members working closely together despite the physical distance. Once a project is ready, RASITGR Games publishes it, bringing our creative work to a global audience.",
      url: "https://timerse.com"
    }
  ];

  return (
    <Container>
      <SingleColumn>
     

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                {/* <Subtitle>{card.subtitle}</Subtitle> */}
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                {/* <Link href={card.url}>See Event Details</Link> */}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
