import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ContentWithPaddingXl, Container } from "components/misc/Layouts.js";
import { SectionHeading as Heading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";
import Alp from 'images/rasitgr/pap-min.png';
import Gizo from 'images/rasitgr/gizo-min.png';
import Ozge from 'images/rasitgr/ozge-min.png';

const Subheading = tw(SubheadingBase)`text-center`;
const Testimonials = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch`;
const TestimonialContainer = tw.div`mt-16 lg:w-1/3`;
const Testimonial = tw.div`px-4 text-center max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img`w-20 h-20 rounded-full`;
const Quote = tw.blockquote`mt-5 text-gray-400 font-light text-sm leading-loose`;
const CustomerName = tw.p`mt-5 text-red-600 font-semibold uppercase text-sm tracking-wide`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;

export default ({
  subheading = "Our Talented",
  heading = "Development Team",
  personalLink ="",
  testimonials = [
    {
      imageSrc:
        Ozge,
      quote:
        "As the QA for Çılgın Algı Savaşları, Özge ensures that the game meets the highest quality standards. From initial concepts through to the final product, Özge conducts testing to identify and resolve any issues, ensuring the game is polished and ready for players. In addition, Özge collaborates on game design and testing, helping to fine-tune gameplay and ensure an optimal player experience.",
      customerName: "Özge Öztürk"
    },
    {
      imageSrc:
       Gizo,
      quote:
        "Gizo specializes in ensuring that Çılgın Algı Savaşları offers an engaging and user-friendly experience. By focusing on both UI/UX design and game mechanics, Gizo helps to create a seamless and enjoyable experience for players. Gizo also plays a key role in testing, helping to refine the game's dynamics and balance through extensive playtesting.",
      customerName: "Gizem Zengin",
      personalLink:"https://www.linkedin.com/in/gizemzengin/"
    },
    {
      imageSrc:
        Alp,
      quote:
        "Alp leads the development and creative direction of Çılgın Algı Savaşları. He plays a pivotal role in both the game's design and its production, contributing to everything from the core mechanics to the illustrations that bring the game to life. Additionally, Alp oversees the game's distribution and sales, ensuring a smooth launch and continued success.",
      customerName: "Alp Kurt",
      personalLink:"https://www.linkedin.com/in/alp-kurt-161285134/"
    }
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        <Testimonials>
          {testimonials.map((testimonial, index) => (
            <TestimonialContainer key={index}>
              <Testimonial>
                <a target="_blank" href = {testimonial.personalLink}><Image src={testimonial.imageSrc} /></a>
                <a target="_blank" href = {testimonial.personalLink}><CustomerName>- {testimonial.customerName}</CustomerName></a>
                <Quote>{testimonial.quote}</Quote>
              </Testimonial>
            </TestimonialContainer>
          ))}
        </Testimonials>
      </ContentWithPaddingXl>

      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
